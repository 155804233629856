import { Suspense } from 'react';
import { Box, ThemeProvider } from "@mui/material";
import { createTheme } from "@mui/material/styles";
import { createBrowserRouter, RouterProvider } from "react-router-dom";
import CoinDetailsScreen from "./coindetails/pages/CoinDetailsScreen";
import CoinsScreen from "./coinlist/pages/CoinsScreen";
import { themeOptions } from "./theme";
import { SnackbarProvider } from 'notistack';
import { StandaloneChat } from "./chat/pages/StandaloneChat";
import i18next from './i18nextConf';
import { I18nextProvider } from "react-i18next";
import TermsAndCondition from "./commons/TermsAndCondition";
import PrivacyPolicy from "./commons/PrivacyPolicy";

function App() {
    const theme = createTheme(themeOptions);

    const router = createBrowserRouter([
        {
            path: '/',
            element: <CoinsScreen/>,
        },
        {
            path: '/market/:marketId',
            element: <CoinDetailsScreen/>,
        },
        {
            path: '/market/:marketId/chat',
            element: <StandaloneChat/>
        },
        {
            path: '/terms-and-conditions',
            element: <TermsAndCondition/>,
        },
        {
            path: '/privacy-policy',
            element: <PrivacyPolicy/>,
        }
    ])

    return (
        <Suspense fallback={<div
            style={{
                margin: 0,
                position: 'absolute',
                top: '50%',
                left: '50%',
                transform: 'translate(-50%, -50%)'
            }}>Loading..</div>}>
            <I18nextProvider i18n={i18next}>
                <SnackbarProvider
                    maxSnack={3}
                    anchorOrigin={{
                        vertical: 'bottom',
                        horizontal: 'right',
                    }}>
                    <ThemeProvider theme={theme}>
                        <Box sx={{
                            paddingX: {xs: 1, sm: 2, md: 4, lg: 14},
                            maxWidth: '1300px',
                            margin: 'auto'
                        }}>
                            <RouterProvider router={router}/>
                        </Box>
                    </ThemeProvider>
                </SnackbarProvider>
            </I18nextProvider>
        </Suspense>
    );
}

export default App;
