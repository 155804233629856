import { useCallback, useEffect, useRef, useState } from 'react';
import { getMarkets } from "../chart/service";
import { getUsersCountOnChatRoom } from "../firebase";
import _ from "lodash";

let socket;
let cachedOnlineUsersCount = undefined;

const useMarkets = (priceChangeRangeRef) => {
    const [markets, setMarkets] = useState({});
    const marketsRef = useRef(markets);

    useEffect(() => {
        marketsRef.current = markets;
    }, [markets]);

    const loadMarkets = async () => {
        const loadedMarkets = await getMarkets();
        setMarkets(loadedMarkets);
        loadOnlineUserCount();

        socket = new WebSocket('wss://stream.binance.com:9443/ws/!miniTicker@arr@3000ms/!ticker_1h@arr@3000ms/!ticker_4h@arr@3000ms')

        socket.onopen = function open() {
            console.log('connected');
        };

        socket.onclose = function close() {
            console.log('disconnected');
        };

        const _1HourChangeRates = {}
        const _4HourChangeRates = {}

        socket.onmessage = function incoming(data) {
            const streamData = JSON.parse(data.data);
            const newMarkets = {...loadedMarkets};
            streamData.forEach(stream => {

                const streamSymbol = stream.s;
                if (streamSymbol === 'USDTBIDR') {
                    return;
                }

                if (stream.e === '1hTicker') {
                    _1HourChangeRates[stream.s] = parseFloat(stream.P);
                    return;
                } else if (stream.e === '4hTicker') {
                    _4HourChangeRates[stream.s] = parseFloat(stream.P);
                    return;
                }

                const coin = newMarkets[streamSymbol];

                if (coin) {
                    const newPrice = parseFloat(stream.c);
                    const oldPrice = coin.tradePrice;
                    const priceDifference = newPrice - oldPrice;
                    coin.isPriceIncreased = priceDifference === 0 ? null : priceDifference > 0;
                    coin.tradePrice = newPrice;

                    if (priceChangeRangeRef?.current === '1h' && _1HourChangeRates[streamSymbol]) {
                        coin.changeRate = _1HourChangeRates[streamSymbol];
                    } else if (priceChangeRangeRef?.current === '4h' && _4HourChangeRates[streamSymbol]) {
                        coin.changeRate = _4HourChangeRates[streamSymbol];
                    } else {

                    }
                    newMarkets[streamSymbol] = coin;
                }
            })
            if (cachedOnlineUsersCount) {
                applyOnlineUserCount(newMarkets, cachedOnlineUsersCount)
            } else {
                setMarkets(newMarkets);
            }
        }
    }

    const loadOnlineUserCount = async () => {
        const marketUserCount = await getUsersCountOnChatRoom();

        if (_.isEmpty(marketsRef.current)) {
            cachedOnlineUsersCount = marketUserCount;
            return;
        }

        applyOnlineUserCount(marketsRef.current, marketUserCount)
    }

    const applyOnlineUserCount = (markets, marketUserCount) => {
        const newMarkets = {...markets};
        for (let market in newMarkets) {
            newMarkets[market].userCount = marketUserCount[market] ?? 0;
        }
        setMarkets(newMarkets);
    }

    const connectWebSocket = useCallback(() => {
        loadMarkets();
        const interval = setInterval(() => {
            loadOnlineUserCount();
        }, 10 * 1000);

        return () => {
            clearInterval(interval);
            socket?.close();
        };
    }, []);

    useEffect(() => {
        const cleanup = connectWebSocket();
        return () => {
            cleanup();
        };
    }, [connectWebSocket]);

    return {markets};
};

export default useMarkets;
