import { Container } from "@mui/material";
import Chat from "../components/Chat";
import { useEffect, useState } from "react";
import { onAuthStateChanged } from "@firebase/auth";
import { auth, firestore } from "../../firebase";
import { collection, doc, getDoc } from "firebase/firestore";
import { useParams } from "react-router-dom";

export function StandaloneChat() {
    const [user, setUser] = useState();
    const [enableChat, setEnableChat] = useState(false);
    const {marketId} = useParams();

    useEffect(() => {
        onAuthStateChanged(auth, (user) => {
            setUser(user);
        });

        const checkIfEnableChat = async () => {
            const coinsRef = collection(firestore, 'coins');
            const coinsDoc = await getDoc(doc(coinsRef, marketId));

            setEnableChat(coinsDoc?.data()?.enableChat ?? true);
        }

        checkIfEnableChat();
    }, [])

    return (
        <Container
            maxWidth={"md"} sx={{my: 2}}>
            {enableChat && <Chat isStandalone={true} user={user}/>}
        </Container>
    );
}