import React from "react";
import { Box, ButtonBase, Typography } from "@mui/material";

const ButtonGroup = ({children, value, onChange, sx}) => {
    const handleItemClick = (itemValue) => {
        if (onChange) {
            onChange(itemValue);
        }
    };

    return (
        <Box
            display='flex'
            alignItems='center'
            sx={{
                background: '#f5f5f5',
                borderRadius: '12px',
                padding: '4px',
                ...sx
            }}>
            {React.Children.map(children, (child) =>
                React.cloneElement(child, {
                    active: child.props.value === value,
                    onClick: () => handleItemClick(child.props.value)
                })
            )}
        </Box>
    );
}

const ButtonGroupItem = ({active, onClick, children}) => {
    return (
        <ButtonBase
            disableRipple
            onClick={onClick}>
            <Box
                sx={{
                    background: active ? '#ff8635' : 'none',
                    borderRadius: '12px',
                    p: '4px 12px'
                }}>
                <Typography
                    color={active ? 'white' : 'unset'}
                    variant='body2'>
                    {children}
                </Typography>
            </Box>
        </ButtonBase>
    )
}

export {
    ButtonGroup,
    ButtonGroupItem
}