import { Avatar, Divider, Grid, Typography } from '@mui/material';
import { Box } from '@mui/system';
import React, { useEffect, useState } from 'react';
import { useLocation, useParams } from 'react-router-dom';
import Chat from '../../chat/components/Chat';
import StarredCoins from '../../coinlist/components/StarredCoins';
import BinanceChart from "./BinanceChart";
import { auth, firestore } from "../../firebase";
import { collection, doc, getDoc, getDocs, orderBy, query } from "firebase/firestore";
import Advertisement from "../../ad/Advertisement";
import { onAuthStateChanged } from "@firebase/auth";
import { useTranslation } from "react-i18next";
import NavBar from "../../coinlist/components/NavBar";
import Footer from "../../nav/Footer";
import CustomButton from "../../commons/CustomButton";
import useMarkets from "../../hooks/useMarkets";

export default function CoinDetailsScreen() {

    const {state} = useLocation();
    // const [coin, setCoin] = useState(state?.coin);

    const {markets} = useMarkets();
    const {marketId} = useParams();
    const [notices, setNotices] = useState([]);
    const [noticePage, setNoticePage] = useState(1);
    const [user, setUser] = useState(null);
    const [enableChat, setEnableChat] = useState(false);

    const {t} = useTranslation();
    const coin = markets[marketId];
    const isRise = coin?.changeRate > 0;

    useEffect(() => {
        const loadNotices = async () => {
            const noticeRef = collection(firestore, 'notices');
            const docSnapshot = await getDocs(query(noticeRef, orderBy('createdAt', 'desc')))
            const noticeArr = docSnapshot.docs.map((doc) => ({...doc.data(), id: doc.id}));
            setNotices(noticeArr);
        }

        onAuthStateChanged(auth, (user) => {
            setUser(user);
            if (user) {
                checkIfEnableChat(user);
            } else {
                setEnableChat(true);
            }
        });

        const checkIfEnableChat = async (user) => {
            const coinsRef = collection(firestore, 'coins');
            const coinsDoc = await getDoc(doc(coinsRef, marketId));

            setEnableChat(coinsDoc?.data()?.enableChat ?? true);
        }

        loadNotices();
    }, [marketId])

    const lastNoticeId = notices.length === 0 ? '' : notices[notices.length - 1].id;

    const onReadMore = () => {
        if (!isLastNotice()) {
            setNoticePage(noticePage + 1);
        }
    }

    const isLastNotice = () => {
        return noticePage * 2 >= notices.length;
    }

    return (
        <Box>

            <NavBar/>

            <Advertisement/>

            <Box>

                {markets && <StarredCoins markets={markets}/>}

                <Box display="flex" mt={1} p={3} alignItems="center" justifyContent="center">

                    <Avatar sx={{
                        width: 42,
                        height: 42,
                        mr: 1
                    }}
                            src={coin?.logo}/>

                    <Box display="flex" alignItems="baseline">
                        <Typography
                            variant="h5"
                            sx={{
                                fontWeight: '800',
                                color: 'orange'
                            }}>
                            {coin?.fullName} &nbsp;
                        </Typography>


                        <Typography
                            variant="body2"
                            sx={{
                                fontWeight: 'bold'
                            }}>
                            {coin?.tradePrice?.toLocaleString('en-US',
                                {style: 'currency', currency: 'USD', maximumFractionDigits: 2})}
                        </Typography>

                        <Typography
                            variant="body2"
                            ml={2}
                            sx={{
                                fontWeight: 'bold',
                                color: isRise ? 'green' : 'red'
                            }}>
                            {(coin?.changeRate ?? 0).toFixed(2)}%
                            <i className={"fa fa-level-" + (isRise ? "up" : "down")} style={{
                                color: isRise ? "green" : "red"
                            }} aria-hidden="true"></i>
                        </Typography>
                    </Box>

                </Box>

                <Divider sx={{my: 2}}/>

                <Grid container spacing={{xs: 1, md: 4}}>
                    <Grid item md={7} xs={12}>
                        <BinanceChart
                            marketId={marketId}
                            onPriceChange={(price) => {}}
                        />
                    </Grid>
                    <Grid item md={5} xs={12}>
                        {enableChat && <Chat user={user}/>}
                    </Grid>
                </Grid>

                <Box border={'1px solid #d6d6d6'} borderRadius={'20px'} p={3} mt={3}>
                    {notices.slice(0, noticePage * 2).map((notice, idx) => (
                        <Box key={notice.id}>
                            <Typography variant={'h6'} sx={{mb: 1}}>{notice.title}</Typography>
                            <div style={{ textAlign: 'center' }}>
                                {notice.attachment && <img src={notice.attachment} style={{display: 'inline-block', marginTop: 4, width: notice.width ?? 600}}/>}
                            </div>
                            
                            <Typography variant={"body1"}
                                        dangerouslySetInnerHTML={{__html: notice.content}}></Typography>
                            {
                                (idx < (noticePage * 2 - 1))
                                && (idx < (notices.length - 1))
                                && <Divider light sx={{my: 3}}/>
                            }
                        </Box>
                    ))}
                    {!isLastNotice() &&
                        <CustomButton
                            variant={'contained'}
                            onClick={() => onReadMore()}
                            sx={{mt: 4, mx: 'auto', display: 'flex'}}>
                            {t('read more')}
                        </CustomButton>}
                </Box>
            </Box>

            <Footer/>

        </Box>
    );
}