import {
    Alert,
    Backdrop,
    CircularProgress,
    Collapse,
    IconButton,
    InputAdornment,
    TextField,
    Typography
} from '@mui/material';
import React, { useState } from 'react';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import { useSnackbar } from 'notistack';
import { EmailAuthProvider, reauthenticateWithCredential, updatePassword } from "firebase/auth";
import { Close, Lock } from "@mui/icons-material";
import { auth } from '../firebase';
import { useTranslation } from "react-i18next";
import CustomButton from "../commons/CustomButton";
import DialogCloseIcon from "../commons/DialogCloseIcon";

export function ChangePasswordDialog({isOpen = false, onClose}) {
    const [loading, setLoading] = useState(false);
    const [errorMessage, setErrorMessage] = useState(null);
    const [currentPassword, setCurrentPassword] = useState('');
    const [newPassword, setNewPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');

    const {enqueueSnackbar} = useSnackbar();
    const {t} = useTranslation();

    const onSave = async () => {
        if (currentPassword.length < 6) {
            setErrorMessage(t('enter valid password'))
            return;
        }

        if (newPassword.length < 6) {
            setErrorMessage(t('enter 6 char pwd'));
            return;
        }

        if (newPassword !== confirmPassword) {
            setErrorMessage(t('password does not match'));
            return;
        }

        setErrorMessage('');
        setLoading(true);

        const credential = EmailAuthProvider.credential(auth.currentUser.email, currentPassword)

        reauthenticateWithCredential(auth.currentUser, credential).then(() => {
            updatePassword(auth.currentUser, newPassword).then(() => {
                enqueueSnackbar(t('password changed'), {
                    variant: 'success'
                })

                setLoading(false);
                onClose();
            })
                .catch((error) => {
                    console.log(error);
                    setLoading(false);
                    setErrorMessage(t('error password changing'));
                })
        })
            .catch((error) => {
                console.log(error)
                setErrorMessage(t('current password incorrect'));
                setLoading(false);
            })
    }

    return (
        <Dialog
            open={isOpen}
            keepMounted
            onClose={() => {
                setCurrentPassword('');
                setNewPassword('')
                setConfirmPassword('')
            }}
            sx={{
                backdropFilter: "blur(1px) sepia(1%)",
            }}
            PaperProps={{sx: {borderRadius: "26px"}}}
            aria-describedby="alert-dialog-slide-description"
        >
            <Backdrop
                sx={{color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1}}
                open={loading}
            >
                <CircularProgress color="inherit"/>
            </Backdrop>

            <DialogTitle sx={{textAlign: 'center'}}>
                <IconButton
                    onClick={onClose}
                    sx={{position: 'absolute', right: 12, top: 12}}>
                    <DialogCloseIcon onClick={onClose}/>
                </IconButton>
            </DialogTitle>

            <DialogContent sx={{width: { xs: 'unset', sm: '300px'}, p: 4}}>

                <Typography variant={'h5'} textAlign='center' fontWeight={800} sx={{mt: 2}}>
                    {t('change password')}
                </Typography>

                <TextField
                    size="medium"
                    variant="standard"
                    value={currentPassword}
                    placeholder={t('current password')}
                    fullWidth
                    type="password"
                    onChange={(e) => {
                        setCurrentPassword(e.target.value);
                    }}
                    InputProps={{
                        style: {
                            borderRadius: 30,
                            background: '#f0f0f0',
                            padding: '8px 12px'
                        },
                        disableUnderline: true,
                        startAdornment: (
                            <InputAdornment position="start">
                                <Lock fontSize="small"/>
                            </InputAdornment>)
                    }}
                    sx={{mt: 3}}
                />

                <TextField
                    size="medium"
                    variant="standard"
                    value={newPassword}
                    placeholder={t('new password')}
                    fullWidth
                    type="password"
                    onChange={(e) => {
                        setNewPassword(e.target.value);
                    }}
                    InputProps={{
                        style: {
                            borderRadius: 30,
                            background: '#f0f0f0',
                            padding: '8px 12px'
                        },
                        disableUnderline: true,
                        startAdornment: (
                            <InputAdornment position="start">
                                <Lock fontSize="small"/>
                            </InputAdornment>)
                    }}
                    sx={{mt: 2}}
                />

                <TextField
                    size="medium"
                    variant="standard"
                    value={confirmPassword}
                    placeholder={t('confirm password')}
                    fullWidth
                    type="password"
                    onChange={(e) => setConfirmPassword(e.target.value)}
                    InputProps={{
                        style: {
                            borderRadius: 30,
                            background: '#f0f0f0',
                            padding: '8px 12px'
                        },
                        disableUnderline: true,
                        startAdornment: (
                            <InputAdornment position="start">
                                <Lock fontSize="small"/>
                            </InputAdornment>)
                    }}
                    sx={{mt: 2, mb: 1}}
                />

                <Collapse in={!!errorMessage}>
                    <Alert
                        onClose={() => setErrorMessage(null)}
                        severity="error">{errorMessage}</Alert>
                </Collapse>

                <CustomButton
                    variant="contained"
                    onClick={onSave}
                    sx={{
                        mt: 2,
                        mx: 'auto',
                        display: 'block'
                    }}>
                    {t('save')}
                </CustomButton>

            </DialogContent>
        </Dialog>
    );
}