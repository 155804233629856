import { Box, Divider, Drawer, IconButton, List } from "@mui/material";
import DialogCloseIcon from "../../commons/DialogCloseIcon";
import React from "react";
import LanguageToggleButton from "../../nav/LanguageToggleButton";
import { Auth } from "../../auth/Auth";

const MobileDrawer = ({isOpen, toggleDrawer}) => {
    return (
        <Drawer anchor="right" open={isOpen} onClose={() => toggleDrawer(false)}>
            <Box width={'200px'}>
                <IconButton>
                    <DialogCloseIcon onClick={() => toggleDrawer(false)}/>
                </IconButton>
                <Divider />
                <List>
                    <LanguageToggleButton/>
                </List>
                <Divider />
                <List>
                    <Auth />
                </List>
            </Box>
        </Drawer>
    );
};

export default MobileDrawer;