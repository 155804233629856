import PropTypes from 'prop-types';
import { Avatar, Stack, Typography, useTheme } from '@mui/material';
import { Box } from '@mui/system';
import { useEffect, useState } from 'react';
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

export default function StarredCoins({markets}) {

    const [coins, setCoins] = useState([]);
    const navigate = useNavigate();
    const theme = useTheme();
    const {t} = useTranslation();

    const loadMarkets = async () => {
        if (markets) {
            const marketList = Object.values(markets);
            marketList.sort((coin1, coin2) => {
                if (coin1.userCount === coin2.userCount) {
                    return coin2.marketCap - coin1.marketCap;
                }
                return coin2.userCount - coin1.userCount;
            })
            const loadedCoins = marketList.slice(0, 4);
            if (loadedCoins.length > 0 && (coins.length === 0 || loadedCoins[0].hasOwnProperty('userCount'))) {
                setCoins(loadedCoins);
            }

            /*if (!loadedCoins[0].hasOwnProperty('userCount')) {
                const newCoins = await Promise.all(
                    loadedCoins.map(async (coin) => {
                        const userCount = await getUsersCountOnChatRoom(coin.market);
                        console.log(coin.market, userCount);
                        return {...coin, userCount};
                    })
                );
                setCoins(newCoins);
            }*/
        }
    }

    useEffect(() => {
        loadMarkets();
    }, [markets])

    function contentMobile(coin, isRise) {
        return (
            <Stack direction="column">
                <Stack direction="row">
                    <Avatar src={coin.logo} sx={{width: 18, height: 18, mr: 1}}/>
                    <Typography variant="body2" sx={{mr: 1}}>
                        {coin.fullName}
                    </Typography>
                </Stack>
                <Box>
                    <Typography component="span" sx={{mr: 1, fontSize: 9}}>
                        {coin.tradePrice.toLocaleString('en-US',
                            {style: 'currency', currency: 'USD', maximumFractionDigits: 2})}
                    </Typography>
                    <Typography component="span" sx={{color: isRise ? '#03A66D' : '#CF304A', fontSize: 9}}>
                        {coin.changeRate > 0 ? '+' : ''}{(coin.changeRate).toFixed(2)}%
                    </Typography>
                </Box>
            </Stack>
        )
    }

    function contentDesktop(coin, isRise, isTop) {
        return (
            <Box display="flex" p={2} alignItems='center'>
                <Box height={48} width={48} display={'flex'} sx={{background: '#f0f0f0', borderRadius: '10px'}} mr={1}>
                    <img src={coin.logo} style={{width: 36, height: 36, margin: 'auto'}}/>
                </Box>

                <Box>
                    <Typography variant="caption"
                                sx={{
                                    background: isTop ? '#ad6a1c' : '#f4f4f4',
                                    color: isTop ? 'white' : '#858585',
                                    p: '3px 8px',
                                    borderRadius: '8px',
                                    fontSize: '0.7rem'
                                }}>
                        {t('peoples on chat', {count: coin.userCount ? coin.userCount.toLocaleString() : '0'})}
                    </Typography>
                    <Box display="flex" alignItems={'center'}>
                        <Typography variant="body2"
                                    sx={{
                                        width: '80px',
                                        color: isTop ? 'white' : 'orange',
                                        fontSize: '1rem',
                                        mr: 1,
                                        whiteSpace: 'nowrap',
                                        overflow: 'hidden',
                                        textOverflow: 'ellipsis',
                                    }}>
                            {coin.fullName}
                        </Typography>
                        <Typography
                            fontWeight={'bold'}
                            color={isTop ? 'white' : 'black'}
                            component="span"
                            sx={{
                                mr: 1,
                                width: '70px'
                            }}>
                            {coin.tradePrice.toLocaleString('en-US',
                                {style: 'currency', currency: 'USD', maximumFractionDigits: 2})}
                        </Typography>
                        <Typography component="span" fontWeight={'bold'}
                                    sx={{
                                        background: 'white',
                                        padding: '0px 4px',
                                        borderRadius: '10px',
                                        width: '44px',
                                        lineHeight: '1rem', color: isRise ? '#03A66D' : '#CF304A'
                                    }}>
                            {coin.changeRate > 0 ? '+' : ''}{(coin.changeRate).toFixed(2)}%
                        </Typography>
                    </Box>
                </Box>
            </Box>
        )
    }

    return (
        <Box display={'flex'} overflow={'auto'} py={{xs: 1, sm: 3}}>
            {coins.map((coin, index) => {

                const isRise = coin.changeRate > 0;
                const isTop = index === 0;

                return (
                    <Box mr={index === 3 ? 0 : 2} width={'314px'} key={coin.market}>
                        <Box
                            border={'1px solid #dddddd'}
                            borderRadius={'12px'}
                            sx={{
                                cursor: 'pointer',
                                background: isTop ? 'linear-gradient(90deg, rgba(236,180,81,1) 0%, rgba(255,136,56,1) 100%)' : 'none'
                            }}

                            onClick={() => navigate('/market/' + coin.market,
                                {
                                    state: {
                                        coin: coin,
                                    }
                                }
                            )}>

                            {contentDesktop(coin, isRise, isTop)}
                        </Box>
                    </Box>
                )
            })}
        </Box>);
}

StarredCoins.propTypes = {
    markets: PropTypes.object.isRequired
};