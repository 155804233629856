import React from 'react';
import { Button } from "@mui/material";
import { makeStyles } from "@mui/styles";

const useStyles = makeStyles((theme) => ({
    customButton: {
        borderRadius: 20,
        fontWeight: 'bold',
        padding: '8px 12px'
    },
    outlinedButton: {
        border: `2px solid ${theme.palette.orange.main}`,
        color: theme.palette.orange.main,
    },
    containedButton: {
        background: 'linear-gradient(90deg, rgba(244, 170, 69, 1) 0%, rgba(254, 131, 41, 1) 100%)',
        color: theme.palette.common.white,
        '&:hover': {
            backgroundColor: theme.palette.orange.dark,
        },
    },
}));

const CustomButton = ({ variant, children, ...props }) => {
    const classes = useStyles();

    const buttonProps = {
        ...props,
        className: `${classes.customButton} ${classes[`${variant}Button`]}`,
    };

    return (
        <Button {...buttonProps}>
            {children}
        </Button>
    );
};

export default CustomButton;
