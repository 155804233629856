import React from 'react';
import NavBar from "../coinlist/components/NavBar";
import { Box } from "@mui/material";
import Footer from "../nav/Footer";

const TermsAndCondition = () => {
    return (
        <Box>

            <NavBar/>

            <Box sx={{marginX: {xs: 1, sm: 2, md: 4, lg: 14}}}>
                <h1>이용약관</h1>

                <p>본 이용약관은 허니컴즈(이하 “회사”라 합니다)가 운영하는 가상 화폐 커뮤니티 사이트인 AllCoin(한글명: 올코인, 이하 "사이트"라 합니다) 사용에 적용됩니다. 사이트에
                    접속하거나
                    사이트를
                    사용함으로써 귀하는 본 약관을 준수할 것에 동의하게 됩니다. 본 약관 전체에 동의하지 않는 경우 사이트에 액세스하거나 사이트를 사용하지 마십시오.</p>

                <h2>1. 적임</h2>
                <p>사이트를 이용하려면 최소한 18세 이상이어야 합니다. 사이트를 사용함으로써 귀하는 귀하가 18세 이상이며 본 약관을 체결할 법적 능력이 있음을 진술하고 보증합니다.</p>

                <h2>2. 사용자 행동</h2>
                <ol>
                    <li>귀하는 합법적인 목적으로만 그리고 모든 관련 법률 및 규정에 부합하는 방식으로만 사이트를 사용할 것에 동의합니다.</li>
                    <li>귀하는 사이트에 해를 끼치거나, 비활성화하거나, 과도한 부담을 주거나 손상시키거나 다른 사람의 사이트 사용을 방해할 수 있는 활동에 참여하지 않을 것에 동의합니다.</li>
                    <li>귀하는 사이트를 사용하여 개인이나 법인을 사칭하거나 개인이나 법인과의 관계를 허위로 진술하거나 허위 진술하지 않을 것에 동의합니다.</li>
                </ol>

                <h2>3. 사용자 콘텐츠</h2>
                <ol>
                    <li>텍스트, 이미지, 비디오 및 링크를 포함하되 이에 국한되지 않고 사이트에 게시한 모든 콘텐츠에 대한 책임은 전적으로 귀하에게 있습니다.</li>
                    <li>사이트에 콘텐츠를 게시함으로써 귀하는 회사에게 사용, 재생산, 수정, 개작, 출판, 번역, 파생물 생성에 대한 비독점적이고, 로열티가 없고, 영구적이고, 취소 불가능하고,
                        완전히
                        2차 라이선스
                        부여가 가능한 권리를 부여합니다. 이러한 콘텐츠를 전 세계에 배포하고 표시합니다.
                    </li>
                    <li>귀하는 불법적이거나, 명예를 훼손하거나, 외설적이거나, 위협적이거나, 괴롭히거나, 모욕적이거나 기타 불쾌한 콘텐츠를 게시하지 않을 것에 동의합니다.</li>
                </ol>

                <h2>4. 지적 재산권</h2>
                <ol>
                    <li>텍스트, 그래픽, 로고, 버튼 아이콘 및 이미지를 포함하되 이에 국한되지 않는 사이트의 모든 콘텐츠는 회사 또는 해당 라이센스 제공자의 재산이며 저작권법에 의해 보호됩니다.
                    </li>
                    <li>귀하는 회사의 명시적인 서면 허가 없이는 사이트의 콘텐츠를 사용할 수 없습니다.</li>
                </ol>

                <h2>5. 가상 화폐</h2>
                <ol>
                    <li>사이트는 플랫폼 내에서 사용할 수 있는 가상 화폐("통화")를 제공할 수 있습니다. 통화는 실제 돈이 아니며 사이트 외부에서는 금전적 가치가 없습니다.</li>
                    <li>사이트 지침에 설명된 대로 사이트 활동을 통해 화폐를 구매하거나 획득할 수 있습니다.</li>
                    <li>회사는 언제든지 통지 없이 통화 시스템을 수정, 일시 중지 또는 종료할 수 있는 권리를 보유합니다.</li>
                </ol>

                <h2>6. 종료</h2>
                <p>회사는 어떤 이유로든 통지 없이 언제든지 사이트에 대한 귀하의 액세스를 종료하거나 일시 중지할 권리를 보유합니다.</p>

                <h2>7. 부인 성명</h2>
                <p>사이트는 "있는 그대로" 제공되며 회사는 사이트 또는 사이트 운영과 관련하여 명시적이든 묵시적이든 어떠한 보증도 하지 않습니다.</p>

                <h2>8. 책임의 한계</h2>
                <p>회사는 사이트 사용과 관련하여 발생하는 직접, 간접, 부수적, 특수 또는 결과적 손해에 대해 책임을 지지 않습니다.</p>

                <h2>9. 준거법</h2>
                <p>본 약관은 법률 조항의 충돌과 상관없이 (회사 관할권)의 법률에 따라 규율되고 해석됩니다.</p>

                <h2>10. 약관 변경</h2>
                <p>회사는 언제든지 본 약관을 수정할 권리를 보유합니다. 모든 변경 사항은 사이트에 게시되는 즉시 적용됩니다. 변경 사항이 게시된 후에도 사이트를 계속 사용하면 해당 변경 사항을
                    수락하는
                    것으로
                    간주됩니다.</p>

                <h2>11. 문의하기</h2>
                <p>본 약관에 대해 질문이 있는 경우 <a href="mailto:honeycomms@gmail.com">여기</a>로 문의하시기 바랍니다.</p>
            </Box>

            <Footer/>

        </Box>
    )
}

export default TermsAndCondition;