import React, { Component } from "react";
import { Box, Stack } from "@mui/material";
import { init } from "klinecharts";
import axios from "axios";
import { ButtonGroup, ButtonGroupItem } from "../ButtonGroup";

let ws, kLineChart;
let currentMarketId;

export default class BinanceChart extends Component {

    constructor(props) {
        super(props);
        currentMarketId = this.props.marketId;
        this.state = {
            interval: '15m',
            chartType: 'candle_solid',
            symbol: currentMarketId
        }
    }

    componentDidMount() {
        kLineChart = init('update-k-line')
        const fontFamily = "'Montserrat'";
        kLineChart.setStyleOptions({
            candle: {
                priceMark: {
                    high: {
                        textFamily: fontFamily
                    },
                    low: {
                        textFamily: fontFamily
                    },
                    last: {
                        text: {
                            family: fontFamily
                        }
                    }
                },
                tooltip: {
                    labels: ['Time: ', 'Open: ', 'Close: ', 'High: ', 'Low: ', 'Volume: '],
                    text: {
                        family: fontFamily
                    }
                },
            },
            indicator: {
                lastValueMark: {
                    family: fontFamily
                },
                tooltip: {
                    text: {
                        family: fontFamily
                    }
                },
            },

            xAxis: {
                show: true,
                tickText: {
                    family: fontFamily
                }
            },
            yAxis: {
                show: true,
                tickText: {
                    family: fontFamily
                }
            },
            crosshair: {
                horizontal: {
                    text: {
                        family: fontFamily
                    }
                },
                vertical: {
                    text: {
                        family: fontFamily
                    }
                }
            },
            overlay: {
                text: {
                    family: fontFamily
                },
                rectText: {
                    family: fontFamily
                }
            }
        })
        kLineChart.createTechnicalIndicator('VOL', true, {id: 'volume_pane'})

        this.loadInitialData();

        ws = new WebSocket(`wss://stream.binance.com:9443/ws/${this.mapSymbol(this.state.symbol)}@kline_${this.state.interval}`);

        ws.onopen = function open() {
            console.log('connected');
        };

        ws.onclose = function close() {
            console.log('disconnected');
        };

        const onPriceChange = this.props.onPriceChange;
        ws.onmessage = function incoming(data) {
            const ticker = JSON.parse(data.data);
            if (ticker.hasOwnProperty('result')) {
                return;
            }
            const newData = {
                open: parseFloat(ticker.k.o),
                low: parseFloat(ticker.k.l),
                high: parseFloat(ticker.k.h),
                close: parseFloat(ticker.k.c),
                volume: parseFloat(ticker.k.v),
                timestamp: ticker.k.t
            };

            onPriceChange(newData.open)

            kLineChart.updateData(newData);
        };
    }

    componentWillUnmount() {
        if (ws.readyState === WebSocket.OPEN) {
            ws.send(JSON.stringify({
                method: 'UNSUBSCRIBE',
                params: [`${this.mapSymbol(this.state.symbol)}@kline_${this.state.interval}`],
                id: 1
            }))
        }
    }

    async loadInitialData() {
        try {
            const binanceUrl = `https://api.binance.com/api/v3/uiKlines?limit=1000&symbol=${this.mapSymbolUpper(this.state.symbol)}&interval=${this.state.interval}`
            const response = await axios.get(binanceUrl)
            const data = response.data.map((arr) => (
                {
                    open: parseFloat(arr[1]),
                    low: parseFloat(arr[3]),
                    high: parseFloat(arr[2]),
                    close: parseFloat(arr[4]),
                    volume: parseFloat(arr[5]),
                    timestamp: arr[0]
                }
            ))
            if (data.length > 0) {
                const pricePrecision = this.getPrecision(data[0].low) + 1;
                const volumePrecision = this.getPrecision(data[0].volume) + 1;
                kLineChart.setPriceVolumePrecision(pricePrecision, volumePrecision)
            }

            kLineChart.applyNewData(data);
            kLineChart.createTechnicalIndicator('MA', false, {id: 'candle_pane'})
        } catch (e) {
            console.log(e);
        }
    }

    changeInterval(interval) {
        const previousInterval = this.state.interval;

        this.setState({interval}, async () => {
            if (ws.readyState === WebSocket.OPEN) {
                ws.send(JSON.stringify({
                    method: 'UNSUBSCRIBE',
                    params: [`${this.mapSymbol(this.state.symbol)}@kline_${previousInterval}`],
                    id: 1
                }))

                await this.loadInitialData()

                ws.send(JSON.stringify({
                    method: 'SUBSCRIBE',
                    params: [`${this.mapSymbol(this.state.symbol)}@kline_${interval}`],
                    id: 2
                }))
            }
        })
    }

    changeSymbol() {
        const previousSymbol = this.state.symbol;

        this.setState({...this.state, symbol: currentMarketId}, async () => {
            if (ws.readyState === WebSocket.OPEN) {
                console.log('Sending unsubscribe', previousSymbol)
                ws.send(JSON.stringify({
                    method: 'UNSUBSCRIBE',
                    params: [`${this.mapSymbol(previousSymbol)}@kline_${this.state.interval}`],
                    id: 1
                }))

                await this.loadInitialData()

                console.log('Sending subscribe', currentMarketId)
                ws.send(JSON.stringify({
                    method: 'SUBSCRIBE',
                    params: [`${this.mapSymbol(currentMarketId)}@kline_${this.state.interval}`],
                    id: 2
                }))
            }
        })
    }

    getPrecision(number) {
        let e = 1;
        while (Math.round(number * e) / e !== number) e *= 10;
        return Math.log(e) / Math.LN10;
    }

    mapSymbol(symbol) {
        return symbol === 'USDTBIDR' ? 'busdusdt' : symbol.toLowerCase();
    }

    mapSymbolUpper(symbol) {
        return symbol === 'USDTBIDR' ? 'BUSDUSDT' : symbol;
    }

    render() {
        if (currentMarketId !== this.props.marketId) {
            currentMarketId = this.props.marketId;
            setTimeout(() => {
                this.changeSymbol()
            }, 1000)
        }

        return (
            <div>
                <Stack direction="row">
                    <ButtonGroup
                        value={this.state.interval}
                        onChange={(newVal) => this.changeInterval(newVal)}
                    >
                        <ButtonGroupItem value="1s">1s</ButtonGroupItem>
                        <ButtonGroupItem value="15m">15m</ButtonGroupItem>
                        <ButtonGroupItem value="1h">1h</ButtonGroupItem>
                        <ButtonGroupItem value="1d">1d</ButtonGroupItem>
                        <ButtonGroupItem value="3d">3d</ButtonGroupItem>
                        <ButtonGroupItem value="1w">7d</ButtonGroupItem>
                        <ButtonGroupItem value="1M">30d</ButtonGroupItem>
                    </ButtonGroup>

                    <ButtonGroup
                        value={this.state.chartType}
                        sx={{
                            ml: {xs: 1, sm: 2}
                        }}
                        onChange={(newVal) => {
                            this.setState({
                                chartType: newVal
                            }, () => {
                                kLineChart.setStyleOptions({
                                    candle: {
                                        type: this.state.chartType
                                    }
                                })
                            })
                        }}>
                        <ButtonGroupItem value="area">
                            <i className="fa fa-line-chart" aria-hidden="true"></i>
                        </ButtonGroupItem>
                        <ButtonGroupItem value="candle_solid">
                            <i className="fa fa-bar-chart" aria-hidden="true"></i>
                        </ButtonGroupItem>
                    </ButtonGroup>
                </Stack>

                <Box style={{
                    display: 'flex',
                    flexDirection: 'column',
                    marginTop: 20,
                    height: 500
                }}>
                    <div id="update-k-line"
                         className="k-line-chart"
                         style={{display: 'flex', flex: 1}}/>
                </Box>
            </div>
        );
    }
}