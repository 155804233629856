import {
    Alert,
    Avatar,
    Backdrop,
    Button,
    CircularProgress,
    Collapse,
    IconButton,
    InputAdornment,
    Stack,
    TextField,
    Typography
} from '@mui/material';
import React, { useEffect, useRef, useState } from 'react';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import PersonIcon from '@mui/icons-material/Person';
import { updateProfile, } from "firebase/auth";
import { getDownloadURL, ref, uploadBytes } from "firebase/storage";
import { useSnackbar } from 'notistack';
import { auth, firestore, storage } from '../firebase';
import { EmailOutlined } from "@mui/icons-material";
import { collection, doc, updateDoc } from "firebase/firestore";
import { useTranslation } from "react-i18next";
import CustomButton from "../commons/CustomButton";
import DialogCloseIcon from "../commons/DialogCloseIcon";

export function ProfileDialog({isOpen = false, onClose, onChangePassword}) {
    const [loading, setLoading] = useState(false);
    const [errorMessage, setErrorMessage] = useState(null);
    const [name, setName] = useState('');
    const [email, setEmail] = useState('');
    const [avatar, setAvatar] = useState(null);
    const [avatarSource, setAvatarSource] = useState(null);

    const hiddenFileInput = useRef(null);
    const {t} = useTranslation();

    const {enqueueSnackbar} = useSnackbar();

    useEffect(() => {
        if (auth.currentUser != null) {
            setName(auth.currentUser.displayName);
            setEmail(auth.currentUser.email)
        }
    }, [auth.currentUser])

    const onSave = async () => {
        setLoading(true);

        if (avatar != null) {
            const storageRef = ref(storage, 'avatars/' + auth.currentUser.uid);
            const uploadResult = await uploadBytes(storageRef, avatar);
            const photoURL = await getDownloadURL(uploadResult.ref);

            await updateProfile(auth.currentUser, {
                displayName: name,
                photoURL
            });
        } else {
            await updateProfile(auth.currentUser, {
                displayName: name,
            });
        }

        await auth.currentUser.reload();
        await updateOnFirestore(auth.currentUser);

        enqueueSnackbar(t('profile updated'), {
            variant: 'success'
        })

        setLoading(false);
    }

    const onAvatarChange = (e) => {
        const avatar = e.target.files[0];
        setAvatar(avatar);
        const reader = new FileReader();
        reader.readAsDataURL(avatar);
        reader.onloadend = () => {
            setAvatarSource(reader.result);
        }
    }

    async function updateOnFirestore(user) {
        const usersRef = collection(firestore, `users`);
        await updateDoc(doc(usersRef, user.uid), {
            name: user.displayName,
            avatar: user.photoURL
        });
    }

    return (
        <Dialog
            open={isOpen}
            keepMounted
            aria-describedby="alert-dialog-slide-description"
            sx={{
                backdropFilter: "blur(1px) sepia(1%)",
            }}
            PaperProps={{sx: {borderRadius: "26px"}}}
        >
            <Backdrop
                sx={{color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1}}
                open={loading}
            >
                <CircularProgress color="inherit"/>
            </Backdrop>

            <DialogTitle sx={{textAlign: 'center'}}>
                <IconButton
                    onClick={onClose}
                    sx={{position: 'absolute', right: 12, top: 12}}>
                    <DialogCloseIcon onClick={onClose}/>
                </IconButton>
            </DialogTitle>

            <DialogContent sx={{width: { xs: 'unset', sm: '300px'}, p: 4}}>

                <Typography variant={'h5'} textAlign='center' fontWeight={800} sx={{mt: 2}}>
                    {t('profile')}
                </Typography>

                <Stack spacing={3} alignItems="center">

                    <Collapse in={!!errorMessage}>
                        <Alert
                            onClose={() => setErrorMessage(null)}
                            severity="error">{errorMessage}</Alert>
                    </Collapse>

                    <Stack alignItems="center" spacing={1}>
                        <Avatar src={avatarSource ?? auth.currentUser?.photoURL} sx={{
                            width: 60,
                            height: 60
                        }}/>
                        <Button size="small" onClick={() => hiddenFileInput.current.click()}>
                            {t('change')}
                        </Button>
                    </Stack>

                    <input
                        type="file"
                        ref={hiddenFileInput}
                        onChange={onAvatarChange}
                        style={{display: 'none'}}/>

                    <TextField
                        size="medium"
                        variant="standard"
                        value={email}
                        placeholder={t('email')}
                        fullWidth
                        type="email"
                        disabled
                        InputProps={{
                            style: {
                                borderRadius: 30,
                                background: '#f0f0f0',
                                padding: '8px 12px'
                            },
                            disableUnderline: true,
                            startAdornment: (
                                <InputAdornment position="start">
                                    <EmailOutlined fontSize="small"/>
                                </InputAdornment>)
                        }}
                        sx={{mt: 3}}
                    />


                    <TextField
                        size="medium"
                        variant="standard"
                        value={name}
                        placeholder={t('nick name')}
                        fullWidth
                        type="text"
                        onChange={(e) => setName(e.target.value)}
                        InputProps={{
                            style: {
                                borderRadius: 30,
                                background: '#f0f0f0',
                                padding: '8px 12px'
                            },
                            disableUnderline: true,
                            startAdornment: (
                                <InputAdornment position="start">
                                    <PersonIcon fontSize="small"/>
                                </InputAdornment>)
                        }}
                        sx={{mt: 2}}
                    />

                    <Button
                        size="small"
                        variant='text'
                        sx={{marginRight: 'auto'}}
                        onClick={() => onChangePassword()}
                    >
                        {t('change password')}
                    </Button>

                    <CustomButton
                        variant="contained"
                        onClick={onSave}
                        sx={{mt: 3}}>
                        {t('save')}
                    </CustomButton>
                </Stack>

            </DialogContent>
        </Dialog>
    );
}