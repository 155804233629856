import { useEffect, useState } from 'react';
import { Button, Divider, Stack, useMediaQuery, useTheme } from "@mui/material";
import LoginDialog from './LoginDialog';
import SignupDialog from './SignupDialog';
import { onAuthStateChanged, signOut } from '@firebase/auth';
import { ProfileDialog } from './ProfileDialog';

import { auth, realtimeDatabase, storage } from '../firebase';
import { ChangePasswordDialog } from "./ChangePasswordDialog";
import { ResetPasswordDialog } from "./ResetPasswordDialog";
import { useTranslation } from "react-i18next";
import LanguageToggleButton from "../nav/LanguageToggleButton";
import { Login, Logout, Person } from "@mui/icons-material";
import { ref, set, serverTimestamp as realtimeTimestamp } from 'firebase/database';

export function Auth() {
    const [showLoginDialog, setShowLoginDialog] = useState(false);
    const [showSignupDialog, setShowSignupDialog] = useState(false);
    const [showProfileDialog, setShowProfileDialog] = useState(false);
    const [showChangePasswordDialog, setShowChangePasswordDialog] = useState(false);
    const [showResetPasswordDialog, setShowResetPasswordDialog] = useState(false);

    const [loggedOut, setLoggedOut] = useState(auth.currentUser === null);
    const {t} = useTranslation();
    const theme = useTheme();
    const isSm = useMediaQuery(theme.breakpoints.down('sm'));

    useEffect(() => {
        onAuthStateChanged(auth, (user) => {
            if (user != null) {
                setLoggedOut(false);
            } else {
                setLoggedOut(true);
            }
        });
    }, [auth])

    const closeLoginDialog = () => {
        setShowLoginDialog(false);
    }

    const closeSignupDialog = () => {
        setShowSignupDialog(false);
    }

    const closeProfileDialog = () => {
        setShowProfileDialog(false);
    }

    const closeChangePasswordDialog = () => {
        setShowChangePasswordDialog(false);
    }

    const onShowChangePasswordDialog = () => {
        setShowProfileDialog(false);
        setShowChangePasswordDialog(true);
    }

    const closeResetPasswordDialog = () => {
        setShowResetPasswordDialog(false);
    }

    const buttonStyles = { color: '#545454', fontSize: isSm ? '16px' : '12px', px: isSm ? 2 : 1 }

    return (
        <Stack direction={ isSm ? "column" : "row"} alignItems={"start"}>
            <LoginDialog
                isOpen={showLoginDialog}
                onCloseDialog={() => closeLoginDialog()}
                onSignup={() => {
                    closeLoginDialog();
                    setShowSignupDialog(true);
                }}
                onForgotPassword={() => {
                    closeLoginDialog();
                    setShowResetPasswordDialog(true);
                }}
                auth={auth}
            />

            <SignupDialog
                isOpen={showSignupDialog}
                onCloseDialog={() => closeSignupDialog()}
                onLogin={() => {
                    closeSignupDialog();
                    setShowLoginDialog(true)
                }}
                auth={auth}
            />

            <ProfileDialog
                isOpen={showProfileDialog}
                onClose={() => closeProfileDialog()}
                onChangePassword={() => onShowChangePasswordDialog()}
                auth={auth}
                storage={storage}
            />

            <ChangePasswordDialog
                isOpen={showChangePasswordDialog}
                onClose={() => closeChangePasswordDialog()}
                auth={auth}
                storage={storage}
            />

            <ResetPasswordDialog
                isOpen={showResetPasswordDialog}
                onClose={() => closeResetPasswordDialog()}
            />

            {loggedOut &&
                <Button sx={buttonStyles}
                        startIcon={<Login />}
                        onClick={() => setShowLoginDialog(true)}>
                    {t('login')}
                </Button>}

            {loggedOut && isSm && <Divider sx={{width: '100%'}} />}

            {loggedOut &&
                <Button
                    sx={{...buttonStyles, ml: isSm ? 0 : 1}}
                    startIcon={<Person />}
                    onClick={() => setShowSignupDialog(true)}>
                    {t('register')}
                </Button>}

            {!loggedOut && isSm && <Divider sx={{width: '100%'}} />}

            {!loggedOut &&
                <Button
                    sx={buttonStyles}
                    startIcon={<Person />}
                    onClick={() => setShowProfileDialog(true)}>
                    {t('profile')}
                </Button>}

            {!loggedOut && isSm && <Divider variant={'fullWidth'} />}

            {!loggedOut &&
                <Button
                    sx={{...buttonStyles, ml: isSm ? 0 : 2}}
                    startIcon={<Logout />}
                    onClick={() => {
                        const usersRealtimeRef = ref(realtimeDatabase, '/users/' + auth.currentUser.uid);
                        const userOffline = {
                            online: false,
                            onlineMarket: '',
                            lastOnlineChange: realtimeTimestamp(),
                        };
                        set(usersRealtimeRef, userOffline)
                        signOut(auth);
                    }}>
                    {t('logout')}
                </Button>}

        </Stack>
    )
}