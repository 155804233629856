const errorCodes = {
    'auth/wrong-password': 'incorrect password',
    'auth/email-already-exists': 'email already exists',
    'auth/email-already-in-use': 'email already exists',
    'auth/user-disabled': 'account disabled',
    'auth/internal-error': 'internal error',
    'auth/invalid-email': 'invalid email',
    'auth/user-not-found': 'user not found',
    'auth/weak-password': 'weak password'
}

export default errorCodes