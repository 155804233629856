import { Box, TableCell, TableHead, TableRow, TableSortLabel, useMediaQuery, useTheme } from "@mui/material";
import { visuallyHidden } from "@mui/utils";
import PropTypes from 'prop-types';
import { useTranslation } from "react-i18next";
import PriceChangeHeader from "./PriceChangeHeader";

export default function EnhancedTableHead(props) {
    const {t} = useTranslation();

    const headCells = [
        {
            id: 'fullName',
            align: 'left',
            paddingLeft: '60px',
            label: t('name')
        },
        {
            id: 'tradePrice',
            align: 'center',
            width: '150px',
            label: t('price')
        },
        {
            id: 'changeRate',
            align: 'start',
            paddingLeft: '15px',
            label: t('change')
        },

        {
            id: 'accTradeVolume',
            align: 'center',
            label: t('24h volume')
        },
        {
            id: 'marketCap',
            align: 'center',
            label: t('market cap')
        },
        {
            id: 'chatCount',
            align: 'right',
            paddingRight: '20px',
            label: t('chat room')
        }
    ]

    const headCellsSm = [
        {
            id: 'fullName',
            align: 'left',
            paddingLeft: '30px',
            width: '40%',
            label: t('name')
        },
        {
            id: 'tradePrice',
            align: 'start',
            label: t('price/24h change')
        },

        {
            id: 'chatCount',
            align: 'right',
            paddingRight: '10px',
            label: t('chat room')
        }
    ];

    const {order, orderBy, onRequestSort, onPriceChangeDurationChange} =
        props;
    const createSortHandler = (property) => (event) => {
        onRequestSort(event, property);
    };

    const theme = useTheme();
    const isSm = useMediaQuery(theme.breakpoints.down('md'));
    const tableHead = isSm ? headCellsSm : headCells;

    const change = t('change');

    const tableCellSx = {
        height: 35,
        fontSize: '12px',
        fontWeight: 'bold',
        display: 'table-cell'
    };

    return (
        <TableHead
            sx={{
                background: '#fafafa',
                borderTop: '1px solid #e0e0e0',
                borderBottom: '1px solid #e0e0e0',
            }}>
            <TableRow>

                {tableHead.map((headCell) => (
                    <TableCell
                        key={headCell.id}
                        align={headCell.align}
                        padding={'none'}
                        sortDirection={orderBy === headCell.id ? order : false}
                        sx={{
                            height: 35,
                            fontSize: '12px',
                            fontWeight: 'bold',
                            display: (headCell.id === 'changeRate' ? 'flex' : 'table-cell'),
                            paddingLeft: headCell.paddingLeft ?? 0,
                            paddingRight: headCell.paddingRight ?? 0,
                            width: headCell.width ?? 'unset'
                        }}>

                        <TableSortLabel
                            active={orderBy === headCell.id}
                            direction={orderBy === headCell.id ? order : 'asc'}
                            onClick={createSortHandler(headCell.id)}
                        >
                            {headCell.label}
                            {orderBy === headCell.id ? (
                                <Box component="span" sx={visuallyHidden}>
                                    {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                                </Box>
                            ) : null}
                        </TableSortLabel>

                        {headCell.id === 'changeRate'
                            &&
                            <PriceChangeHeader
                                onPriceChangeDurationChange={(range) => onPriceChangeDurationChange(range)}/>}
                    </TableCell>
                ))}
            </TableRow>
        </TableHead>
    );
}

EnhancedTableHead.propTypes = {
    numSelected: PropTypes.number.isRequired,
    onRequestSort: PropTypes.func.isRequired,
    order: PropTypes.oneOf(['asc', 'desc']).isRequired,
    orderBy: PropTypes.string.isRequired,
    rowCount: PropTypes.number.isRequired,
};