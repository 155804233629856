import React from 'react';
import PropTypes from 'prop-types';
import {
    Avatar,
    Stack,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TablePagination,
    TableRow,
    Typography,
    useMediaQuery,
    useTheme,
    tableCellClasses
} from '@mui/material';
import { withStyles } from '@mui/styles';
import EnhancedTableHead from './EnhancedTableHead';
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

const TableBodyCell = withStyles(theme => ({
    root: {
        fontWeight: 400,
        padding: '12px 8px'
    }
}))(TableCell);

function descendingComparator(a, b, orderBy) {
    if (typeof a[orderBy] === 'string' && typeof b[orderBy] === 'string') {
        // If both values are strings, use localeCompare to compare them
        return b[orderBy].localeCompare(a[orderBy]);
    } else {
        // If one or both values are not strings, compare them directly
        if (b[orderBy] < a[orderBy]) {
            return -1;
        }
        if (b[orderBy] > a[orderBy]) {
            return 1;
        }
        return 0;
    }
}


function getComparator(order, orderBy) {
    return order === 'desc'
        ? (a, b) => descendingComparator(a, b, orderBy)
        : (a, b) => -descendingComparator(a, b, orderBy);
}

function stableSort(array, comparator) {
    const stabilizedThis = array.map((el, index) => [el, index]);
    stabilizedThis.sort((a, b) => {
        const order = comparator(a[0], b[0]);
        if (order !== 0) {
            return order;
        }
        return a[1] - b[1];
    });
    return stabilizedThis.map((el) => el[0]);
}

function Price({price, isPriceIncreased, isSm}) {
    return (
        <Typography sx={{
            fontSize: '14px',
            width: '100px',
            color: isPriceIncreased ? '#03A66D' : (
                isPriceIncreased ? '#CF304A' : 'black')

        }}>
            {price.toLocaleString('en-US', {
                style: 'currency',
                currency: 'USD',
                maximumFractionDigits: 2
            })}
        </Typography>);
}

function ChangeRate({changeRate, isSm}) {
    return (
        <Typography sx={{
            color: changeRate > 0 ? '#03A66D' : '#CF304A',
            fontSize: isSm ? '12px' : '14px',
            fontWeight: '500'
        }}>
            {changeRate > 0 ? '+' : ''}{changeRate.toFixed(2)}%
        </Typography>
    );
}

function CoinName({row, isSm}) {
    return (
        <TableBodyCell
            align="left" sx={{
            width: 180
        }}>
            <Stack direction="row" alignItems="center">
                <Avatar
                    sx={{
                        width: 32,
                        height: 32,
                        mr: isSm ? 1 : 2
                    }} src={row.logo}/>
                <Typography variant="body1" style={{
                    color: "#1E2329",
                    fontSize: "14px",
                    lineHeight: "24px",
                    fontWeight: 500
                }}>{row.fullName}</Typography>
            </Stack>
        </TableBodyCell>);
}

CoinName.propTypes = {row: PropTypes.any};
export default function EnhancedTable({markets, page, setPage, onPriceChangeDurationChange}) {
    const [order, setOrder] = React.useState('asc');
    const [orderBy, setOrderBy] = React.useState('calories');
    const [selected, setSelected] = React.useState([]);
    const [rowsPerPage, setRowsPerPage] = React.useState(50);
    const navigate = useNavigate();
    const theme = useTheme();
    const isSm = useMediaQuery(theme.breakpoints.down('md'));
    const {t} = useTranslation();

    const handleRequestSort = (event, property) => {
        console.log('Property', property, 'Event', event);
        const isAsc = orderBy === property && order === 'asc';
        setOrder(isAsc ? 'desc' : 'asc');
        setOrderBy(property);
    };

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    const enabledStyle = {
        background: '#ff8736',
        borderRadius: '6px',
        color: 'white',
        padding: '1px',
    }
    const disabledStyle = {
        borderRadius: '6px',
        border: '1px solid #dbdbd9',
        padding: '1px'
    }
    const allowNext = (page + 1) * rowsPerPage <= markets.length;
    const allowPrevious = page > 0;
    const paginationComponent = (
        <TablePagination
            rowsPerPageOptions={[5, 10, 25, 50, 100]}
            component="div"
            count={markets.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
            translate={'yes'}
            labelRowsPerPage={t('rows per page')}
            labelDisplayedRows={() => ''}
            slotProps={{
                select: {
                    style: {background: '#f3f3f3', borderRadius: '6px', margin: '0 0 0 6px'}
                },
                actions: {
                    previousButton: {
                        style: allowPrevious ? {...enabledStyle, marginRight: 5} : {
                            ...disabledStyle,
                            marginRight: 5
                        }
                    },
                    nextButton: {style: allowNext ? enabledStyle : disabledStyle},
                    previousButtonIcon: {style: {p: '1px'}},
                    nextButtonIcon: {style: {p: '1px'}}
                }
            }}
        />);

    return (
        <>
            {paginationComponent}
            <TableContainer>
                <Table sx={{
                    [`& .${tableCellClasses.root}`]: {
                        borderBottom: "none"
                    }
                }}>
                    <EnhancedTableHead
                        numSelected={selected.length}
                        order={order}
                        orderBy={orderBy}
                        onPriceChangeDurationChange={(range) => onPriceChangeDurationChange(range)}
                        onRequestSort={handleRequestSort}
                        rowCount={markets.length}
                    />
                    <TableBody>
                        {stableSort(markets, getComparator(order, orderBy))
                            .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                            .map((row, index) => {
                                return (
                                    <TableRow
                                        hover
                                        divi
                                        key={row.market}
                                        sx={{
                                            cursor: 'pointer',
                                            fontSize: '14px',
                                            paddingRight: '20px',
                                            background: index % 2 ? '#f9f9f9' : 'white'
                                        }}
                                        onClick={() => navigate(
                                            '/market/' + row.market,
                                            {
                                                state: {
                                                    coin: row,
                                                }
                                            })}
                                    >
                                        <CoinName row={row} isSm={isSm}/>

                                        {isSm && <TableBodyCell>
                                            <Stack>
                                                <Price price={row.tradePrice} isPriceIncreased={row.isPriceIncreased}
                                                       isSm={isSm}/>
                                                <ChangeRate changeRate={row.changeRate} isSm={isSm}/>
                                            </Stack>
                                        </TableBodyCell>}

                                        {!isSm && <TableBodyCell align={'center'}>
                                            <Price price={row.tradePrice} isPriceIncreased={row.isPriceIncreased}
                                                   isSm={isSm}/>
                                        </TableBodyCell>}

                                        {!isSm && <TableBodyCell align="start" sx={{width: 128}}>
                                            <ChangeRate changeRate={row.changeRate} isSm={isSm}/>
                                        </TableBodyCell>}

                                        {!isSm && <TableBodyCell align="center" sx={{
                                            fontSize: '14px'
                                        }}>
                                            ${row.accTradeVolume.toLocaleString('en-US', {maximumFractionDigits: 0})}
                                        </TableBodyCell>}

                                        {!isSm && <TableBodyCell align="center" sx={{
                                            fontSize: '14px'
                                        }}>
                                            ${row.marketCap.toLocaleString('en-US', {maximumFractionDigits: 0})}
                                        </TableBodyCell>}

                                        <TableBodyCell align="right" sx={{
                                            fontSize: '14px'
                                        }}>
                                            {t('peoples', {count: (row.userCount ?? 0).toLocaleString()})}   &nbsp;
                                        </TableBodyCell>
                                    </TableRow>
                                );
                            })}
                    </TableBody>
                </Table>
            </TableContainer>
            {paginationComponent}
        </>
    );
}

EnhancedTable.propTypes = {
    markets: PropTypes.array.isRequired
};