import {
    Alert,
    Backdrop,
    Box,
    CircularProgress,
    Collapse,
    IconButton,
    InputAdornment,
    TextField,
    Typography
} from '@mui/material';
import React, { useState } from 'react';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import HighlightOffIcon from '@mui/icons-material/HighlightOff';
import { useSnackbar } from 'notistack';
import { sendPasswordResetEmail } from "firebase/auth";
import { Close, EmailOutlined } from "@mui/icons-material";
import { auth } from '../firebase';
import { useTranslation } from "react-i18next";
import CustomButton from "../commons/CustomButton";
import DialogCloseIcon from "../commons/DialogCloseIcon";

export function ResetPasswordDialog({isOpen = false, onClose}) {
    const [loading, setLoading] = useState(false);
    const [errorMessage, setErrorMessage] = useState(null);
    const [email, setEmail] = useState('');
    const [emailSent, setEmailSent] = useState(false);

    const {enqueueSnackbar} = useSnackbar();
    const {t} = useTranslation();

    const onSend = async () => {
        if (email === '' || !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(email)) {
            setErrorMessage(t('enter valid email'))
            return;
        }

        setErrorMessage('');
        setLoading(true);

        sendPasswordResetEmail(auth, email)
            .then(() => {
                setEmailSent(true);
                setLoading(false);
            })
            .catch((error) => {
                const errorCode = error.code;
                const errorMessage = error.message;
                console.log(errorCode, errorMessage)
                setLoading(false);
            });
    }

    function emailSentContent() {
        return (<DialogContent sx={{width: { xs: 'unset', sm: '300px'}, p: 4}}>
            <Box
                display="flex"
                flexDirection="column"
                justifyContent="center"
                height={'100%'}
                alignItems="center">

                <EmailOutlined fontSize={"large"}/>
                <Typography variant={'h6'}>{t('pwd reset email sent')}</Typography>
                <Typography variant={'body1'}>{t('check email to reset pwd')}</Typography>
            </Box>
        </DialogContent>)
    }

    return (
        <Dialog
            open={isOpen}
            keepMounted
            onClose={() => {
                setEmail('');
                setEmailSent(false)
            }}
            aria-describedby="alert-dialog-slide-description"
            sx={{
                backdropFilter: "blur(1px) sepia(1%)",
            }}
            PaperProps={{sx: {borderRadius: "26px"}}}
        >
            <Backdrop
                sx={{color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1}}
                open={loading}
            >
                <CircularProgress color="inherit"/>
            </Backdrop>

            <DialogTitle sx={{textAlign: 'center'}}>
                <IconButton
                    onClick={onClose}
                    sx={{position: 'absolute', right: 8, top: 8}}>
                    <DialogCloseIcon onClick={onClose}/>
                </IconButton>
            </DialogTitle>

            {emailSent && emailSentContent()}

            {!emailSent && <DialogContent sx={{width: 330}}>

                <Typography variant={'h5'} textAlign='center' fontWeight={800} sx={{mt: 2}}>
                    {t('reset password')}
                </Typography>

                <TextField
                    size="medium"
                    variant="standard"
                    value={email}
                    placeholder={t('email')}
                    fullWidth
                    type="email"
                    onChange={(e) => {
                        setEmail(e.target.value);
                    }}
                    InputProps={{
                        style: {
                            borderRadius: 30,
                            background: '#f0f0f0',
                            padding: '8px 12px'
                        },
                        disableUnderline: true,
                        startAdornment: (
                            <InputAdornment position="start">
                                <EmailOutlined fontSize="small"/>
                            </InputAdornment>)
                    }}
                    sx={{mt: 3, mb: 2}}
                />

                <Collapse in={!!errorMessage}>
                    <Alert
                        onClose={() => setErrorMessage(null)}
                        severity="error">{errorMessage}</Alert>
                </Collapse>

                <CustomButton
                    fullWidth
                    variant="contained"
                    onClick={onSend}
                    sx={{mt: 1}}>
                    {t('get pwd reset email')}
                </CustomButton>

            </DialogContent>}
        </Dialog>
    );
}