const coinmap = {
    "1055": "1055-china-southern-airlines",
    "BTC": "btc-bitcoin",
    "ETH": "eth-ethereum",
    "USDT": "usdt-tether",
    "USDC": "usdc-usd-coin",
    "BNB": "bnb-binance-coin",
    "XRP": "xrp-xrp",
    "BUSD": "busd-binance-usd",
    "DOGE": "doge-dogecoin",
    "ADA": "ada-cardano",
    "OKB": "okb-okb",
    "MATIC": "matic-polygon",
    "DAI": "dai-dai",
    "STETH": "steth-lido-staked-ether",
    "LTC": "ltc-litecoin",
    "HEX": "hex-hex",
    "TRX": "trx-tron",
    "SOL": "sol-solana",
    "SHIB": "shib-shiba-inu",
    "WETH": "weth-weth",
    "DOT": "dot-polkadot",
    "AVAX": "avax-avalanche",
    "TONCOIN": "toncoin-the-open-network",
    "LEO": "leo-leo-token",
    "WBTC": "wbtc-wrapped-bitcoin",
    "ATOM": "atom-cosmos",
    "XMR": "xmr-monero",
    "LINK": "link-chainlink",
    "UNI": "uni-uniswap",
    "ETC": "etc-ethereum-classic",
    "BCH": "bch-bitcoin-cash",
    "XLM": "xlm-stellar",
    "ALGO": "algo-algorand",
    "QNT": "qnt-quant",
    "APE": "ape-apecoin",
    "HT": "ht-huobi-token",
    "BTCB": "btcb-binance-bitcoin",
    "NEAR": "near-near-protocol",
    "VET": "vet-vechain",
    "FIL": "fil-filecoin",
    "ICP": "icp-internet-computer",
    "LUNC": "luna-terra",
    "LDO": "ldo-lido-dao",
    "FRAX": "frax-frax",
    "CRO": "cro-cryptocom-chain",
    "HBAR": "hbar-hedera-hashgraph",
    "WBNB": "wbnb-wrapped-bnb",
    "EOS": "eos-eos",
    "USDP": "usdp-paxos-standard-token",
    "TUSD": "tusd-trueusd",
    "BIT": "bit-bitdao",
    "BSV": "bsv-bitcoin-sv",
    "EGLD": "egld-elrond",
    "AAVE": "aave-new",
    "FLOW": "flow-flow",
    "THETA": "theta-theta-token",
    "USDD": "usdd-usdd",
    "XTZ": "xtz-tezos",
    "AXS": "axs-axie-infinity",
    "ZEC": "zec-zcash",
    "KCS": "kcs-kucoin-token",
    "HBTC": "hbtc-huobi-btc",
    "SAND": "sand-the-sandbox",
    "TWT": "twt-trust-wallet-token",
    "GUSD": "gusd-gemini-dollar",
    "FTM": "ftm-fantom",
    "BTTC": "bttc-bittorrent-chain",
    "MANA": "mana-decentraland",
    "CAKE": "cake-pancakeswap",
    "GRT": "grt-the-graph",
    "APT": "apt-aptos",
    "MKR": "mkr-maker",
    "MIOTA": "miota-iota",
    "PAXG": "paxg-pax-gold",
    "CHZ": "chz-chiliz",
    "DASH": "dash-dash",
    "KLAY": "klay-klaytn",
    "NEO": "neo-neo",
    "XAUT": "xaut-tether-gold",
    "RUNE": "rune-thorchain",
    "XEC": "xec-ecash",
    "FEI": "fei-fei-protocol",
    "BKC": "bkc-bankcoin-cash",
    "SNX": "snx-synthetix-network-token",
    "NEXO": "nexo-nexo",
    "MINA": "mina-mina-protocol",
    "OSMO": "osmo-osmosis",
    "FXS": "fxs-frax-share",
    "GT": "gt-gatechain-token",
    "CCXX": "ccxx-counosx",
    "GMX": "gmx-gmx",
    "1INCH": "1inch-1inch",
    "CSPR": "cspr-casper-network",
    "XDC": "xdc-xdc-network",
    "FTT": "ftt-ftx-token",
    "STX": "stx-stacks",
    "CRV": "crv-curve-dao-token",
    "USDN": "usdn-neutrino-usd",
    "DCR": "dcr-decred",
    "DFI": "dfi-defi-chain",
    "ZIL": "zil-zilliqa",
    "BAT": "bat-basic-attention-token",
    "XEM": "xem-nem",
    "LRC": "lrc-loopring",
    "XCN": "xcn-chain",
    "HOT": "hot-holo",
    "LUNA": "luna-terra-v2",
    "BAL": "bal-balancer",
    "CELO": "celo-celo",
    "ENJ": "enj-enjin-coin",
    "BTG": "btg-bitcoin-gold",
    "MV": "mv-gensokishi-metaverse",
    "RVN": "rvn-ravencoin",
    "ENS": "ens-ethereum-name-service",
    "CVX": "cvx-convex-finance",
    "KAVA": "kava-kava",
    "CEL": "cel-celsius",
    "AR": "ar-arweave",
    "RPL": "rpl-rocket-pool",
    "HNT": "hnt-helium",
    "GNO": "gno-gnosis",
    "OP": "op-optimism",
    "EDGT": "edgt-edgecoin",
    "ROSE": "rose-oasis-network",
    "CHSB": "chsb-swissborg",
    "IOTX": "iotx-iotex",
    "USTC": "ust-terrausd",
    "KSM": "ksm-kusama",
    "BNX": "bnx-binaryx",
    "YFI": "yfi-yearnfinance",
    "TFUEL": "tfuel-theta-fuel",
    "KDA": "kda-kadena",
    "QTUM": "qtum-qtum",
    "GLM": "glm-golem",
    "LUSD": "lusd-liquity-usd",
    "JST": "jst-just",
    "SNM": "snm-sonm",
    "XCH": "xch-chia-",
    "XYM": "xym-symbol",
    "JASMY": "jasmy-jasmycoin",
    "MASK": "mask-mask-network",
    "GLMR": "glmr-moonbeam",
    "LN": "ln-link",
    "WAVES": "waves-waves",
    "ELON": "elon-dogelon-mars",
    "OMG": "omg-omg-network",
    "WOO": "woo-wootrade",
    "ASTR": "astr-astar",
    "FLUX": "zel-zelcash",
    "ONT": "ont-ontology",
    "HIVE": "hive-hive",
    "ONE": "one-harmony",
    "POLY": "poly-polymath",
    "ZRX": "zrx-0x",
    "IOST": "iost-iost",
    "AMP": "amp-amp",
    "RSR": "rsr-reserve-rights",
    "EURS": "eurs-stasis-eurs",
    "LPT": "lpt-livepeer",
    "AUDIO": "audio-audius",
    "GALA": "gala-gala",
    "DGB": "dgb-digibyte",
    "TEL": "tel-telcoin",
    "SUSHI": "sushi-sushi",
    "DYDX": "dydx-dydx",
    "ZEN": "zen-horizen",
    "SC": "sc-siacoin",
    "BTRST": "btrst-braintrust",
    "NFT": "nft-apenft",
    "CTC": "ctc-creditcoin",
    "MIR": "mir-mir-coin",
    "SXP": "sxp-swipe",
    "STORJ": "storj-storj",
    "BABYDOGE": "babydoge-baby-doge-coin",
    "UMA": "uma-uma",
    "RNDR": "rndr-render-token",
    "PEOPLE": "people-constitutiondao",
    "OCEAN": "ocean-ocean-protocol",
    "BORA": "bora-bora",
    "COMP": "comp-compoundd",
    "SCRT": "scrt-secret",
    "CDT": "cdt-blox",
    "WAXP": "wax-wax",
    "INJ": "inj-injective-protocol",
    "RLC": "rlc-iexec-rlc",
    "RBN": "rbn-ribbon-finance",
    "TRIBE": "tribe-tribe",
    "LSK": "lsk-lisk",
    "ANT": "ant-aragon",
    "EWT": "ewt-energy-web-token",
    "ABBC": "abbc-alibabacoin",
    "MC": "mc-merit-circle",
    "DEXE": "dexe-dexe",
    "MX": "mx-mx-token",
    "REQ": "req-request-network",
    "XNO": "xno-nano",
    "KNC": "knc-kyber-network",
    "ELF": "elf-aelf",
    "VGX": "ethos-ethos",
    "FET": "fetch-ai",
    "PUNDIX": "pundix-pundi-x",
    "MXC": "mxc-machine-xchange-coin",
    "HI": "hi-hi-dollar",
    "CVC": "cvc-civic",
    "SYS": "sys-syscoin",
    "API3": "api3-api3",
    "KAS": "kas-kaspa",
    "ILV": "ilv-illuvium",
    "RAD": "rad-radicle",
    "PLA": "pla-playdapp",
    "SNT": "snt-status",
    "CKB": "ckb-nervos-network",
    "NMR": "nmr-numeraire",
    "WIN": "win-winklink",
    "CELR": "celr-celer-network",
    "CTSI": "ctsi-cartesi",
    "ICX": "icx-icon",
    "DENT": "dent-dent",
    "XPRT": "xprt-persistence",
    "ERG": "efyt-ergo",
    "MDX": "mdx-mdex",
    "WRX": "wrx-wazirx",
    "KEEP": "keep-keep-network",
    "ARDR": "ardr-ardor",
    "SYN": "syn-synapse",
    "FUN": "fun-funfair",
    "MBOX": "mbox-mobox",
    "FX": "fx-function-x",
    "SOLO": "solo-sologenic",
    "REN": "ren-republic-protocol",
    "CGCX": "cgcx-cgcx",
    "DKA": "dka-dkargo",
    "PROM": "prom-prometeus",
    "ARRR": "arrr-pirate",
    "NEST": "nest-nest-protocol",
    "BSW": "bsw-biswap",
    "STEEM": "steem-steem",
    "ORBS": "orbs-orbs",
    "POWR": "powr-power-ledger",
    "XYO": "xyo-xyo-network",
    "AMPL": "ampl-ampleforth",
    "NU": "nu-nucypher",
    "COTI": "coti-coti",
    "SPELL": "spell-spell-token",
    "TRAC": "trac-origintrail",
    "NKN": "nkn-nkn",
    "XVS": "xvs-venus",
    "STRAX": "strax-stratis",
    "ADA Token": "meld-meld-ada-token",
    "BNT": "bnt-bancor",
    "LOCUS": "locus-locus-chain",
    "IQ": "iq-everipedia",
    "RACA": "raca-radio-caca",
    "UOS": "uos-ultra",
    "VTHO": "vtho-vethor-token",
    "LOOM": "loom-loom-network",
    "MFT": "mft-hifi-finance",
    "CHR": "chr-chromia",
    "DERO": "dero-dero",
    "AGIX": "agix-singularitynet",
    "BAND": "band-band-protocol",
    "WMT": "wmt-world-mobile-token",
    "ORC": "orc-orbit-chain",
    "DAG": "dag-constellation",
    "PHA": "pha-phala-network",
    "REEF": "reef-reef",
    "COL": "col-clash-of-lilliput",
    "POLYX": "polyx-polymesh",
    "QKC": "qkc-quarkchain",
    "CFX": "cfx-conflux-network",
    "REP": "rep-augur",
    "MTL": "mtl-metal",
    "YFII": "yfii-dfimoney",
    "SUSD": "susd-susd",
    "MRS": "mrs-metars-genesis",
    "REV": "rev-revain",
    "OGN": "ogn-origin-protocol",
    "XVG": "xvg-verge",
    "POLIS": "polis-star-atlas-dao",
    "ADS": "ads-adshares",
    "GHST": "ghst-aavegotchi",
    "FORTH": "forth-ampleforth-governance-token",
    "BTCV": "btcv-bitcoin-vault",
    "STPT": "stpt-stpt",
    "cUSD": "cusd-celo-dollar",
    "BWO": "bwo-battle-world",
    "DODO": "dodo-dodo",
    "TLM": "tlm-alien-worlds",
    "ARK": "ark-ark",
    "ACH": "ach-alchemy-pay",
    "RIF": "rif-rif-token",
    "MBX": "mbx-marblex",
    "MLN": "mln-enzyme",
    "UTK": "utk-utrust",
    "AERGO": "aergo-aergo",
    "SSX": "ssx-somesing",
    "DAR": "mines-of-dalarnia-dar",
    "MOVR": "movr-moonriver",
    "SFUND": "sfund-seedifyfund",
    "ANKR": "ankr-ankr-network",
    "IDRT": "idrt-rupiah-token",
    "ASD": "asd-ascendex-token",
    "PERP": "perp-perpetual-protocol",
    "TIME": "time-chronotech",
    "COCOS": "cocos-cocos-bcx",
    "RSS3": "rss3-rss3",
    "MBL": "mbl-moviebloc",
    "DIVI": "divi-divi",
    "KUNCI": "kunci-kunci-coin",
    "ALPACA": "alpaca-alpaca-finance",
    "DUSK": "dusk-dusk-network",
    "XCAD": "xcad-xcad-network",
    "SEELE": "seele-seele",
    "POLS": "pols-polkastarter",
    "SUPER": "super-superfarm",
    "CON": "con-conun",
    "ARPA": "arpa-arpa-chain",
    "CTXC": "ctxc-cortex",
    "WAN": "wan-wanchain",
    "MTRG": "mtrg-meter-governance",
    "GODS": "gods-gods-unchained",
    "TLOS": "tlos-telos",
    "TRR": "trr-terran-coin",
    "TT": "tt-thunder-token",
    "YGG": "ygg-yield-guild-games",
    "RARE": "rare-superrare",
    "HSF": "hsf-hillstone",
    "META": "meta-metadium",
    "C98": "c98-coin98",
    "MONA": "mona-monacoin",
    "UFO": "ufo-ufo-gaming",
    "GAFI": "gafi-gamefi",
    "CET": "cet-coinex-token",
    "TRB": "trb-tellor",
    "BIFI": "bifi-beefyfinance",
    "LIT": "lit-litentry",
    "BOBA": "boba-boba-network",
    "LTO": "lto-lto-network",
    "PRO": "pro-propy",
    "AVA": "ava-travala",
    "RAY": "ray-raydium",
    "ERN": "ern-ethernity-chain",
    "YOOSHI": "yooshi-yooshi",
    "EUROC": "euroc-euro-coin",
    "GRS": "grs-groestlcoin",
    "STARL": "starl-starlink",
    "KP3R": "kp3r-keep3rv1",
    "NEBL": "nebl-neblio",
    "LAZIO": "lazio-lazio-fan-token",
    "IDEX": "idex-idex",
    "TOMO": "tomo-tomochain",
    "BTS": "bts-bitshares",
    "DIA": "dia-dia",
    "LCX": "lcx-lcx",
    "KMD": "kmd-komodo",
    "SBD": "sbd-steem-dollars",
    "ORN": "orn-orion-protocol",
    "CULT": "cult-cult-dao",
    "WILD": "wild-wilder-world",
    "AE": "ae-aeternity",
    "HUNT": "hunt-hunt",
    "LEVER": "lever-leverfi",
    "QUICK": "quick-quickswap",
    "ALCX": "alcx-alchemix",
    "UBT": "ubt-unibright",
    "RARI": "rari-rarible",
    "WNXM": "wnxm-wrapped-nxm",
    "KILT": "kilt-kilt-protocol",
    "BTM": "btm-bytom",
    "FCT": "fct-firmachain",
    "VRA": "vra-verasity",
    "QRDO": "qrdo-qredo",
    "CRE": "cre-carry",
    "GMT": "gmt-gomining-token",
    "AKT": "akt-akash-network",
    "WOZX": "wozx-efforce",
    "NULS": "nuls-nuls",
    "TROY": "troy-troy",
    "AIOZ": "aioz-aioz-network",
    "RFR": "rfr-refereum",
    "UPP": "upp-sentinel-protocol",
    "REI": "rei-rei-network",
    "MOC": "moc-mossland",
    "XMON": "xmon-xmon",
    "GTO": "gto-gifto",
    "SWISE": "swise-stakewise",
    "GAS": "gas-gas",
    "FARM": "farm-harvest-finance",
    "FIO": "fio-fio-protocol",
    "BADGER": "badger-badger",
    "SANTOS": "santos-santos-fc-fan-token",
    "XHV": "xhv-haven-protocol",
    "FSN": "fsn-fusion",
    "STRM": "strm-streamcoin",
    "AGLD": "agld-adventure-gold",
    "NOIA": "noia-syntropy",
    "IRIS": "iris-irisnet",
    "AQT": "aqt-alpha-quark-token",
    "FIRO": "firo-firo",
    "HEZ": "hez-hermez-network",
    "XPR": "xpr-proton",
    "EPX": "eps-ellipsis",
    "QLC": "qlc-qlc-chain",
    "COS": "cos-contentos",
    "BCD": "bcd-bitcoin-diamond",
    "BLZ": "blz-bluzelle",
    "EUM": "eum-elitium",
    "VITE": "vite-vite",
    "CENNZ": "cennz-centrality",
    "CHESS": "chess-tranchess",
    "DATA": "data-streamr-datacoin",
    "HERO": "hero-metahero",
    "ROUTE": "route-router-protocol",
    "FIS": "fis-stafi",
    "BURGER": "burger-burger-swap",
    "MAP": "map-map-protocol",
    "BNANA": "bnana-banana-token",
    "PNK": "pnk-kleros",
    "OM": "om-mantra-dao",
    "ADX": "adx-adex",
    "ELA": "ela-elastos",
    "STC": "stc-student-coin",
    "BMX": "bmx-bitmart-token",
    "SOUL": "soul-phantasma",
    "ALEPH": "aleph-alephim",
    "KEY": "key-selfkey",
    "WTC": "wtc-waltonchain",
    "PIT": "pit-pitbull",
    "VOXEL": "voxel-voxies",
    "KIN": "kin-kin",
    "AHT": "aht-ahatoken",
    "AMB": "amb-ambrosus",
    "DORA": "dora-dora-factory",
    "AMO": "amo-amo-coin",
    "AST": "ast-airswap",
    "SOS": "sos-opendao",
    "BEAM": "beam-beam",
    "TOKE": "toke-tokemak",
    "PRE": "pre-presearch",
    "HNS": "hns-handshake",
    "PIVX": "pivx-pivx",
    "VIB": "vib-viberate",
    "AION": "aion-aion",
    "APM": "apm-apm-coin",
    "ONG": "ong-ong",
    "MDT": "mdt-measurable-data-token",
    "AKRO": "akro-akropolis",
    "DX": "dx-dxchain-token",
    "KAR": "kar-karura",
    "PDEX": "default",
    "DOCK": "dock-dock",
    "SKL": "skl-skale",
    "VID": "vid-videocoin",
    "SKEY": "skey-skey-network",
    "GLEEC": "gleec-gleec-coin",
    "MATH": "math-math",
    "NRG": "nrg-energi",
    "AUTO": "auto-auto",
    "APX": "apx-apollox-token",
    "PROS": "pros-prosper",
    "HEGIC": "hegic-hegic",
    "ANC": "anc-anchor-protocol",
    "MIX": "mix-mixmarvel",
    "NIM": "nim-nimiq",
    "DERC": "derc-derace",
    "CPOOL": "cpool-clearpool",
    "QOM": "qom-shiba-predator",
    "SERO": "sero-super-zero",
    "DNT": "dnt-district0x",
    "HTR": "htr-hathor-network",
    "WISE": "wise-wise",
    "GARI": "gari-gari-network",
    "VELO": "velo-velo",
    "WABI": "wabi-wabi",
    "FUND": "fund-unification",
    "SDN": "sdn-shiden-network",
    "OXEN": "oxen-oxen",
    "SOLVE": "solve-solve",
    "ALBT": "albt-allianceblock",
    "FOR": "for-fortube",
    "OAX": "oax-oax",
    "ABT": "abt-arcblock",
    "SDAO": "sdao-singularitydao",
    "SENSO": "senso-senso",
    "GEL": "gel-gelato",
    "UNCX": "uncx-unicrypt",
    "SIX": "six-six",
    "ASM": "asm-assemble-protocol",
    "PENDLE": "pendle-pendle",
    "JUV": "juv-juventus-fan-token",
    "RAI": "rai-rai-reflex-index",
    "BABY": "baby-babyswap",
    "ZBC": "zbc-zebec-protocol",
    "FOX": "fox-fox-token",
    "ZKS": "zks-zkspace",
    "PNT": "pnt-pnetwork",
    "KICK": "kick-kickcoin",
    "DACXI": "dacxi-dacxi",
    "ADP": "adp-adappter-token",
    "APL": "apl-apollo-currency",
    "MVC": "mvc-mileverse",
    "RAE": "rae-receive-access-ecosystem",
    "WICC": "wicc-waykichain",
    "MCRT": "mcrt-magiccraft",
    "PKF": "pkf-firebird",
    "SPA": "spa-sperax",
    "PZM": "pzm-prizm",
    "VINU": "vinu-vita-inu",
    "BASIC": "basic-basic",
    "MSOL": "msol-marinade-staked-sol",
    "SHPING": "shping-shping",
    "SHIRYO-INU": "shiryo-inu-shiryo-inu",
    "WHALE": "whale-whale",
    "GRIN": "grin-grin",
    "SUKU": "suku-suku",
    "GOVI": "govi-govi",
    "PERL": "perl-perleco",
    "CRPT": "crpt-crypterium",
    "GoC": "goc-gocrypto",
    "BUY": "buy-buyingcom",
    "FRTS": "frts-fruits",
    "NUM": "num-numbers-protocol",
    "NAKA": "naka-nakamoto-games",
    "OVR": "ovr-ovr",
    "TITAN": "titan-titanswap",
    "BEPRO": "bepro-bet-protocol",
    "MCB": "mcb-mcdex",
    "BRG": "brg-bridge-oracle",
    "CRU": "cru-crust",
    "TEMCO": "temco-temco",
    "PCX": "pcx-chainx",
    "HIBS": "hibs-hiblocks",
    "EVZ": "evz-electric-vehicle-zone",
    "MOF": "mof-molecular-future",
    "QTCON": "qtcon-quiztok",
    "CBX": "cbx-cropbytes",
    "FTN": "ftn-fountain",
    "EAC": "eac-earthcoin",
    "STAT": "stat-stat",
    "EL": "el-elysia",
    "MINTME": "mintme-com-coin",
    "ZANO": "zano-zano",
    "SWASH": "swash-swash",
    "SYLO": "sylo-sylo",
    "SHFT": "default",
    "TRA": "tra-trabzonspor-fan-token",
    "CRP": "crp-crypton",
    "CAPS": "caps-ternoa",
    "SHILL": "shill-shill-token",
    "SWFTC": "swftc-swftcoin",
    "TORN": "torn-tornado-cash",
    "SOV": "sov-sovryn",
    "MTV": "mtv-multivac",
    "JUP": "jup-jupiter",
    "DLC": "dlc-diamond-launch",
    "BONDLY": "bondly-bondly",
    "DOGEDASH": "dogedash-doge-dash",
    "POOLZ": "poolz-poolz-finance",
    "BTR": "btr-bitrue-coin",
    "BITCNY": "bitcny-bitcny",
    "CELL": "cell-cellframe",
    "FB": "fb-fenerbahce-token",
    "DYP": "dyp-defi-yield-protocol",
    "DBC": "dbc-deepbrain-chain",
    "CGG": "cgg-chain-guardians",
    "EPIK": "epik-epik-prime",
    "GHX": "ghx-gamercoin",
    "WOOP": "woop-woonkly-power",
    "DFYN": "dfyn-dfyn-network",
    "WOM": "wom-wombat-exchange",
    "WRLD": "wrld-nft-worlds",
    "UBXT": "ubxt-upbots",
    "ROOK": "rook-keeperdao",
    "WIKEN": "wiken-project-with",
    "ORAI": "orai-oraichain-token",
    "GMM": "gmm-gamium",
    "MAN": "man-matrix-ai-network",
    "CMP": "cmp-caduceus",
    "TIFI": "tifi-tifi-token",
    "DEL": "del-decimal",
    "FUSE": "fuse-fuse-network",
    "INSUR": "insur-insurace",
    "MAHA": "maha-mahadao",
    "MSB": "msb-misbloc",
    "REVV": "revv-revv",
    "OGV": "default",
    "MUSE": "muse-muse",
    "ABYSS": "abyss-the-abyss",
    "ASTRO": "astro-astro",
    "GMCOIN": "gmcoin-gmcoin",
    "TABOO": "taboo-taboo-token",
    "TON": "ton-tokamak-network",
    "VSYS": "vsys-v-systems",
    "CPC": "cpc-cpcoin",
    "SLIM": "slim-solanium",
    "BLY": "bly-blocery",
    "GMEE": "gmee-gamee",
    "BLP": "blp-bullperks",
    "PBR": "pbr-polkabridge",
    "MITH": "mith-mithril",
    "HAM": "ham-hamster",
    "PXP": "pxp-pointpay",
    "DERI": "deri-deri-protocol",
    "PIAS": "pias-pias",
    "OOE": "ooe-openocean",
    "FRM": "frm-ferrum-network",
    "NHT": "default",
    "GEEQ": "geeq-geeq",
    "BRUSH": "brush-brush",
    "BLANK": "blank-blockwallet",
    "GENE": "gene-genopets",
    "NFTART": "nftart-nft-art-finance",
    "BOLT": "bolt-bolt",
    "OOKS": "ooks-onooks",
    "SWRV": "swrv-swerve",
    "PPT": "ppt-populous",
    "SNC": "snc-suncontract",
    "EMRX": "emrx-emirex-token",
    "CTCN": "ctcn-contracoin",
    "TNB": "tnb-time-new-bank",
    "PRDX": "prdx-paramountdax-token",
    "AOG": "aog-ageofgods",
    "GNY": "gny-gny",
    "LAMB": "lamb-lambda",
    "AOA": "aoa-aurora",
    "DOE": "doe-dogs-of-elon",
    "TAROT": "tarot-tarot",
    "STRP": "strp-strips-finance",
    "DSLA": "dsla-dsla-protocol",
    "0xBTC": "0xbtc-0xbitcoin",
    "HPB": "hpb-high-performance-blockchain",
    "TNC": "tnc-tnc-coin",
    "XOT": "xot-okuru-token",
    "MAPS": "maps-maps",
    "LIFE": "life-life-crypto",
    "KAMPAY": "kampay-kampay",
    "MTH": "mth-monetha",
    "K21": "k21-k21",
    "EXM": "exm-exmo-coin",
    "LAYER": "layer-unilayer",
    "OLT": "olt-oneledger",
    "VDG": "vdg-veridocglobal",
    "PLANETS": "planets-planetwatch",
    "SRK": "srk-sparkpoint",
    "WNT": "wnt-wicrypt",
    "SWINGBY": "swingby-swingby",
    "AME": "ame-amepay",
    "EFX": "efx-effect-network",
    "RFUEL": "rfuel-riodefi",
    "KOM": "kom-kommunitas",
    "ISIKC": "isikc-isiklar-coin",
    "RMESH": "rmesh-rightmesh",
    "TOKO": "toko-tokoin",
    "VXL": "vxl-voxel-x-network",
    "DEFIT": "defit-digital-fitness",
    "BTX": "btx-bitcore",
    "ROOBEE": "roobee-roobee",
    "EPK": "epk-epik-protocol",
    "LYM": "lym-lympo",
    "DAFI": "dafi-dafi-protocol",
    "SUTER": "suter-suterusu",
    "NBS": "nbs-new-bitshares",
    "MOVEZ": "movez-movez",
    "PICKLE": "pickle-pickle-finance",
    "THN": "thn-throne",
    "NORD": "nord-nord-finance",
    "BDP": "bdp-big-data-protocol",
    "DOGGY": "doggy-doggy",
    "GOF": "gof-golff",
    "BAZ": "baz-bazooka-token",
    "BSCS": "bscs-bsc-station",
    "ACT": "act-achain",
    "TOP": "top-top",
    "FCD": "fcd-freshcut-diamond",
    "GUM": "gum-gourmet-galaxy",
    "GMPD": "gmpd-gamespad",
    "BIX": "bix-bibox-token",
    "CBC": "cbc-cashbet-coin",
    "FEAR": "fear-fear",
    "SFG": "sfg-sfinance",
    "DEXA": "dexa-dexa-coin",
    "NRFB": "nrfb-nurifootball",
    "FCON": "fcon-spacefalcon",
    "LABS": "labs-labs-group",
    "LXT": "lxt-litex",
    "CARE": "care-carecoin",
    "INXT": "inxt-internxt",
    "OLE": "ole-openleverage",
    "IOI": "ioi-ioi-token",
    "UT": "ut-ulord",
    "XTAG": "xtag-xhashtag",
    "KLO": "klo-kalao",
    "NCASH": "ncash-nucleus-vision",
    "OPCT": "opct-opacity",
    "OPIUM": "opium-opium",
    "AAC": "aac-acute-angle-cloud",
    "SYP": "syp-sypool",
    "ZOON": "zoon-cryptozoon",
    "DAPPX": "dappx-dappstore",
    "2CRZ": "2crz-2crazynft",
    "XCUR": "xcur-curate",
    "SHOPX": "shopx-splyt",
    "DTA": "dta-data",
    "DOSE": "dose-dose",
    "DOWS": "dows-shadows",
    "PAR": "par-parachute",
    "CVN": "cvn-cvcoin",
    "NRV": "nrv-nerve-finance",
    "REM": "rem-remme",
    "ZEE": "zee-zeroswap",
    "NABOX": "nabox-nabox",
    "NEW": "new-newton",
    "SPO": "spo-spores-network",
    "TKY": "tky-thekey",
    "FTI": "fti-fanstime",
    "ECOM": "ecom-omnitude",
    "BCHA": "bcha-bitcoin-cash-abc",
    "NAX": "nax-nextdao",
    "UBEX": "ubex-ubex",
    "GNX": "gnx-genaro-network",
    "NIF": "nif-unifty",
    "DEC": "dec-decentr",
    "HBIT": "hbit-hashbit-blockchain",
    "SENATE": "senate-senate",
    "DOS": "dos-dos-network",
    "BOTTO": "botto-botto",
    "CWS": "cws-crowns",
    "PERI": "peri-peri-finance",
    "ONSTON": "onston-onston",
    "TULIP": "tulip-solfarm",
    "NIOX": "niox-autonio",
    "IBFK": "ibfk-istanbul-baakehir-fan-token",
    "JULD": "juld-julswap",
    "GHNY": "ghny-grizzly-honey",
    "WGP": "wgp-w-green-pay",
    "STEP": "step-step-finance",
    "HYVE": "hyve-hyve",
    "FYZ": "fyz-fyooz",
    "PBX": "pbx-paribus",
    "KCASH": "kcash-kcash",
    "PWAR": "pwar-polkawar",
    "CAPP": "capp-cappasity",
    "BEZ": "bez-bezop",
    "TH": "th-team-heretics-fan-token",
    "SNY": "sny-synthetify",
    "RENA": "rena-warena",
    "GTH": "gth-gather",
    "ACE": "ace-acent",
    "XY": "xy-xy-finance",
    "AZY": "azy-amazy",
    "WEST": "west-waves-enterprise",
    "DPET": "dpet-my-defi-pet",
    "BMON": "bmon-binamon",
    "PULI": "puli-puli",
    "RINGX": "ringx-ring-x-platform",
    "TCH": "tch-tigercash",
    "CTI": "cti-clintex-cti",
    "VIN": "vin-vinchain",
    "PRARE": "prare-polkarare",
    "STRONG": "strong-strong",
    "KWIK": "kwik-kwikswap-protocol",
    "INT": "int-internet-node-token",
    "ING": "ing-infinity-angel",
    "CYCLUB": "cyclub-cyclub",
    "MEL": "mel-melalie",
    "ADI": "adi-aditus",
    "FARA": "fara-faraland",
    "KOI": "koi-koi-network",
    "KTON": "kton-darwinia-commitment-token",
    "CNS": "cns-centric-swap",
    "VIDY": "vidy-vidy",
    "NEXT": "next-shopnext",
    "FLY": "fly-franklin",
    "UMB": "umb-umbrella-network",
    "PHNX": "phnx-phoenixdao",
    "MPH": "mph-88mph",
    "IBP": "ibp-innovation-blockchain-payment",
    "PVT": "pvt-pivot-token",
    "REDPANDA": "redpanda-redpanda-earth",
    "HIT": "hit-hitchain",
    "WSG": "wsg-wall-street-games",
    "FTG": "ftg-fantomgo",
    "1EARTH": "1earth-earthfund",
    "SOLX": "solx-soldex",
    "CHEEMS": "cheems-cheems",
    "ARGON": "argon-argon",
    "UIP": "uip-unlimitedip",
    "RUFF": "ruff-ruff",
    "AMM": "amm-micromoney",
    "MAKI": "maki-makiswap",
    "ENG": "eng-enigma",
    "HUSL": "husl-the-husl",
    "BOMB": "bomb-bomb",
    "CHRP": "chrp-chirpley",
    "BOX": "box-contentbox",
    "RIO": "rio-realio-network",
    "FBX": "fbx-finance-blocks",
    "UUU": "uuu-u-network",
    "TIDAL": "default",
    "BYN": "byn-beyond-finance",
    "XLN": "xln-lunaone",
    "KLEE": "klee-kleekai",
    "METIS": "metis-metis-token",
    "HOKK": "hokk-hokkaidu-inu",
    "CMCX": "cmcx-core-multichain",
    "MEDIA": "media-media-network",
    "BRISE": "brise-bitrise-token",
    "FWC": "fwc-qatar-2022-token",
    "XTI": "xti-us-crude-oil-spot-token",
    "SML": "sml-saltmarble",
    "POLA": "pola-polaris-share",
    "FDT": "fdt-frutti-dino",
    "ADADOWN": "adadown-adadown",
    "DOME": "dome-everdome",
    "CREDI": "credi-credefi",
    "TDROP": "tdrop-thetadrop",
    "ZCX": "zcx-unizen",
    "PAF": "paf-pacific",
    "BDX": "bdx-beldex",
    "BIDR": "bidr-binanceidr",
    "ICETH": "iceth-interest-compounding-eth-index",
    "POLC": "polc-polkacity",
    "SUZUME": "suzume-shita-kiri-suzume",
    "BIBL": "bibl-biblecoin",
    "USDEX": "usdex-usdex",
    "IRR": "irr-iranian-rial",
    "CCX": "ccx-clearcryptos",
    "ETM": "etm-ethersmart",
    "BA": "ba-boeing",
    "UFEWO": "default",
    "BTCST": "btcst-btc-standard-hashrate-token",
    "LQTY": "lqty-liquity",
    "RING": "ring-darwinia-network-native-token",
    "BORING": "boring-boringdao",
    "LYO": "lyo-lyo-credit",
    "AKITA": "akita-akita-inu",
    "PESA": "pesa-pesabase",
    "SYL": "syl-xsl-labs",
    "GFX": "gfx-gamyfi-token",
    "BTCUP": "btcup-btcup",
    "SQUA": "squa-square",
    "FTRB": "ftrb-faith-tribe",
    "BREED": "breed-breederdao",
    "GBK": "gbk-gbank-apy",
    "TPY": "tpy-thrupenny",
    "ARC": "arc-crypto-arc",
    "ENV": "env-envoy-via-chainportio",
    "LWC": "lwc-linework-coin",
    "ULX": "ulx-ultron",
    "LUNR": "lunr-lunr-token",
    "WMX": "wmx-wombex",
    "XETA": "xeta-xana",
    "LYXE": "lyxe-lukso-token",
    "SILK": "silk-silkchain",
    "PS1": "ps1-polysports",
    "IPX": "ipx-inpulsex",
    "EPAN": "epan-paypolitan-token",
    "BCASH": "bcash-bankcoin",
    "LSS": "lss-lossless",
    "UBI": "ubi-universal-basic-income",
    "ABC": "abc-abc-chain",
    "MMPRO": "mmpro-mmpro-token",
    "BPTC": "bptc-tomato-coin",
    "SETH2": "seth2-seth2",
    "AZERO": "azero-aleph-zero",
    "EXFI": "exfi-flare-finance",
    "UBX": "ubx-ubix-network",
    "AVAX3S": "avax3s-3x-short-for-avax",
    "SSV": "ssv-ssv-network",
    "BWRC": "bwrc-werecoin",
    "EURT": "eurt-tether-eurt",
    "IMT": "imt-imov",
    "BETH": "beth-binance-beacon-eth",
    "MOBX": "mobx-mobix",
    "G": "g-grn-grid",
    "ARTIC": "artic-artic-foundation",
    "VEMP": "vemp-vempire-gamer-token",
    "GBP": "gbp-pound-sterling-token",
    "ERC-20": "xed-exeedme-erc-20",
    "XRPAYNET": "xrpaynet-xrpaynet",
    "SD": "sd-stader",
    "BAKE3L": "bake3l-bake3l",
    "DIE": "die-die-protocol",
    "EARN": "earn-earnguild",
    "QI": "default",
    "HTB": "htb-hotbit-token",
    "RCCC": "rccc-rccc",
    "UXD": "default",
    "NCR": "ncr-neos-credits",
    "POO": "poo-poomoon",
    "SUNNY": "sunny-sunny-aggregator",
    "VXLD": "default",
    "JEWEL": "jewel-defi-kingdoms",
    "MXT": "mxt-mixtrust",
    "QMALL": "qmall-qmall-token",
    "TRIBL": "default",
    "ATT": "att-artube",
    "OAS": "oas-oases-chain",
    "EAGON": "eagon-eagonswap-token",
    "LUNG": "lung-lunagens",
    "ZURR": "zurr-zurrency",
    "ADAUP": "adaup-adaup",
    "TVK": "tvk-the-virtua-kolect",
    "AGLA": "agla-angola",
    "NFTN": "nftn-nftnetwork",
    "LOC": "loc-locktrip",
    "PCC": "pcc-pcore",
    "KT": "kt-kingdomx",
    "TGT": "tgt-thorwallet",
    "ROG": "rog-rogin-ai",
    "WE": "we-webuy",
    "IBAT": "ibat-battle-infinity",
    "DREP": "drep-drep",
    "TRDC": "trdc-traders-coin",
    "AD": "default",
    "FNB": "fnb-finexboxtoken",
    "JCR": "jcr-justcarbon-removal",
    "NFTA": "nfta-nfraction-token",
    "ATA3L": "ata3l-ata3l",
    "LEASH": "leash-doge-killer",
    "ADCO": "adco-advertise-coin",
    "Arbitrum": "magic-magic-arbitrum",
    "ATA": "ata-automata",
    "MNZ": "mnz-menzy",
    "KRRX": "krrx-kyrrex",
    "XAG": "xag-silver-spot-token",
    "AGEUR": "ageur-ageur",
    "TDX": "tdx-tidex-token",
    "FXG": "fxg-fxg",
    "UPO": "upo-uponly-token",
    "DMTR": "dmtr-dimitra",
    "ULTRA": "ultra-ultrasafe",
    "TRX3L": "trx3l-trx3l",
    "ZEDXION": "zedxion-zedxion",
    "HOD": "hod-hodooicom-token",
    "SEUR": "seur-seur",
    "USD-N": "usd-n-usd-n",
    "AUCTION": "auction-bounce",
    "BTN": "btn-biten",
    "THE9": "default",
    "COVER": "cover-cover-protocol-governance-token",
    "DAX": "dax-dax-30-token",
    "GODE": "gode-gode-chain",
    "PLS": "pls-ipulse",
    "RITE": "rite-ritestream",
    "POI$ON": "default",
    "EU50": "eu50-euro-stoxx-50-token",
    "ETL": "etl-etherlite",
    "JUNO": "juno-juno-network",
    "SKET": "default",
    "SFP": "sfp-safepal-token",
    "ZEON": "zeon-zeon-network",
    "ADMN": "admn-adioman",
    "OHM": "ohm-olympus-v1",
    "CTK": "ctk-shentu",
    "DBT": "dbt-dengba-planet",
    "CPH": "cph-cypherium",
    "EGX": "egx-enegra",
    "MIM": "mim-magic-internet-money",
    "DRONE": "drone-drone-coin",
    "PARADOX": "paradox-paradox-metaverse",
    "LOG": "log-logos",
    "NUMI": "numi-numitor",
    "MUDOL2": "mudol2-hero-blaze-three-kingdoms",
    "TITI": "titi-titi-financial",
    "UUSD": "uusd-utopia-usd",
    "BNSD": "bnsd-bnsd-finance",
    "ETX": "default",
    "MAT": "mat-mirarc-chain",
    "BBT": "bbt-bitbook",
    "MCONTENT": "mcontent-mcontent",
    "B20": "b20-b20",
    "DETH": "deth-ether",
    "ABNBC": "abnbc-abnbc",
    "CHECK": "check-paycheck-defi",
    "GGP": "ggp-geegoopuzzle",
    "ICW": "icw-icryptoworld",
    "MVD": "mvd-metavault-dao",
    "BLH": "blh-blue-horizon",
    "NHCT": "nhct-hurricane-nft",
    "DEBT": "debt-debt",
    "ELG": "elg-escoin-token",
    "MSTO": "msto-millennium-sapphire",
    "DBI": "dbi-don39t-buy-inu",
    "FHTN": "fhtn-fishing-town",
    "CLEG": "cleg-chain-of-legends",
    "MARO": "maro-maro",
    "PLD": "pld-plutonian-dao",
    "SPN": "spn-sepanta-token",
    "TRAXX": "traxx-traxx",
    "ALGOBLK": "algoblk-algoblocks",
    "PLUG": "plug-plgnet",
    "MSQ": "msq-msquare-global",
    "XBE": "xbe-xbe-token",
    "BERRY": "berry-berry",
    "$HAWK": "hawk-hawksight",
    "XRDOGE": "xrdoge-xrdoge",
    "FAWA": "fawa-fantasy-war",
    "B2M": "b2m-bit2me",
    "DV": "dv-dreamverse",
    "FT": "ft-fracton-protocol",
    "THEX": "thex-thore-exchange",
    "BLZ3S": "blz3s-3x-short-for-blz",
    "BNS": "bns-bns-token",
    "DBTC": "dbtc-dfi-bitcoin",
    "BENX": "benx-bluebenx",
    "CRI": "cri-crypto-international",
    "PSL": "psl-pastel",
    "BAKE": "bake-bakeryswap",
    "EGGT": "eggt-egg-n-partners",
    "METAL": "metal-drunk-robots",
    "W3G": "w3g-w3g",
    "XT": "xt-xt-token",
    "CRYN": "cryn-cryncoin",
    "LON": "lon-tokenlon",
    "POLO": "polo-nftyplay",
    "SFT": "sft-scorefam",
    "SONAR": "sonar-sonarwatch",
    "XRT": "xrt-robonomics-network",
    "YOUC": "youc-youcash",
    "HECO": "dog-dogeswap-token-heco",
    "SPT": "spt-sportoken",
    "TEM": "tem-temtum",
    "WAXE": "waxe-waxe",
    "FISHINGLIF": "fishinglife-fishinglife-token",
    "LAT": "lat-platon-network",
    "STEMX": "stemx-stemx",
    "YCO": "yco-y-coin",
    "ATOM4L": "atom4l-atom4l",
    "TYRANT": "tyrant-fable-of-the-dragon",
    "BEND": "bend-bend-token",
    "UNC": "unc-utility-nft-coin",
    "GENS": "gens-genshiro",
    "MLT": "mlt-media-licensing-token",
    "VICE": "vice-vicewrld",
    "WAR": "war-westarter",
    "SCLP": "default",
    "MPL": "mpl-maple-finance",
    "ARX": "arx-arcs",
    "BOB": "default",
    "CRANE": "crane-crane-miners",
    "LEON": "leon-leon-token",
    "FID": "fid-fidira",
    "IRT": "irt-infinity-rocket-token",
    "LOOT": "loot-lootex-token",
    "ORO": "oro-oro",
    "MEER": "meer-qitmeer-network",
    "ZAL": "zal-zal-token",
    "JK": "jk-jk-coin",
    "XTUSD": "xtusd-xt-stablecoin-xtusd",
    "OHMv2": "ohmv2-olympus-v2",
    "TREES": "trees-safetrees",
    "ATOZ": "atoz-race-kingdom",
    "LOOKS": "looks-looksrare-token",
    "TKX": "tkx-tokenize-xchange",
    "APN": "apn-apron",
    "U": "u-unidef",
    "GBEX": "gbex-globiance-exchange",
    "XIV": "xiv-planet-inverse",
    "MIMATIC": "mimatic-mimatic",
    "OPNN": "opnn-opennity",
    "3QT": "3qt-3qt",
    "RIN": "rin-aldrin",
    "LBT": "lbt-law-blocks",
    "SANSHU": "sanshu-sanshu-inu",
    "1INCH3L": "1inch3l-3x-long-for-1inch",
    "DIKO": "diko-arkadiko",
    "PET": "pet-battle-pets",
    "PSC": "psc-petsneakers",
    "3ULL": "3ull-playa3ull-games",
    "MBASE": "mbase-minebase",
    "ANKR3L": "ankr3l-ankr3l",
    "DIONE": "dione-dione",
    "HVE2": "hve2-uhive",
    "BBC": "bbc-bull-btc-club",
    "ONX": "onx-onx-finance",
    "PORT": "port-teleport",
    "WBT": "wbt-whitebit",
    "PMON": "pmon-polychain-monsters",
    "ZTG": "ztg-zeitgeist",
    "ALPINE": "alpine-alpine-fan-token",
    "OOKI": "ooki-ooki",
    "ADA2.0": "ada20-cardano-20",
    "ADA3L": "ada3l-ada3l",
    "IVG": "ivg-ivogel",
    "DUSDT": "dusdt-dfi-tether",
    "MEAN": "mean-meanfi",
    "MOOLAH": "moolah-block-creatures",
    "TOPG": "topg-topg-coin",
    "00": "00-00-token",
    "MMC": "mmc-monopoly-millionaire-control",
    "AFD": "default",
    "BGVT": "bgvt-bit-game-verse-token",
    "ANC3S": "anc3s-anc3s",
    "HDRN": "hdrn-hedron",
    "HKMT": "hkmt-hk-monetary-token",
    "REMI": "remi-remi",
    "RYOMA": "ryoma-ryoma",
    "LOTT": "lott-lottrade",
    "SLEEPEE": "sleepee-sleepfuture",
    "HOP": "hop-hop-protocol",
    "HIDOODLES": "hidoodles-hidoodles",
    "PLUGCN": "plugcn-plug-chain",
    "BTT": "btt-bit-team-token",
    "GLCH": "glch-glitch-protocol",
    "BAKE3S": "bake3s-bake3s",
    "BIT3S": "bit3s-bit3s",
    "JW": "jw-jasan-wellness",
    "PATH": "path-pathdao",
    "KINTA": "kinta-kintaman",
    "GOAL": "goal-goal-token",
    "BLUE": "blue-blue-token",
    "CIND": "cind-cindrum",
    "$DCAP": "dcap-dcap",
    "ETH2": "eth2-eth-20-staking-by-pool-x",
    "ETHW": "ethw-ethereum-pow-on-poloniex",
    "SIN": "sin-sin-city",
    "VECT": "vect-vectorium",
    "NUTS": "nuts-nutsdao",
    "USDE": "usde-energi-dollar",
    "AXC": "axc-axia-coin",
    "GDT": "gdt-globe-derivative-exchange",
    "WSTETH": "wsteth-wrapped-liquid-staked-ether-20",
    "XNG": "xng-us-natural-gas-spot",
    "CDZ": "cdz-cdzexchange",
    "BICO": "bico-biconomy-network-token",
    "OPP": "opp-open-proprietary-protocol",
    "AXS5L": "axs5l-axs5l",
    "DOGENS": "default",
    "BSV3S": "bsv3s-bsv3s",
    "WX": "wx-wavesexchange-token",
    "FANZ": "fanz-frontfanz",
    "BRDG": "brdg-bridge-network",
    "YeFiM": "yefim-yfi-management",
    "AR3S": "ar3s-ar3s",
    "EVA": "eva-evanesco-network",
    "BCH4S": "bch4s-4x-short-for-bch",
    "GGG": "ggg-good-games-guild",
    "NAOS": "default",
    "WCV": "wcv-wellness-convertible",
    "CVNT": "cvnt-content-value-network",
    "BAT3L": "bat3l-bat3l",
    "PIS": "pis-polkainsure-finance",
    "FAN": "fan-fanspel",
    "HAI": "hai-hacken-token",
    "AVXL": "avxl-avaxlauncher",
    "BEMD": "bemd-betterment-digital",
    "GNS": "gns-gains-network",
    "Allbridge from Polygon": "apusdt-wrapped-usdt-allbridge-from-polygon",
    "DXGM": "dxgm-dexgame",
    "TOPB": "topb-topbtc-token",
    "AGLD3S": "agld3s-agld3s",
    "DEGO": "dego-degofinance",
    "PRI": "pri-privateum-initiative",
    "HCT": "hct-hurricane-token",
    "LSTAR": "lstar-learning-star",
    "WQT": "wqt-workquest",
    "EUL": "eul-euler",
    "WEI": "wei-wei",
    "RICE": "rice-riceswap",
    "SALE": "sale-dxsalenetwork",
    "XBR": "xbr-brent-crude-oil-spot",
    "API33S": "api33s-api33s",
    "KON": "kon-konpay",
    "UNITS": "units-unitedcoins",
    "Kobe": "kobe-shabu-shabu",
    "LINA": "lina-linear",
    "EPMAX": "epmax-fcdep",
    "FORGE": "forge-defi-forge",
    "USDJ": "default",
    "BUDG": "budg-bulldogswap",
    "KAZ": "kaz-kazano",
    "MIST": "mist-alchemist-coin",
    "TSLA": "tsla-tesla",
    "GATO": "gato-gato",
    "PLAY": "play-playmusic",
    "SIGN": "sign-sign",
    "Wormhole": "wavax-wrapped-avax-wormhole",
    "BNFT": "bnft-bruce-non-fungible-token",
    "T2E": "default",
    "VINA": "vina-vicuna",
    "WATT": "watt-wattton",
    "DFT": "dft-dfuture",
    "DJ": "dj-dj-token",
    "PWAY": "pway-pway",
    "TERA": "tera-terareum",
    "BABY KIM": "baby-kim-baby-kim",
    "FIWA": "fiwa-defi-warrior",
    "HUM": "hum-humanscape",
    "WAL": "wal-the-wasted-lands",
    "DR1$": "dr1-dr1ver",
    "KUN": "kun-chemix-ecology-governance-token",
    "CREAM": "cream-cream",
    "ELONHYPE": "elonhype-elonhype",
    "TALK": "talk-talkado",
    "DAGT": "dagt-digital-asset-guarantee-token",
    "WSHARE": "wshare-frozen-walrus-share",
    "JEM": "jem-jem",
    "BAL3S": "bal3s-bal3s",
    "NFTL": "nftl-nftl",
    "REAL": "real-reallink",
    "XAVA": "xava-avalaunch",
    "DNS": "dns-danny39s-suit",
    "DPX": "dpx-dopex",
    "1SOL": "1sol-1sol",
    "TCOIN": "tcoin-travel-coin",
    "ZILLIONXO": "zillionxo-zillion-aakar-xo",
    "SEAN": "sean-starfish-finance",
    "START": "start-bscstarter",
    "CPS": "cps-cryptostone",
    "MUU": "muu-muu",
    "AMUSDC": "amusdc-aave-matic-market-usdc",
    "STMX": "stmx-stormx-11212",
    "QTCC": "default",
    "SAITAMA": "saitama-saitama-inu",
    "ZIG": "zig-zignaly",
    "BIT3L": "bit3l-bit3l",
    "BTC-C": "btc-c-bitcoin-copy",
    "MMIT": "mmit-mangoman-intelligent",
    "YUSD": "yusd-yusd-stablecoin",
    "ETHS": "eths-ethereum-pos-on-poloniex",
    "$GRUSH": "grush-gold-rush-finance",
    "MTS": "mts-mtblock",
    "NEXM": "nexm-nexum",
    "PEPPA": "peppa-peppa-network",
    "PLEX": "plex-plex",
    "FDO": "fdo-firdaos",
    "TAMA": "tama-tamadoge",
    "CHEQ": "cheq-cheqd-network",
    "INDSHIB": "indshib-indian-shiba-inu",
    "RUSH": "rush-rushcoin",
    "PRB": "prb-paribu-net",
    "SHIBIB": "shibib-shiba-inu-billionaire",
    "OXT": "oxt-orchid-protocol",
    "THXC": "thxc-thxchain",
    "XOLO": "default",
    "HRTC": "hrtc-highrollertrader-coin",
    "LUCKY": "lucky-lucky-lion",
    "BCH5L": "bch5l-5x-long-for-bch",
    "BGC": "bgc-bagcoin",
    "ICE": "ice-iron-finance",
    "KLUV": "kluv-kismet-love",
    "TPT": "tpt-token-pocket",
    "GLQ": "glq-graphlinq",
    "ORT": "ort-okratech",
    "STLE": "stle-saint-ligne",
    "WELUPS": "welups-welups-blockchain",
    "FFT": "fft-farm-finance",
    "BAND3L": "band3l-band3l",
    "GGT": "ggt-goat-gang",
    "POLL": "poll-pollchain",
    "GDDY": "gddy-giddy",
    "NOAHP": "default",
    "CDOG": "cdog-cypherdog-token",
    "COOP": "coop-coop-network",
    "MCG": "mcg-microchains-gov-token",
    "NEOX": "neox-neoxa",
    "TOM": "tom-tom-finance",
    "TRU": "tru-truebit-protocol",
    "NYN": "nyn-nynjacoin",
    "SPY": "spy-snapy",
    "CBANK": "cbank-crypto-bank",
    "CML": "cml-camelcoin",
    "SLRR": "slrr-solarr",
    "XFC": "xfc-football-coin",
    "BIOT": "biot-bio-passport",
    "MULTI": "multi-multichain",
    "SRX": "srx-storx",
    "DOGMSK": "dogmsk-dog-masked",
    "VOLLAR": "vollar-v-dimension",
    "SEOR": "seor-seor-network",
    "PALM": "palm-palmswap",
    "TSC": "tsc-time-space-chain",
    "CBETH": "cbeth-coinbase-wrapped-staked-eth",
    "JRC": "jrc-finchain",
    "LCG": "lcg-lcg",
    "WPL": "wpl-worldplus",
    "BETA": "beta-polybeta-finance",
    "EWC": "ewc-erugo-world-coin",
    "XFUND": "xfund-xfund",
    "LF": "lf-linkflow",
    "NUGEN": "nugen-nugencoin",
    "PURE": "pure-puriever",
    "ATLAS3S": "atlas3s-atlas3s",
    "CARBO": "carbo-carbo",
    "KNDX": "kndx-kondux",
    "CHA": "default",
    "SGB": "sgb-songbird",
    "BPCN": "bpcn-blipcoin",
    "DF": "df-dforce-token",
    "URUS": "urus-aurox-token",
    "ACYC": "acyc-all-coins-yield-capital",
    "BADGER3L": "badger3l-badger3l",
    "BCL": "bcl-bitcoinlegend",
    "GM": "gm-gm-holding",
    "MLNK": "mlnk-malinka",
    "P-S-T-A-R": "p-s-t-a-r-parkstar",
    "BTCIO": "btcio-btcio",
    "GALEON": "galeon-galeon",
    "INP": "inp-inpoker",
    "HVT": "hvt-hyperverse",
    "IMPACTXP": "impactxp-impactxp",
    "HUB": "hub-hubcoin",
    "JACK": "jack-jack-token",
    "PLASTIK": "plastik-plastiks",
    "POKT": "pokt-pocket-network",
    "YTS": "yts-yetiswap",
    "ESHIB": "eshib-euro-shiba-inu",
    "REVO": "revo-revoland",
    "ALT": "default",
    "Solidly": "usdc-usdc-solidly",
    "IXT": "ixt-ix-token",
    "HTO": "hto-heavenland-hto",
    "APE3S": "ape3s-ape3s",
    "REF": "ref-ref-finance",
    "TFLOW": "default",
    "MASH": "mash-marshmallowdefi",
    "STSOL": "stsol-lido-staked-sol",
    "BCNA": "bcna-bitcanna",
    "HC": "hc-savebee-farm-honeycomb",
    "MGOLD": "mgold-mercenary",
    "GLAND/BNB": "glandbnb-griffin-land",
    "CORE": "core-coreum",
    "O3": "default",
    "CBFINU": "cbfinu-cbfinu",
    "LITH": "lith-lithium-finance",
    "SQUIDGROW": "squidgrow-squidgrow",
    "BKK": "bkk-bkex-chain",
    "FGD": "fgd-freedom-god-dao",
    "MWC": "default",
    "UNO": "uno-uno",
    "BOBA3S": "boba3s-boba3s",
    "FRIN": "frin-fringe",
    "BUX": "bux-bux-token",
    "EDC": "edc-edcoin",
    "TBL": "tbl-tank-battle",
    "WAS": "was-wasder",
    "KING": "king-king-swap",
    "WFLOW": "wflow-wrapped-flow",
    "AUD": "aud-australian-dollar-token",
    "BABYLLAMA": "babyllama-babyllama",
    "KSP": "ksp-klayswap-protocol",
    "THE": "the-the-protocol",
    "SCRAP": "scrap-scrap",
    "SFV2": "sfv2-shibafame-v2",
    "AXL": "axl-axl-inu",
    "JOE": "joe-trader-joe",
    "RXC": "rxc-ran-x-crypto",
    "DSP": "dsp-delio-dsp",
    "SAK": "sak-sakura-planet",
    "ACTN": "actn-action-coin",
    "$FUR": "fur-furio",
    "ES": "es-era-swap-token",
    "PIGGY": "piggy-piggy",
    "GMC": "gmc-gokumarket-credit",
    "GOM": "gom-gomics",
    "GHT": "ght-global-human-trust",
    "PD": "pd-palladium",
    "ZED": "zed-zed-run",
    "RBC3": "rbc3-ruby-currency",
    "STD": "std-stream-dungeons-token",
    "XAU": "xau-gold-spot-token",
    "DST": "dst-daystarter",
    "SIP": "sip-space-sip",
    "TSTR": "tstr-tech-star",
    "ZAIF": "zaif-zaigar-finance",
    "KCCPAD": "kccpad-kccpad",
    "MYT": "myt-mytrade",
    "SPR": "spr-spring-game",
    "AENS": "aens-aen-smart-token",
    "BTCA": "btca-bitcoin-asia8218",
    "KYOKO": "kyoko-kyoko",
    "AKRO3L": "akro3l-akro3l",
    "MDR": "mdr-mudra",
    "NNN": "default",
    "XHT": "xht-hollaex-token",
    "BRZ": "brz-brazilian-digital-token",
    "MELD": "meld-melandai",
    "SNOWFOX": "snowfox-snow-fox",
    "UNIC": "unic-unicly",
    "AKRO3S": "akro3s-akro3s",
    "HPX": "hpx-hupayx",
    "IP3": "ip3-cripco",
    "KICKS": "kicks-getkicks",
    "JET": "jet-jet",
    "LGX": "lgx-legion-network",
    "USDi": "usdi-usdi0",
    "KOK": "kok-kok-coin",
    "SCI": "sci-scimatic-coin",
    "GEIST": "geist-geist-finance",
    "HME": "hme-heavy-metal-elements",
    "KREX": "krex-kronn",
    "ANKR3S": "ankr3s-ankr3s",
    "BSV3L": "bsv3l-bsv3l",
    "XBLL": "default",
    "XEN": "xen-xen-crypto",
    "CHAIN": "chain-chain-games",
    "CHNG": "chng-chainge-finance",
    "FWT": "fwt-freeway-token",
    "FILDA": "filda-filda",
    "VIDT": "vidt-v-id",
    "TOOLS": "tools-tools",
    "CHF": "chf-swiss-franc-token",
    "CIRUS": "cirus-cirus",
    "DRIVECRYPT": "drivecrypt-drive-crypto",
    "GANY": "gany-ganymede",
    "OLAND": "oland-oceanland",
    "FOF": "fof-future-of-fintech",
    "IUX": "iux-geniux",
    "LKR": "lkr-lokr",
    "MIE": "mie-mie-network",
    "UMAMI": "umami-umami-finance",
    "KAWA": "kawa-kawa",
    "MNR": "mnr-mineral",
    "SA": "sa-superalgos",
    "ELU": "elu-elumia",
    "PLUT": "plut-plutos-network",
    "$TGK": "tgk-tugouking",
    "FIST": "fist-fisttoken",
    "LOKA": "loka-league-of-kingdoms",
    "GME": "gme-gamestop-finance",
    "MNTL": "mntl-mantle",
    "ZKT": "zkt-zktube",
    "AGLD3L": "agld3l-agld3l",
    "BAL3L": "bal3l-bal3l",
    "CNAME": "cname-cloudname",
    "HUGO": "hugo-hugo-finance",
    "ISLAMI": "islami-islamicoin",
    "MTK": "mtk-metatoken",
    "PAYN": "payn-paynet-coin",
    "SCC": "scc-siaclassic",
    "COFI": "cofi-cofix",
    "DEXSHARE": "dexshare-dexshare",
    "TFI": "tfi-trustfi-network-token",
    "XTT": "xtt-xswap-treasure",
    "TKM": "tkm-thinkium",
    "ASSA": "assa-assaplay",
    "DON": "don-donnie-finance",
    "TESLA": "tesla-tesla-token",
    "YOU": "you-youwho",
    "AUDIO3L": "audio3l-audio3l",
    "DCU": "dcu-decentralized-united",
    "MFO": "mfo-moonfarm-finance",
    "SOGA": "soga-soga-project",
    "ITT": "itt-international-trade-token",
    "BSCDUCO": "bscduco-duino-coin-on-bsc",
    "FOOD": "food-food-bank",
    "LEMD": "lemd-lemond",
    "SWAP": "swap-satoshiswap",
    "KRIPTO": "kripto-kripto",
    "PDX": "pdx-pdxcoin",
    "REV3L": "rev3l-rev3al",
    "FEED": "feed-feeder-finance",
    "LIST": "list-klist-protocol",
    "NSTEPN": "default",
    "OPENAI": "openai-openai",
    "WOMBAT": "wombat-wombat",
    "GTC": "gtc-gitcoin",
    "XELS": "xels-xels",
    "GCR": "gcr-gold-coin-reserve",
    "ADAPAD": "adapad-adapad",
    "BEL3L": "bel3l-3x-long-for-bel",
    "COOK": "cook-cook",
    "F7": "f7-five7",
    "PEN": "pen-protocon",
    "SLC": "slc-solice",
    "BAMBOO": "bamboo-bamboodefi",
    "GLB": "glb-golden-ball",
    "ANT3L": "ant3l-ant3l",
    "INPA": "inpa-inpa-coin",
    "PRMX": "prmx-prema",
    "ADK": "adk-aidos-kuneen",
    "PBT": "pbt-project-babel",
    "HIGH": "high-highstreet-token",
    "KINE": "kine-kine-protocol",
    "LHB": "lhb-lendhub",
    "OKG": "okg-ookeenga",
    "AGV": "agv-astra-guild-ventures-token",
    "MUTE": "mute-mute",
    "GART": "gart-griffin-art",
    "FEG": "feg-feg",
    "METANO": "default",
    "SOY": "soy-soy-finance",
    "CRAZYWORLD": "crazyworld-crazyworld",
    "CRF": "crf-crafting-finance",
    "XPO": "xpo-xpool",
    "ANT3S": "ant3s-ant3s",
    "ICH": "ich-ideachain",
    "NEPT": "nept-metanept",
    "SX": "sx-sx-network",
    "QUACK": "quack-richquack",
    "RAID": "raid-raider-token",
    "DUSD": "dusd-defidollar",
    "POLX": "polx-polylastic",
    "POODL": "poodl-poodl-token",
    "TRANQ": "tranq-tranquil-finance",
    "AURA": "aura-aura-finance",
    "FRONT": "front-frontier-token",
    "MCT": "mct-microtuber",
    "PIG": "pig-pig-finance",
    "XRUN": "xrun-xrun",
    "MCASH": "mcash-monsoon-finance",
    "METI": "meti-meetin-token",
    "YETU": "yetu-yetucoin",
    "BONES": "bones-moonshots-farm",
    "MHUNT": "mhunt-metashooter",
    "ILUS": "ilus-ilus-coin",
    "ELT": "elt-element-black",
    "GERMANY": "default",
    "GFARM2": "gfarm2-gains-v2",
    "NEC": "nec-nectar",
    "TRYB": "tryb-bilira",
    "SMRAT": "default",
    "VLX": "vlx-velas",
    "BCH4L": "bch4l-4x-long-for-bch",
    "IUP": "iup-infinitup",
    "JOY": "joy-drawshop-kingdom-reverse-joystick",
    "MANC": "manc-mancium",
    "HMR": "hmr-homeros",
    "SETH": "seth-seth",
    "SSW": "ssw-seesaw",
    "BVC": "bvc-crypto-chip",
    "CTX": "ctx-cryptex",
    "MNST": "mnst-moonstarter",
    "REVU": "revu-revuto",
    "BFI": "bfi-bearnfi",
    "L2P": "l2p-lung-protocol",
    "TRACE": "trace-trace-network-labs",
    "CMQ": "cmq-communique",
    "KUMA": "kuma-kuma-inu",
    "YES": "yes-yes-world",
    "ZILLA": "zilla-ukrainezilla",
    "BSC": "eth-ethereum-token",
    "MVDG": "mvdg-metaverse-dog",
    "AVAT": "avat-avata-network",
    "LOVELY": "lovely-lovely-inu-finance",
    "REDLC": "redlc-redlight-chain",
    "PLSD": "plsd-pulsedogecoin",
    "SPUME": "spume-spume",
    "TREEB": "treeb-treeb",
    "KUB": "kub-bitkub-coin",
    "MCP": "mcp-metacamp",
    "SBR": "sbr-saber",
    "PYR": "pyr-vulcan-forged",
    "CHEWY": "chewy-chewy",
    "FPRO": "fpro-fairpro",
    "MFI": "mfi-metfi",
    "MODEX": "modex-modex",
    "ORKL": "orkl-orakler",
    "PETT": "pett-pett-network",
    "SCK": "sck-space-chick",
    "URUB": "urub-urubit",
    "T1": "t1-test1",
    "XDEN": "xden-xiden",
    "XI": "xi-xi-token",
    "XSPECTAR": "xspectar-xspectar",
    "GENX": "genx-genx-token",
    "GSG": "gsg-gold-socialfi-gamefi",
    "ASAN": "asan-asan-verse",
    "XPAY": "xpay-wallet-pay",
    "CUBE": "cube-cube-network",
    "EFK": "efk-efk-token",
    "GROW": "grow-growingfi",
    "ALPA": "alpa-alpaca-city",
    "DBDT": "dbdt-digital-bdt",
    "PLI": "pli-plugin",
    "EURST": "eurst-euro-stable-token",
    "CVP": "cvp-powerpool-concentrated-voting-power",
    "ABIC": "abic-arabic",
    "BSEREY": "bserey-bserey-token",
    "MLX": "mlx-metaple-finance",
    "QTO": "qto-qtoken",
    "SHIDO": "shido-shido",
    "ISP": "isp-ispolink-token",
    "NEKO": "neko-the-neko",
    "DECV": "decv-ledgerfolder",
    "NMS": "nms-nemesis-dao",
    "CNX": "cnx-cryptonex",
    "GZX": "gzx-greenzonex",
    "TILL": "default",
    "ARKER": "arker-arker",
    "DGOD": "dgod-dogegod-token",
    "VEGA": "vega-vega-coin",
    "HFI": "hfi-hecofi",
    "LTE": "lte-listen-to-earn",
    "RETH": "reth-rocket-pool-eth",
    "ASTO": "asto-altered-state-token",
    "NAI": "nai-natiol",
    "CNFI": "cnfi-connect-financial",
    "CMN": "cmn-crypto-media-network",
    "UNFI": "unfi-unifi-protocol",
    "FSC": "fsc-fileshare-platform",
    "LEOS": "leos-leonicorn-swap",
    "PUSH": "push-ethereum-push-notification-service-epns",
    "LTN": "ltn-latinio",
    "MDB": "mdb-milliondollarbaby",
    "FACTR": "factr-defactor",
    "FSHR": "fshr-fishera-token",
    "OCT": "oct-octopus-network",
    "THOR": "thor-thor",
    "BSPT": "bspt-blocksport",
    "MS": "ms-morphswap",
    "TUSK": "tusk-mammothbet",
    "CEXD": "cexd-cexd",
    "CHARMY": "charmy-community",
    "KSC": "ksc-kstarcoin",
    "ANN": "ann-annex-finance",
    "INVI": "invi-invi-token",
    "OSQTH": "osqth-opyn-squeeth",
    "ANV": "anv-aniverse",
    "FRG": "frg-freedom-gold",
    "KANA": "kana-kanaloa-network",
    "KUR": "kur-kuro",
    "UCTC": "uctc-uct-cash",
    "CCOIN": "ccoin-crypteriumcoin",
    "AOW": "aow-art-of-war",
    "ORBR": "orbr-orbler",
    "BT": "bt-btfinance",
    "HDLN": "hdln-hodlers-network",
    "STMATIC": "stmatic-lido-staked-matic",
    "JRIT": "jrit-jeritex",
    "BOO": "boo-spookyswap",
    "CAW": "caw-a-hunters-dream",
    "JAWS": "jaws-autoshark",
    "RACEFI": "racefi-racefi",
    "TARM": "tarm-armtoken",
    "LOOBR": "loobr-loobr",
    "BOND": "bond-barnbridge",
    "UMEE": "umee-umee",
    "VT": "vt-virtual-tourist",
    "BGB": "bgb-bitget-token",
    "BITMETA": "bitmeta-bitcoin-metaverse",
    "PRNT": "prnt-prime-numbers",
    "RENS": "rens-rens",
    "HOLY": "holy-holy-trinity",
    "RVC": "rvc-revenue-coin",
    "TEND": "tend-tendon",
    "BKR": "bkr-balkari-token",
    "LOVE": "love-lovepot-token",
    "STKK": "stkk-streakk",
    "VMT": "vmt-vemate",
    "CLH": "clh-clearinghouse-token",
    "SHIK": "default",
    "ABS": "abs-autobusd",
    "GFLOKI": "gfloki-genshinflokiinu",
    "INV": "inv-inv",
    "MASS": "mass-mass",
    "SMARS": "smars-safemars-protocol",
    "CURE": "cure-cure-token-v2",
    "EXG": "exg-exg",
    "BUC": "buc-beau-cat",
    "EFI": "efi-efinity",
    "ATOMBULL": "atombull-3x-long-cosmos-token",
    "BADGER3S": "badger3s-badger3s",
    "BXMI": "bxmi-bxmi-token",
    "GLS": "gls-glasscoin",
    "MESH": "mesh-meshbox",
    "MAMMON": "mammon-mammontoken",
    "QUA": "qua-quasacoin",
    "AMUSDT": "amusdt-aave-matic-market-usdt",
    "DIVER": "diver-divergence-protocol",
    "QIE": "qie-qi-blockchain",
    "YLD": "yld-yield",
    "3DOG": "3dog-cerberus",
    "CHIk": "chik-chickenkebab-finance",
    "FRP": "frp-fame-reward-plus",
    "PLCU": "plcu-plc-ultima",
    "BFIC": "bfic-bficoin",
    "CMCC": "cmcc-cmc-coin",
    "HBB": "hbb-hubble",
    "OKSE": "okse-okse",
    "CHRO": "chro-chronicum",
    "OMC": "omc-omchain",
    "CAD": "cad-canadian-dollar-token",
    "ANGEL": "angel-angel-nodes",
    "FLZ": "flz-fellaz",
    "RLY": "rly-rally",
    "XBT": "xbt-xbit-coin",
    "BTCTRADE": "btctrade-bitcoin-trade",
    "ICSA": "icsa-icosa",
    "ECU": "ecu-ecoscu",
    "STG": "stg-stargod",
    "BLXM": "blxm-bloxmove",
    "SPX": "spx-sp-500",
    "TBC": "tbc-ten-best-coins",
    "VPAD": "vpad-vlaunch",
    "COLA": "cola-cola-token",
    "BLZ3L": "blz3l-3x-long-for-blz",
    "BNBUP": "bnbup-bnbup",
    "BSCD": "bscd-bsdium",
    "CUST": "cust-custody-token",
    "YTA": "yta-yottachain",
    "GAD": "gad-greenapp-dao",
    "CGO": "cgo-comtech-gold",
    "RABBIT": "default",
    "ZGD": "zgd-zambesigold",
    "BTCI": "btci-bitcoin-international",
    "IMPT": "default",
    "TARP": "tarp-totally-a-rug-pull",
    "BCA": "bca-bitcoiva",
    "COMB": "comb-combo",
    "RAIN": "rain-rainmaker-games",
    "ZAM": "zam-zamio",
    "AIPRO": "aipro-aipro",
    "SUN": "sun-sun",
    "KAINET": "kainet-kainet",
    "CLOUT": "clout-bitclout",
    "DJPLUS": "djplus-djplus",
    "$SCW": "scw-smart-crypto-watch-token",
    "OMI": "omi-ecomi",
    "HIODBS": "hiodbs-hiodbs",
    "TLP": "tlp-tlp-token",
    "LM": "lm-leisuremeta",
    "YETI": "yeti-yeti-finance",
    "KTX": "ktx-kwiktrust",
    "ZOO": "zoo-cryptozoo",
    "PIX": "pix-pixie",
    "LKC": "lkc-luckycoin",
    "WOOL": "wool-wolf-game-wool",
    "GTN": "gtn-relictumpro-genesis-token",
    "X2Y2": "x2y2-x2y2token",
    "FCC": "fcc-filecash",
    "AXS5S": "axs5s-axs5s",
    "OKT": "okt-okc-token",
    "CMI": "cmi-crypto-market-index",
    "DCC": "dcc-digital-chronic-coin",
    "MLS": "mls-metaland-shares",
    "NTVRK": "ntvrk-netvrk",
    "BNOX": "bnox-blocknotex",
    "FLIX": "flix-flix",
    "GYMNET": "gymnet-gym-network",
    "GSX": "gsx-gold-secured-currency",
    "JNTR": "jntr-jointer",
    "XPOP": "xpop-xpop",
    "DOGEDIGGER": "dogedigger-doge-digger",
    "SURE": "sure-insure",
    "FSCC": "fscc-fisco",
    "MBCASH": "mbcash-mbcash",
    "REFI": "refi-realfinance-network",
    "AI": "ai-multiverse",
    "TKO": "tko-tokocrypto",
    "TTF": "ttf-turbotrix-finance",
    "EVER": "ever-everscale",
    "RDN": "rdn-ride2earn",
    "ALICE": "alice-my-neighbor-alice",
    "CLV": "clv-clover-finance",
    "EQ": "eq-equilibrium-games",
    "GFT": "gft-game-fantasy-token",
    "MU": "mu-miracle-universe",
    "BUSY": "busy-busy-dao",
    "GCZ": "gcz-globalchainz",
    "DFD": "dfd-defidollar-dao",
    "LIEN": "lien-lien",
    "RADAR": "radar-dappradar",
    "NODE": "node-whole-network",
    "REDI": "redi-redi",
    "LCT": "lct-local-traders",
    "AXS3L": "axs3l-3x-long-for-axs",
    "BAXS": "baxs-boxaxis",
    "DTR": "dtr-dotori",
    "JUNI": "juni-juni-jackpot-universe",
    "wALV": "walv-alvey-chain",
    "BTCL": "btcl-bitcoinlatino",
    "RBD": "rbd-rubidium",
    "VTS": "vts-veritise",
    "SMOON": "smoon-saylormoon",
    "SBDO": "sbdo-bdollar-share",
    "SECO": "seco-serum-ecosystem-token",
    "DGP": "dgp-digipad",
    "LBK": "lbk-lbk",
    "BSDS": "bsds-basis-dollar-share",
    "PPC": "default",
    "VD": "vd-bitcoin-card",
    "SCY": "scy-synchrony",
    "MLK": "mlk-milk-alliance",
    "SFF": "sff-sunflower-farm",
    "TXA": "txa-txa",
    "ANA": "ana-nirvana-ana",
    "BEL": "bel-belrium",
    "HACHIKO": "hachiko-hachiko",
    "MTD": "mtd-minted",
    "RS": "rs-rabbit-share-coin",
    "TTC": "ttc-tao-te-ching",
    "GSBL": "gsbl-gasblock",
    "$VISION": "vision-visiongame",
    "ARPA3L": "arpa3l-arpa3l",
    "VNES": "vnes-vanesse",
    "GAL": "gal-galxe",
    "USDC.e": "usdce-usd-coine",
    "GHD": "ghd-giftedhands",
    "$SCATE": "scate-sincere-cate",
    "IVAR": "ivar-ivar-coin",
    "NZD": "nzd-new-zealand-dollar-token",
    "WCS": "wcs-weecoins",
    "KATS": "kats-straykats",
    "BSOCIAL": "bsocial-banksocial",
    "FLOKI": "floki-floki-inu",
    "HTT": "htt-hello-art",
    "SSS": "sss-starsharks",
    "TDLC": "tdlc-the-davis-legacy-coin",
    "HBTCQ": "hbtcq-hbtcq",
    "OGZD": "ogzd-gazprom-pjsc-adr",
    "CXO": "cxo-cargox",
    "FST": "fst-futureswap",
    "TSUKA": "tsuka-dejitaru-tsuka",
    "PRDS": "prds-brise-paradise",
    "WEOWNS": "weowns-weowns",
    "ERP": "erp-entropyfi",
    "FXC": "fxc-futurexcrypto",
    "NDX": "ndx-nasdaq-100-token",
    "POD": "pod-podo-point",
    "GAW": "default",
    "OSAKU": "osaku-the-tale-of-osaku",
    "BTCIX": "btcix-bitcolojix",
    "ALPHA": "alpha-alpha-finance",
    "BPLC": "bplc-blackpearl-token",
    "MUSK": "musk-musk",
    "AGSPAD": "agspad-aegis-launchpad",
    "CHER": "cher-cherry-network",
    "FDC": "fdc-fidance",
    "SHINJA": "shinja-shibnobi",
    "WMATIC": "wmatic-wrapped-matic-wormhole",
    "PHB": "phb-red-pulse-phoenix",
    "SONIC": "sonic-sonic-token",
    "AWO": "awo-aiwork",
    "BAND3S": "band3s-band3s",
    "BTCD": "btcd-bitdollars",
    "IMBTC": "imbtc-the-tokenized-bitcoin",
    "JUNK": "junk-junk-token",
    "TYCO": "tyco-tycooncoin",
    "HWT": "default",
    "FBURN": "fburn-forever-burn",
    "WGLMR": "wglmr-wrapped-moonbeam",
    "MT": "mt-mytoken",
    "$RYS": "rys-rys",
    "SON": "son-souni-token",
    "$STAR": "star-star-inu-token",
    "PCI": "pci-payprotocol-paycoin",
    "AZN": "azn-astrazeneca-usd",
    "ETF": "etf-ethereumfair",
    "KIC": "kic-koisan",
    "NITRO": "nitro-nitro-league",
    "ATTN": "attn-attn",
    "KROM": "krom-kromatikafinance",
    "MSWAP": "mswap-moneyswap",
    "JONES": "jones-jones-dao",
    "PANDO": "pando-pando",
    "MEGA": "mega-mega-protocol",
    "VOW": "vow-vow",
    "BABYVDOGE": "babyvdoge-baby-virus-doge",
    "HIBAYC": "hibayc-hibayc",
    "LAVAX": "lavax-lavax-labs",
    "YFDAI": "yfdai-yfdaifinance",
    "BCH5S": "bch5s-5x-short-for-bch",
    "EVMOS": "evmos-evmos",
    "LKD": "lkd-linkdao",
    "CAN": "can-channels",
    "BAT3S": "bat3s-bat3s",
    "DVF": "dvf-rhinofi",
    "MOR": "mor-moreal",
    "RI": "ri-ri-token",
    "BF": "bf-bitforex",
    "M7V2": "m7v2-m7v2",
    "RUBY": "ruby-realms-of-ruby",
    "CALI": "cali-calicoin",
    "ZTC": "ztc-zeto",
    "SEFI": "sefi-sefi",
    "SQAD": "sqad-sq",
    "BNBDOWN": "bnbdown-bnbdown",
    "NAVI": "navi-navibration",
    "YLGY": "ylgy-sheepasheep",
    "AXS3S": "axs3s-3x-short-for-axs",
    "PLM": "plm-pulegram",
    "TIPSY": "tipsy-tipsy",
    "YASHA": "yasha-yashadao",
    "CHE": "che-cherryswap",
    "MNT": "mnt-micronft",
    "$NMKR": "nmkr-nmkr",
    "GQ": "gq-galactic-quadrant",
    "ANML": "anml-animal-concerts",
    "SYM": "sym-symverse",
    "CAT": "cat-cat",
    "PBTC": "pbtc-polybtc",
    "SCREAM": "scream-scream",
    "WTK": "wtk-wadzpay-token",
    "ALICE3S": "alice3s-alice3s",
    "NJT": "njt-nj-token",
    "BMC": "bmc-bountymarketcap",
    "MTVT": "mtvt-metaverser",
    "CHS": "chs-cheesemakerfarm",
    "EATER": "eater-eat-to-earn",
    "MM": "mm-millimeter",
    "CZAR": "czar-chainex-zar",
    "PLT": "plt-palette",
    "SHELL": "shell-shell-token",
    "$BEAT": "beat-metabeat",
    "EUR": "eur-euro-token",
    "PORN": "porn-porn-dao",
    "BEL3S": "bel3s-3x-short-for-bel",
    "ELAN": "elan-elan",
    "SHIBLITE": "shiblite-shibalite",
    "JFI": "jfi-jackpoolfinance",
    "JFIN": "jfin-jfin-coin",
    "CELEB": "celeb-celeb",
    "NOS": "nos-nosana",
    "RNDX": "rndx-round-x",
    "GULF": "gulf-gulfcoin",
    "WING": "wing-wing-finance",
    "BPROSUS": "bprosus-prosus-bsc",
    "SAFEZONE": "safezone-safezone",
    "SOLR": "solr-solrazr",
    "VHC": "vhc-vault-hill-city",
    "LEX": "lex-elxis",
    "PERRY": "perry-swaperry",
    "Q2": "q2-pocketful-of-quarters",
    "RADIO": "radio-radioshack-token",
    "SBERru": "sberru-sberbank-rossii",
    "3CRV": "3crv-lp-3pool-curve",
    "POOL": "pool-pooltogether",
    "ATOM4S": "atom4s-atom4s",
    "GOMT": "gomt-gomeat",
    "POWA": "powa-powabit",
    "AR3L": "ar3l-ar3l",
    "axlUSDC": "axlusdc-axelar-usd-coin",
    "DFW": "dfw-dfw-token",
    "FISTSHIB": "fistshib-fistshib",
    "KKT": "kkt-kingdom-karnage",
    "UFT": "uft-unilend-finance",
    "ANC3L": "anc3l-anc3l",
    "HMT": "hmt-human-protocol",
    "DOGESX": "dogesx-doge-spacex",
    "PRUF": "pruf-prf-protocol",
    "ALICE3L": "alice3l-alice3l",
    "DXP": "dxp-dexpools",
    "SPOOL": "spool-spool-dao-token",
    "IZI": "izi-izumi-finance",
    "CST": "cst-cryptostake",
    "KRP": "krp-krypto-republic",
    "VMS": "default",
    "GAMI": "gami-gami-world",
    "RTC": "rtc-reltime",
    "UCT": "uct-universal-community-trust",
    "ATLAS3L": "atlas3l-atlas3l",
    "UCX": "ucx-ucx",
    "NTG": "ntg-newtowngaming",
    "TAI": "tai-tbridge-token",
    "PHCR": "phcr-photochromic",
    "POL": "pol-proof-of-liquidity",
    "BCH3S": "bch3s-bch3s",
    "COLLIE": "collie-collie-inu",
    "FIRE": "fire-the-phoenix",
    "DRC": "drc-drc-mobility",
    "PPM": "ppm-punk-panda-messenger",
    "UNDEAD": "undead-undead-blocks",
    "USDT.e": "usdte-tether-usde",
    "WLRS": "wlrs-walrus",
    "ARPA3S": "arpa3s-arpa3s",
    "CNG": "cng-changer",
    "SINGH": "singh-singhz",
    "ALI": "ali-artificial-liquid-intelligence-token",
    "FC": "fc-fanscoin",
    "1TREE": "1tree-1treecoin",
    "KNG": "kng-kanga-exchange-token",
    "MEET": "meet-coinmeet",
    "SLG": "slg-land-of-conquest-slg",
    "WETUX": "wetux-wetux",
    "XGLI": "xgli-glitter-finance",
    "KISHU": "kishu-kishu-inu",
    "SEED": "seed-treedefi-token",
    "HIPUNKS": "hipunks-hipunks",
    "DUET": "duet-duet-governance-token",
    "VTX": "vtx-vector-finance",
    "37C": "37c-37protocol",
    "SHDW": "shdw-genesysgo-shadow",
    "VICA": "vica-vica-token",
    "YFX": "yfx-your-futures-exchange",
    "CHMB": "chmb-chumbi-valley",
    "MOOMOO": "moomoo-moomoo",
    "AVAX3L": "avax3l-3x-long-for-avax",
    "GAZPru": "gazpru-gazprom",
    "MET": "met-metagame",
    "SOON": "soon-soonaverse",
    "TAVA": "default",
    "API33L": "api33l-api33l",
    "BP": "bp-bunnypark",
    "CFXT": "cfxt-chainflix",
    "MEGG": "megg-metaegg-defi",
    "VRGW": "vrgw-virtual-reality-game-world",
    "LOL": "lol-emogi-network",
    "QLINDO": "qlindo-qlindo",
    "BMON-Z1": "bmon-z1-binamon-z1-planet",
    "LOON": "loon-loon-network",
    "OXP": "oxp-onxrp",
    "TBCC": "tbcc-tbcc",
    "APE3L": "ape3l-ape3l",
    "ZZ": "zz-zigzag",
    "COIN": "coin-coinbase-tokenized-stock",
    "BNIX": "bnix-bnix-token",
    "BEE": "bee-honeybee",
    "MMAI": "mmai-metamonkeyai",
    "FDM": "fdm-fandom",
    "METAUFO": "metaufo-metaufo",
    "WBTCBR": "wbtcbr-wrapped-bitcoinbr",
    "EMP": "emp-empricus-token",
    "MWAR": "mwar-memewars",
    "IT": "it-idc-token",
    "MOO": "moo-moo",
    "EMC": "emc-edumetrix-coin",
    "FLOKIN": "flokin-flokinomics",
    "PNC": "pnc-parallel-network",
    "PONYO": "ponyo-ponyo-inu",
    "NTC": "ntc-natcoin3270",
    "EXPO": "expo-exponential-capital",
    "MATRIXAPE": "matrixape-matrix-ape",
    "NUCLEUS": "nucleus-nucleus",
    "PIGS V2": "pigs-v2-pigs-v2",
    "YNDX": "yndx-yandex-n-v",
    "SYFI": "syfi-soft-yearn",
    "PO": "po-pomely",
    "AVT": "avt-artverse-token",
    "SCAM": "scam-antiscam",
    "AUV": "auv-viiida-gold",
    "ZEOS": "zeos-zeos",
    "GREENFUND": "greenfund-greenfund",
    "GRF": "grf-greenfund",
    "CANDY": "candy-candy-shop-finance",
    "$SHIBX": "shibx-shibx",
    "SUP8EME": "sup8eme-sup8eme",
    "WIDI": "widi-widiland",
    "THX": "thx-thorenext",
    "LEGEND": "legend-league-of-legends",
    "PTR": "ptr-payturn",
    "SAFU": "safu-ceezee-safu",
    "YB": "yb-yb",
    "BITB": "default",
    "QCSS": "qcss-quality-control-safety-system",
    "RBH": "rbh-robinhoodswap",
    "TLB": "tlb-the-luxury-coin",
    "JF": "jf-jswap-finance-token",
    "PH": "ph-phylums",
    "MSH": "msh-memshib",
    "DEE": "dee-deeptank",
    "BSC-HO": "bsc-ho-binance-peg-bsc-ho",
    "KS": "ks-kebapswap",
    "CYSHI": "cyshi-cybershinu",
    "SCOMF": "scomf-scomfit",
    "PTERIA": "pteria-pteria",
    "WEC": "wec-wholeearthcoin-from-mainnet",
    "ROME": "rome-rome-metaverse",
    "UPC": "upc-upcake",
    "CRING": "cring-darwinia-crab-network-native-token",
    "RUTRACKER": "rutracker-rutracker-metaverse",
    "REU": "reu-reucoin",
    "VEGAS": "vegas-vegasdoge",
    "SAT": "sat-smartx",
    "JUS": "jus-just-network",
    "BALKE": "balke-balker",
    "INUYASHA": "inuyasha-inuyasha",
    "TWIT": "twit-twitchety",
    "HUNNY": "hunny-pancake-hunny",
    "MUSKD": "muskd-muskdeercoin",
    "POLYBUNNY": "polybunny-pancake-bunny-polygon",
    "VAMPI": "vampi-vampire",
    "DAA": "daa-double-ace",
    "GODDI": "goddi-godding",
    "IAL": "ial-inheritanceart",
    "PDOX": "pdox-paradox",
    "MABO": "mabo-securecrypto",
    "PRVS": "prvs-fortuneswap",
    "TEX": "tex-tychexchange",
    "MDC": "mdc-mandelacoin",
    "MRUN": "mrun-metarun",
    "BTC006": "btc006-btc-token006",
    "STLUNA": "stluna-staked-luna",
    "ECM": "ecm-kabosuinu",
    "UL": "ul-uselink-chain",
    "ILIC": "ilic-ilicic",
    "THANOS": "thanos-thanos",
    "MADRID": "madrid-madridistoken",
    "WO": "wo-seafish",
    "LICM": "licm-tigersnest",
    "HALO": "halo-angelprotocol",
    "REGR": "regr-retrogression",
    "SEXT": "sext-sextil",
    "BREP": "brep-babyrep",
    "VIX": "vix-vixco",
    "SHIU": "shiu-luckyakita",
    "TAST": "tast-viralatafinance",
    "WONH": "wonh-xbabycryptocurrenciescapital",
    "ST": "st-saiyantoken",
    "WPEPS": "wpeps-wrapped-peps",
    "LILS": "lils-lilashiba",
    "PA": "pa-palmcat",
    "SPRE": "spre-spaceresort",
    "SCD": "scd-supercooldoge",
    "BLADEKING": "bladeking-blade-king",
    "CH": "ch-coinnitro",
    "WOS": "wos-projectquantum",
    "YAHOO": "yahoo-yahoo-finance",
    "MEME": "meme-octans",
    "FWW": "fww-financeworldwar",
    "SV": "sv-sunnyverse",
    "DDOG": "ddog-saturna",
    "CRPO": "crpo-partyprotocol",
    "FIREE": "firee-fireeater",
    "SWT": "swt-scam-watcher-token",
    "UKRAINE": "ukraine-rescue-ukraine",
    "NUBANK": "nubank-nubank",
    "BLST": "blst-cryptoworld",
    "VALENTINEF": "valentineforcheems-valentineforcheems",
    "RSH": "rsh-robinshibahood",
    "FMT": "fmt-fuckmeat",
    "SBER": "sber-sberbank-of-russia-gdr",
    "PENTS": "pents-pentstemon",
    "RHIN": "rhin-rhinoliths",
    "BLOC": "bloc-bitcoinsprocket",
    "LOLL": "loll-lolli",
    "ALPC": "alpc-gogodog",
    "TRADE": "trade-smart-trade-coin",
    "SATS": "sats-cryptotrades",
    "CNC": "cnc-global-china-cash",
    "DECI": "deci-officedecentralization",
    "ELV": "elv-elves",
    "CRCB": "crcb-securecrypto",
    "BAY": "bay-cryptobay",
    "SATP": "satp-tradertrust",
    "SAMSUNG": "samsung-samsung-metaverse",
    "OORBIT": "oorbit-oorbit",
    "SAD": "sad-special-activities-division",
    "DSD": "dsd-dynamic-set-dollar",
    "MEMEY": "memey-memey",
    "EASYDOLLAR": "easydollar-easy-dollar",
    "NMC": "nmc-numancia",
    "TWD": "twd-terra-world-token",
    "SWAPPYSWAP": "swappyswap-swappyswap",
    "TIGERHEAD": "tigerhead-tigerhead",
    "ER": "er-elessar",
    "BSCA": "bsca-smaugsnft",
    "CRCL": "crcl-cryptocloak",
    "DKING": "dking-doubleking-token",
    "FW": "fw-freewomen",
    "DOGEJAVU": "dogejavu-dogejavucom",
    "TTSS": "ttss-ttss-token",
    "RUBLEDAO": "rubledao-rubledao-token",
    "DMT": "dmt-darkmatter",
    "UPA": "upa-upaboga",
    "PARA": "para-paratoken",
    "METASEA": "metasea-metasea-token",
    "ETLT": "etlt-ethereum-lightning-token",
    "SSAP": "ssap-star-sapphire",
    "XSPACE": "xspace-xspace-token",
    "MOONPRO": "moonpro-moonpro",
    "WWCN": "wwcn-wrapped-widecoin",
    "LAR": "lar-linkart",
    "CHAMPION": "champion-champion-token",
    "TW": "tw-thewatcher",
    "FE": "fe-flatearth",
    "KPC": "kpc-keeps-coin",
    "WAGMI": "wagmi-wagmi-game",
    "BLN": "bln-blacknet",
    "USWAP": "uswap-ukraineswap",
    "METAFI": "metafi-metafi",
    "ADAM": "adam-adamoracle",
    "DNF": "dnf-dnft-protocol",
    "LQR": "lqr-liqrcoin",
    "SSN": "ssn-supersonic-finance",
    "ADVNT": "advnt-advantage",
    "SHIMBA": "shimba-shimba-token",
    "TS": "ts-tangsi",
    "NEXOFINTEC": "nexofintech-nexoio-fintech",
    "XLPG": "xlpg-stellarpayglobal",
    "ZVSA": "zvsa-zeldavsalien",
    "PLIC": "plic-pulpi-coin",
    "HEROES": "heroes-dehero",
    "POKER": "poker-poker",
    "PLANETVERS": "planetverse-planetverse",
    "FOMO": "fomo-aloha",
    "EGN": "egn-eugene",
    "WFPS": "wfps-wrappedfps",
    "APY": "apy-incredibleapy",
    "TOMB": "tomb-tomb5557",
    "BTMC": "btmc-bit-miner-chain",
    "BST": "bst-bitsten-token",
    "NKG": "nkg-neko-gold",
    "ACM": "acm-aucume",
    "DHS": "dhs-dirham-crypto",
    "SWIN": "swin-swincoin",
    "PTSU": "ptsu-profitsup",
    "NGT": "default",
    "PHO": "pho-phoenix-community-token",
    "ANDRO": "andro-andronodes",
    "NOF": "nof-noften",
    "OUD": "oud-oud",
    "DT": "dt-dragon-token",
    "AXZ": "axz-axiezilla",
    "RC": "rc-russellcoin",
    "BULLS": "bulls-bulls",
    "DVLD": "dvld-divi-land",
    "CRBA": "crba-elephantcompany",
    "NFTB": "nftb-nftbinance",
    "PT": "pt-predict",
    "IKC": "ikc-ikcoin",
    "BR": "br-beleriend",
    "MEND": "mend-mend-inu",
    "BORON": "boron-boronlink",
    "MBMX": "mbmx-metal-backed-money",
    "TYT": "tyt-tianya-token",
    "DMON": "dmon-dangermoon",
    "FBB": "fbb-foxboy-bsc",
    "SPAN": "span-shibapancake",
    "MRC": "mrc-myrealcoin",
    "HHE": "hhe-hhe",
    "BKU": "bku-babykishu-inu",
    "FOMOSWAP": "fomoswap-fomoswap",
    "DCD": "dcd-dcd-ecosystem",
    "WTS": "wts-whatsapp-token-powered-by-meta",
    "TTX": "ttx-titox",
    "MINIMIKO": "minimiko-minimiko",
    "PWT": "pwt-pelia-wallet-token",
    "HUKR": "hukr-helpukraine",
    "NEWCASH": "newcash-newcash",
    "MAYC": "mayc-mayc-coin",
    "$BAPE": "bape-basicape",
    "BS": "bs-bulldogswap",
    "RBI": "rbi-reserve-bank-of-india",
    "10SET": "10set-10set-token",
    "GAT": "gat-global-aex-token",
    "FNHL": "fnhl-cryptomoney",
    "SMZ": "smz-samz",
    "SPACE": "space-spacetoken",
    "CRCE": "crce-decentralizationbite",
    "STARK": "stark-stark-chain",
    "PINE": "pine-pine-token",
    "CRPJ": "crpj-cryptoplanet",
    "FCG": "fcg-funnycat-grumpy",
    "HEART": "heart-hearts",
    "MTRX": "mtrx-moontrx"
}

function getLogoFromCoin({coinName, coinSymbol}) {
    let coinImageName = '';

    if (coinmap.hasOwnProperty(coinSymbol)) {
        coinImageName = coinmap[coinSymbol];
    } else {
        coinImageName = coinSymbol.toLowerCase() + '-' + coinName.replaceAll(' ', '-').toLowerCase()
    }

    return 'https://cryptocurrencyliveprices.com/img/' + coinImageName + '.png'
}

export {
    getLogoFromCoin
}