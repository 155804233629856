import { MenuOpen, Search } from "@mui/icons-material";
import { Box, ButtonBase, IconButton, InputAdornment, TextField, useMediaQuery, useTheme } from "@mui/material";
import React, { useState } from 'react';
import Logo from './../../assets/all coin_02.png';
import { Auth } from "../../auth/Auth";
import { useLocation, useNavigate } from "react-router-dom";
import PropTypes from "prop-types";
import LanguageToggleButton from "../../nav/LanguageToggleButton";
import MobileDrawer from "./MobileDrawer";

const NavBar = ({showSearch = false, onSearchChange}) => {
    const navigate = useNavigate();
    const location = useLocation();
    const theme = useTheme();
    const isSm = useMediaQuery(theme.breakpoints.down('md'));
    const [drawerOpen, setDrawerOpen] = useState(false);
    const [searching, setSearching] = useState(false);

    const searchInput = showSearch &&
        <TextField
            size="medium"
            variant="standard"
            type={"search"}
            onBlur={(e) => {
                if (!e.target.value) {
                    setSearching(false);
                }
            }}
            onKeyUp={(e) => {
                if (e.key === "Enter") {
                    e.target.blur();
                }
            }}
            onChange={(e) => onSearchChange(e.target.value?.toLowerCase())}
            InputProps={{
                style: {
                    borderRadius: 30,
                    background: '#f0f0f0',
                    paddingLeft: 12
                },
                disableUnderline: true,
                endAdornment: (
                    <InputAdornment position="end" sx={{mr: 1}}>
                        <Search fontSize={'small'} color="#979797"/>
                    </InputAdornment>
                )
            }}
            sx={{
                width: isSm ? '160px' : 'unset',
                ml: 'auto',
                mr: {xs: 1, sm: 3}
            }}
        />

    return (
        <Box display='flex' flexDirection={'row'} justifyContent={'space-between'} alignItems={'center'} p={1}
             sx={{
                 boxShadow: '0px 5px 5px -5px rgba(0, 0, 0, 0.2)',
             }}>

            <ButtonBase
                disableRipple
                onClick={() => {
                    if (location.pathname === "/") {
                        navigate(0)
                    } else {
                        navigate("/")
                    }
                }}>
                <img src={Logo} height={36} alt='AllCoin'/>
            </ButtonBase>

            {isSm &&
                <Box display={'flex'} alignItems={'center'}>
                    {showSearch && !searching &&
                        <IconButton size={"small"} onClick={() => setSearching(true)}>
                            <Search/>
                        </IconButton>}
                    {searching && searchInput}
                    <IconButton size={"small"} onClick={() => setDrawerOpen(true)}>
                        <MenuOpen/>
                    </IconButton>
                </Box>
            }

            {isSm && <MobileDrawer isOpen={drawerOpen} toggleDrawer={(open) => setDrawerOpen(open)}/>}

            {!isSm && <Box display={'flex'} alignItems={'center'}>

                {searchInput}

                <LanguageToggleButton/>

                <Auth/>
            </Box>}

        </Box>
    )
}

NavBar.propTypes = {
    showSearch: PropTypes.bool,
    onSearchChange: PropTypes.func
};

export default NavBar;