import { Box } from "@mui/system";
import { useEffect, useState } from "react";
import { collection, doc, getDoc } from "firebase/firestore";
import { firestore } from "../firebase";

export default function Advertisement() {

    const [image, setImage] = useState();
    const [openUrl, setOpenUrl] = useState();

    const adRef = collection(firestore, 'advertisement');
    const fileDoc = doc(adRef, 'file');

    useEffect(() => {
        getDoc(fileDoc).then((imageDoc) => {
            const doc = imageDoc.data();
            setImage(doc.downloadUrl);
            setOpenUrl(doc.openUrl);
        })
    }, [fileDoc]);

    return (
        <Box sx={{ marginTop: '12px' }}>
            <a
                href={openUrl}
                target={'_blank'}
                referrerPolicy={'no-referrer'}
            >
                <div style={{
                    color: 'white',
                    backgroundRepeat: 'no-repeat',
                    backgroundSize: 'cover',
                    height: '200px',
                    width: '100%',
                    borderRadius: 12,
                    backgroundImage: `url("${image}")`
                }}>

                </div>
            </a>
        </Box>
    )
}