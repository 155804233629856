import {
    Avatar,
    CircularProgress,
    Dialog,
    DialogContent,
    DialogTitle,
    IconButton,
    List,
    ListItemButton,
    ListItemIcon,
    ListItemText, Typography
} from "@mui/material";
import { Box } from "@mui/system";
import HighlightOffIcon from '@mui/icons-material/HighlightOff';
import { useTranslation } from "react-i18next";
import DialogCloseIcon from "../../commons/DialogCloseIcon";


export function UsersListOnRoomDialog({users, isOpen, onClose, isLoading}) {
    const {t} = useTranslation();

    return (
        <Dialog
            open={isOpen}
            keepMounted
            sx={{
                backdropFilter: "blur(1px) sepia(1%)",
            }}
            PaperProps={{sx: {borderRadius: "26px"}}}>

            <DialogTitle sx={{textAlign: 'center'}}>

                <IconButton
                    onClick={onClose}
                    sx={{position: 'absolute', right: 12, top: 12}}>
                    <DialogCloseIcon onClick={onClose}/>
                </IconButton>
            </DialogTitle>

            <DialogContent sx={{width: { xs: 'unset', sm: '300px'}, p: 4}}>
                <Typography variant={'h5'} textAlign='center' fontWeight={800} sx={{mt: 2, mb: 3}}>
                    {t('users on this room')}
                </Typography>

                {isLoading &&
                    <Box
                        display="flex"
                        justifyContent="center"
                        alignItems="center"
                        minHeight={200}>
                        <CircularProgress/>
                    </Box>
                }

                <List sx={{maxHeight: 600}}>
                    {(users ?? []).map((user) => (
                        <ListItemButton key={user.uid}>
                            <ListItemIcon>
                                <Avatar src={user.avatar} sx={{width: 30, height: 30}}/>
                            </ListItemIcon>
                            <ListItemText primary={user.name}/>
                        </ListItemButton>
                    ))}
                </List>
            </DialogContent>
        </Dialog>
    )
}