import React from "react";
import { ButtonBase } from "@mui/material";
import CloseIcon from "../assets/close.png";
import PropTypes from "prop-types";

const DialogCloseIcon = ({ onClick }) => {
    return (
      <ButtonBase onClick={() => onClick()}>
          <img src={CloseIcon} height={30} width={30}/>
      </ButtonBase>
    );
}

DialogCloseIcon.propTypes = {
    onClick: PropTypes.func.isRequired
}

export default DialogCloseIcon;