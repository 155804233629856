import React from 'react';
import { Box, Button, Divider, Stack, Typography } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";

const Footer = () => {
    const navigate = useNavigate();
    const { t } = useTranslation();

    return (
        <Box mt={1} sx={{background: '#f5f5f5'}}>
            <Stack p={2} alignItems={'center'} justifyContent={'center'} direction={'row'}>
                <Button
                    size={'small'}
                    variant={'text'}
                    sx={{color: 'grey'}}
                    onClick={() => navigate('/terms-and-conditions')}>
                    { t('Terms and Conditions') }
                </Button>
                <Button
                    size={'small'}
                    sx={{ml: 2, color: 'grey'}}
                    variant={'text'}
                    onClick={() => navigate('/privacy-policy')}>
                    { t('Privacy Policy') }
                </Button>
                <Button
                    size={'small'}
                    sx={{ml: 2, color: 'grey'}}
                    variant={'text'}
                    onClick={() => window.location.href = 'mailto:admin@allcoin.co.kr'}>
                    { t('Contact Ad agency') }
                </Button>

            </Stack>

            <Divider/>

            <Typography textAlign={'center'} color={'#acacac'} sx={{py: 4}}>
                허니컴즈  사업자등록번호: 381-33-00232  대표: 이호성  전화: 02-2065-5678  이메일: admin@allcoin.co.kr <br/>
                Copyright 2024(c) www.allcoin.co.kr All rights Reserved 
            </Typography>

        </Box>
    );
}

export default Footer;