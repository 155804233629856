import React, { useState } from 'react'
import { Box, ButtonBase } from "@mui/material";

export default function PriceChangeHeader(props) {

    const [selectedTime, setSelectedTime] = useState('24h')

    const {onPriceChangeDurationChange} = props;

    function button(label) {
        const selected = selectedTime === label;
        return (
            <ButtonBase
                onClick={() => {
                    setSelectedTime(label);
                    onPriceChangeDurationChange(label);
                }}
                style={{
                    padding: '0px 6px',
                    fontSize: 10,
                    fontWeight: 'bold',
                    height: '16px',
                    background: selected ? '#ff8736' : 'none',
                    borderRadius: '2px',
                    color: (selected ? 'white' : 'black')
                }}>
                {label}
            </ButtonBase>
        )
    }

    return (
        <Box display="flex" alignItems='center' fontSize={8}>
            {button('1h')}
            {button('4h')}
            {button('24h')}
        </Box>
    )
}