import React, { useEffect, useRef, useState } from 'react';
import { Box } from '@mui/system';
import {
    Avatar,
    Button,
    CircularProgress,
    Divider,
    IconButton,
    Menu,
    MenuItem,
    Stack,
    TextField,
    Typography
} from '@mui/material';
import { useParams } from 'react-router';
import {
    addDoc,
    collection,
    doc,
    getDoc,
    limit,
    onSnapshot,
    orderBy,
    query,
    serverTimestamp,
    updateDoc,
    where,
} from "firebase/firestore";
import MoreVertIcon from '@mui/icons-material/MoreVert';

import { auth, firestore, firestore as db, getUsersOnCurrentRoom } from './../../firebase';
import { UsersListOnRoomDialog } from './UsersListOnRoomDialog';
import { sendEmailVerification } from "firebase/auth";
import { useSnackbar } from "notistack";
import { ReportDialog } from "../../auth/ReportDialog";
import { useTranslation } from "react-i18next";
import Logo from "../../assets/all coin_02.png";
import { MoreHoriz, Send } from "@mui/icons-material";
import { realtimeDatabase } from "../../firebase"
import { serverTimestamp as realtimeTimestamp, set, ref } from "firebase/database";

function Chat({isStandalone = false, user}) {
    const [usersOnRoom, setUsersOnRoom] = useState(null);
    const [isUsersListOnRoomDialogOpen, setUsersListOnRoomDialogOpen] = useState(false);
    const {marketId} = useParams();
    const {t} = useTranslation();

    const messagesRef = collection(db, `messages`);

    const sendMessage = async (val) => {
        const {uid, photoURL, displayName} = user;

        await addDoc(messagesRef, {
            text: val,
            createdAt: serverTimestamp(),
            userId: uid,
            photoURL,
            displayName,
            coin: marketId
        });
    }

    const [anchorEl, setAnchorEl] = React.useState(null);
    const open = Boolean(anchorEl);
    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };

    useEffect(() => {
        if (!user) return;

        const usersRealtimeRef = ref(realtimeDatabase, '/users/' + user.uid);

        const marketOffline = {
            online: true,
            onlineMarket: '',
            lastOnlineChange: realtimeTimestamp(),
        };

        const marketOnline = {
            online: true,
            onlineMarket: marketId,
            lastOnlineChange: realtimeTimestamp(),
        };

        set(usersRealtimeRef, marketOnline)

        return () => set(usersRealtimeRef, marketOffline);
    }, [marketId, user]);

    const showUsersOnThisRoom = async () => {
        setUsersOnRoom(null);
        setUsersListOnRoomDialogOpen(true);

        setUsersOnRoom(await getUsersOnCurrentRoom(marketId));
    }

    return (
        <Box
            pb={1}
            sx={{
                border: '1px solid none',
                borderRadius: 4,
                boxShadow: '0px 0px 6px 2px #eee'
            }}>

            <UsersListOnRoomDialog
                users={usersOnRoom}
                isOpen={isUsersListOnRoomDialogOpen}
                isLoading={usersOnRoom === null}
                onClose={() => setUsersListOnRoomDialogOpen(false)}
            />

            <Stack
                direction="row"
                alignItems="center"
                justifyContent="space-between"
                px={2} py={1}>

                <img src={Logo} height={24} alt='AllCoin'/>

                <IconButton size="small" onClick={handleClick}>
                    <MoreHoriz fontSize={"small"}/>
                </IconButton>

                <Menu
                    anchorEl={anchorEl}
                    open={open}
                    onClose={handleClose}
                    onClick={handleClose}
                    PaperProps={{
                        elevation: 0,
                        sx: {
                            overflow: 'visible',
                            filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
                            mt: 1.5,
                            '& .MuiAvatar-root': {
                                width: 32,
                                height: 32,
                                ml: -0.5,
                                mr: 1,
                            },
                            '&:before': {
                                content: '""',
                                display: 'block',
                                position: 'absolute',
                                top: 0,
                                right: 14,
                                width: 10,
                                height: 10,
                                bgcolor: 'background.paper',
                                transform: 'translateY(-50%) rotate(45deg)',
                                zIndex: 0,
                            },
                        },
                    }}
                    transformOrigin={{horizontal: 'right', vertical: 'top'}}
                    anchorOrigin={{horizontal: 'right', vertical: 'bottom'}}
                >
                    <MenuItem onClick={() => showUsersOnThisRoom()}>
                        {t('show online users')}
                    </MenuItem>
                    {!isStandalone && <MenuItem onClick={() => window.open(window.location.href + '/chat',
                        t('all coins chat'), "menubar=1,resizable=1,width=450,height=650")}>
                        {t('open chat in new window')}
                    </MenuItem>}
                </Menu>
            </Stack>

            <Divider/>

            {/* {user ? */}
            <ChatRoom
                marketId={marketId}
                auth={auth}
                db={db}
                t={t}
                isStandalone={isStandalone}
                messagesRef={messagesRef}/>
            {/* : <SignIn auth={auth} />} */}

            <InputBox
                auth={auth}
                send={(val) => sendMessage(val)}/>

        </Box>
    );
}

function InputBox({auth, send}) {
    const [formValue, setFormValue] = useState('');
    const [verificationEmailSent, setVerificationEmailSent] = useState(true);
    const [loading, setLoading] = useState(false);
    const [disableChat, setDisableChat] = useState(false);
    const {enqueueSnackbar} = useSnackbar();
    const {t} = useTranslation();
    const user = auth.currentUser;


    useEffect(() => {
        async function loadFirestoreUser() {
            const usersRef = collection(firestore, `users`);
            const userDoc = await getDoc(doc(usersRef, user.uid));
            setDisableChat(!(userDoc.data().enableChat ?? false));
        }

        if (user) {
            loadFirestoreUser();
        }
    }, [user]);

    const onSend = (e) => {
        e.preventDefault();
        send(formValue);
        setFormValue('');
    }

    function verifyEmail() {
        const actionCodeSettings = {
            url: 'https://allcoin.co.kr/?verified=true'
        };

        setLoading(true);

        sendEmailVerification(user, actionCodeSettings)
            .then(() => {
                setVerificationEmailSent(true);
                enqueueSnackbar(t('verification link sent'), {
                    variant: 'success'
                });
                setLoading(false)
                monitorForEmailVerification()
            })
            .catch((error) => {
                console.log(error)
                enqueueSnackbar(t('verification link not sent'), {
                    variant: 'error'
                })
                setLoading(false)
            })
    }

    function monitorForEmailVerification() {
        const unsubSetInterval = setInterval(async () => {
            await auth.currentUser.reload();
            if (auth.currentUser.emailVerified) {
                setVerificationEmailSent(false);
                clearInterval(unsubSetInterval);
            }
        }, 10_000);
    }

    function emailNotVerified() {
        return (
            <Box
                display='flex'
                justifyContent='center'
                flexDirection='column'
                height={'58px'}
                alignItems='center'>

                {loading && <CircularProgress color="inherit" size={14}/>}

                {!loading && <Typography
                    variant="body1"
                    textAlign="center"
                    sx={{color: verificationEmailSent ? 'green' : 'red', mb: 1}}>
                    {verificationEmailSent
                        ? t('check email to verify')
                        : t('email not verified')}
                </Typography>}
                {!loading && <Button variant={'outlined'} size={'small'} onClick={verifyEmail}>
                    {verificationEmailSent
                        ? t('resend verification email')
                        : t('send verification email')}
                </Button>}
            </Box>
        )
    }

    function notLoggedIn() {
        return (
            <Typography
                variant="body1"
                textAlign="center"
                sx={{color: 'red'}}>
                {t('login for chat')}
            </Typography>
        )
    }

    function chatDisabledByAdmin() {
        return (
            <Typography
                variant="body1"
                textAlign="center"
                sx={{color: 'red'}}>
                {t('chat disabled by admin')}
            </Typography>
        )
    }

    return (
        <Box
            p={0}
            px={1}
            mx={2}
            my={1}>

            {!user && notLoggedIn()}

            {disableChat && chatDisabledByAdmin()}

            {!disableChat && user && !user.emailVerified && emailNotVerified()}

            {!disableChat && user && user.emailVerified &&
                <form onSubmit={onSend}>
                    <Box
                        borderRadius={'30px'}
                        backgroundColor='#f0f0f0'
                        fontSize="6px"
                        display={'flex'}
                        alignItems={'center'}>
                        <TextField
                            fullWidth
                            style={{ padding: 0 }}
                            size="small"
                            variant="standard"
                            InputProps={{
                                disableUnderline: true,
                                style: {
                                    paddingLeft: 10,
                                    paddingTop: '4px'
                                }
                            }}
                            value={formValue}
                            onChange={(e) => setFormValue(e.target.value)}
                            placeholder={t('type your message')}/>
                        <IconButton
                            type="submit"
                            disabled={!formValue}
                            size="small">
                            <Send color={"orange"}/>
                        </IconButton>
                    </Box>
                </form>}
        </Box>
    );
}


function ChatRoom({messagesRef, marketId, auth, isStandalone, db, t}) {

    const [messages, setMessages] = useState([]);
    const [showReport, setShowReport] = useState(false);
    const [reportData, setReportData] = useState();

    const dummy = useRef();

    async function onReport(reportData) {
        setReportData(reportData);
        setShowReport(true);
    }

    const scrollToBottom = () => {
        if (dummy.current)
            dummy.current.scrollIntoView(
                {
                    behavior: 'smooth',
                    block: 'nearest',
                    inline: 'start'
                });
    }

    useEffect(() => {
        loadChats();
    }, [marketId]);

    const loadChats = async () => {
        const unsub = onSnapshot(
            query(messagesRef,
                where('coin', '==', marketId),
                orderBy('createdAt', 'desc'),
                limit(500)),
            (doc) => {
                const iChats = [];
                doc.forEach(msg => iChats.push({...msg.data(), ref: `messages`, id: msg.id}));
                setMessages(iChats.reverse());
                scrollToBottom();
            });
    }

    return (
        <Box
            id="scrollableDiv"
            height={isStandalone ? "83vh" : '452px'}
            overflow="auto"
            sx={{
                '&::-webkit-scrollbar': {
                    width: '0.4em'
                },
                '&::-webkit-scrollbar-track': {
                    boxShadow: 'inset 0 0 6px rgba(0,0,0,0.00)',
                },
                '&::-webkit-scrollbar-thumb': {
                    backgroundColor: 'rgba(0,0,0,.2)',
                    borderRadius: 2
                }
            }}>

            <ReportDialog
                onClose={() => setShowReport(false)}
                isOpen={showReport}
                reportData={reportData}/>

            {messages && messages.map(
                msg =>
                    <ChatMessage
                        t={t}
                        auth={auth}
                        key={msg.id}
                        message={msg}
                        onReport={(reportData) => onReport(reportData)}
                    />
            )}

            <span ref={dummy}></span>

        </Box>)
}


function ChatMessage({message, auth, onReport, t}) {
    const {text, id, userId, photoURL, displayName} = message;

    const isMine = userId === auth.currentUser?.uid;
    const [menuStyle, setMenuStyle] = useState({display: 'none'});
    const [anchorEl, setAnchorEl] = React.useState(null);
    const open = Boolean(anchorEl);
    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };

    return (
        <Box display="flex" alignItems="top" mx={2} my={1}
             onMouseEnter={e => {
                 if (!isMine) {
                     setMenuStyle({display: 'block'});
                 }
             }}
             onMouseLeave={e => {
                 setMenuStyle({display: 'none'});
             }}>

            <Avatar
                sx={{width: 25, height: 25}} src={photoURL}/>

            <Box mx={1}>
                <Typography
                    color="#000"
                    variant="body1">
                    {displayName}
                </Typography>

                <Typography
                    variant="caption"
                    style={{flex: 1}}>
                    {text}
                </Typography>
            </Box>

            <IconButton size="small" sx={{ml: 'auto', alignSelf: 'flex-start', p: 0, ...menuStyle}}
                        onClick={handleClick}>
                <MoreVertIcon fontSize={"small"}/>
            </IconButton>

            <Menu
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
                onClick={handleClose}
                PaperProps={{
                    elevation: 0,
                    sx: {
                        overflow: 'visible',
                        filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
                        mt: 1.5,
                        '& .MuiAvatar-root': {
                            width: 32,
                            height: 32,
                            ml: -0.5,
                            mr: 1,
                        },
                        '&:before': {
                            content: '""',
                            display: 'block',
                            position: 'absolute',
                            top: 0,
                            right: 14,
                            width: 10,
                            height: 10,
                            bgcolor: 'background.paper',
                            transform: 'translateY(-50%) rotate(45deg)',
                            zIndex: 0,
                        },
                    },
                }}
                transformOrigin={{horizontal: 'right', vertical: 'top'}}
                anchorOrigin={{horizontal: 'right', vertical: 'bottom'}}
            >
                <MenuItem onClick={() => {
                    handleClose();
                    onReport({
                        messageId: id,
                        messageRef: message.ref,
                        message: text,
                        messageBy: {
                            id: userId,
                            name: displayName
                        },
                        reportedBy: {
                            id: auth.currentUser.uid,
                            name: auth.currentUser.displayName
                        },
                        seenByAdmin: false,
                        reason: ''
                    });
                }}>
                    {t('report message')}
                </MenuItem>
            </Menu>
        </Box>
    );
}


export default Chat;