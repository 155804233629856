import React, { useEffect, useRef, useState } from 'react';
import { Box } from '@mui/system';
import EnhancedTable from '../components/EnhancedTable';
import StarredCoins from "../components/StarredCoins";
import { useSnackbar } from "notistack";
import Advertisement from "../../ad/Advertisement";
import { useTranslation } from "react-i18next";
import NavBar from "../components/NavBar";
import Footer from "../../nav/Footer";
import useMarkets from "../../hooks/useMarkets";
import { auth, realtimeDatabase } from "../../firebase";
import { onAuthStateChanged } from 'firebase/auth';
import { 
    goOffline,
    goOnline,
    onDisconnect,
    onValue,
    ref,
    set,
    serverTimestamp as realtimeTimestamp } from 'firebase/database';

export default function CoinsScreen() {

    const priceChangeRangeRef = useRef('24h');
    const {markets} = useMarkets(priceChangeRangeRef);
    const [page, setPage] = useState(0);
    const {enqueueSnackbar} = useSnackbar();
    const [searchQuery, setSearchQuery] = useState('');
    const {t} = useTranslation();

    useEffect(() => { 
        onAuthStateChanged(auth, (user) => {
            if (user) {
                const isOfflineForDatabase = {
                    online: false,
                    onlineMarket: '',
                    lastOnlineChange: realtimeTimestamp(),
                };

                const isOnlineForDatabase = {
                    online: true,
                    onlineMarket: '',
                    lastOnlineChange: realtimeTimestamp(),
                };

                const usersRealtimeRef = ref(realtimeDatabase, '/users/' + user.uid);

                goOnline(realtimeDatabase);

                onValue(ref(realtimeDatabase, '.info/connected'), async (snapshot) => {
                    if (snapshot.val() === false) {
                        return;
                    }
        
                    onDisconnect(usersRealtimeRef).set(isOfflineForDatabase).then(async function () {
                        await set(usersRealtimeRef, isOnlineForDatabase);
                    });
                });
            } else {
                goOffline(realtimeDatabase)
            }
        });
    }, []);

    useEffect(() => {

        const isVerified = (new URLSearchParams(window.location.search)).get("verified")
        if (isVerified) {
            enqueueSnackbar(t('email verified'), {variant: 'success'})
        }
    }, [])

    const onPriceChangeDurationChange = (range) => {
        priceChangeRangeRef.current = range;
    }

    let tableMarkets = Object.values(markets);
    if (searchQuery) {
        tableMarkets = tableMarkets.filter(market =>
            market.fullName.toLowerCase().includes(searchQuery)
            || market.symbol.toLowerCase().includes(searchQuery)
            || market.market.toLowerCase().includes(searchQuery)
        )
    }

    return (
        <Box>

            <NavBar showSearch={true} onSearchChange={(query) => setSearchQuery(query)}/>

            <Advertisement/>

            <Box>

                {markets && <StarredCoins markets={markets}/>}

                <EnhancedTable
                    markets={tableMarkets}
                    page={page}
                    setPage={(page) => setPage(page)}
                    onPriceChangeDurationChange={(range) => onPriceChangeDurationChange(range)}
                />

            </Box>

            <Footer/>

        </Box>
    );
}
