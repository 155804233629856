import {
    Alert,
    Backdrop,
    Box,
    Button,
    CircularProgress,
    Collapse,
    Divider,
    IconButton,
    InputAdornment,
    Stack,
    TextField,
    Typography
} from '@mui/material';
import { useState } from 'react';
import { useSnackbar } from 'notistack';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import MailOutlineIcon from '@mui/icons-material/MailOutline';
import LockIcon from '@mui/icons-material/Lock';
import PersonIcon from '@mui/icons-material/Person';
import {
    browserLocalPersistence,
    createUserWithEmailAndPassword,
    getAdditionalUserInfo,
    GoogleAuthProvider,
    sendEmailVerification,
    setPersistence,
    signInWithPopup,
    updateProfile
} from "firebase/auth";
import { auth, firestore, saveUserOnFirebase } from '../firebase';
import { Close, EmailOutlined } from "@mui/icons-material";
import { collection } from "firebase/firestore";
import { useTranslation } from "react-i18next";
import CustomButton from "../commons/CustomButton";
import GoogleIcon from "../assets/google.png";
import DialogCloseIcon from "../commons/DialogCloseIcon";
import errorCodes from './errorCodes';

export default function SignupDialog({isOpen = false, onCloseDialog, onLogin, onChangePassword}) {
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [name, setName] = useState('');
    const [loading, setLoading] = useState(false);
    const [emailVerificationSent, setEmailVerificationSent] = useState(false);
    const [errorMessage, setErrorMessage] = useState(null);

    const {enqueueSnackbar} = useSnackbar();
    const {t} = useTranslation();

    const provider = new GoogleAuthProvider();

    const usersRef = collection(firestore, `users`);

    const loadingStart = () => {
        setLoading(true);
        setErrorMessage(null);
    }

    const loginWithGoogle = () => {
        loadingStart();
        setPersistence(auth, browserLocalPersistence).then(() => {
            return signInWithPopup(auth, provider)
                .then(async (result) => {
                    const user = result.user;

                    const userDetail = getAdditionalUserInfo(result);
                    if (userDetail.isNewUser) {
                        await saveUserOnFirebase(user);
                    }

                    enqueueSnackbar(t('logged in success'), {
                        variant: 'success'
                    });

                    setLoading(false);
                    onClose();
                }).catch((error) => {
                    setLoading(false);
                    const errorCode = error.code;
                    const errorMessage = error.message;
                    const email = error.email;
                    console.error(error);
                    enqueueSnackbar(t('unable to login'), {
                        variant: 'error'
                    });
                });
        });

    }

    const onSignin = () => {
        if (email === '' || !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(email)) {
            setErrorMessage(t('enter valid email'))
            return;
        }

        if (password.length < 6) {
            setErrorMessage(t('enter 6 char pwd'))
            return;
        }

        setErrorMessage('');

        loadingStart();

        setPersistence(auth, browserLocalPersistence).then(() => {
            return createUserWithEmailAndPassword(auth, email, password)
                .then(async (userCredential) => {
                    // Signed in
                    const user = userCredential.user;
                    await updateProfile(user, {
                        displayName: name
                    });
                    await auth.currentUser.reload();
                    await saveUserOnFirebase(user);
                    verifyEmail(user);
                })
                .catch((error) => {
                    const errorCode = error.code;
                    const errorMessage = error.message;
                    console.error(errorCode, errorMessage);
                    
                    setErrorMessage(t(errorCodes[errorCode] ?? 'something went wrong'))

                    setLoading(false);
                });
        });
    }

    function verifyEmail(user) {
        const actionCodeSettings = {
            url: 'https://allcoin.co.kr/?verified=true'
        };

        sendEmailVerification(user, actionCodeSettings)
            .then(() => {
                setEmailVerificationSent(true);
                setLoading(false);
                enqueueSnackbar(t('register success'), {
                    variant: 'success'
                });
                monitorForEmailVerification();
            }).catch((error) => {
            console.log(error)
            enqueueSnackbar(t('verification link not sent'), {
                variant: 'error'
            });
            setLoading(false);
        })
    }

    function monitorForEmailVerification() {
        const unsubSetInterval = setInterval(async () => {
            await auth.currentUser.reload();
            if (auth.currentUser.emailVerified) {
                clearInterval(unsubSetInterval);
                onClose();
            }
        }, 10_000);
    }

    function emailSentContent() {
        return (<DialogContent sx={{width: { xs: 'unset', sm: '300px'}, p: 4}}>
            <Box
                display="flex"
                flexDirection="column"
                justifyContent="center"
                height={'100%'}
                alignItems="center">

                <EmailOutlined fontSize={"large"}/>
                <Typography variant={'h6'}>{t('verification link sent')}</Typography>
                <Typography variant={'body1'}>{t('check email to verify')}</Typography>
            </Box>
        </DialogContent>)
    }

    
    const onClose = () => {
        setName('');
        setEmail('');
        setPassword('');
        onCloseDialog();
    }

    return (
        <Dialog
            open={isOpen}
            keepMounted
            aria-describedby="alert-dialog-slide-description"
            sx={{
                backdropFilter: "blur(1px) sepia(1%)",
            }}
            PaperProps={{sx: {borderRadius: "26px"}}}
        >
            <Backdrop
                sx={{color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1}}
                open={loading}
            >
                <CircularProgress color="inherit"/>
            </Backdrop>

            <DialogTitle sx={{textAlign: 'center'}}>
                <IconButton
                    onClick={onClose}
                    sx={{position: 'absolute', right: 12, top: 12}}>
                    <DialogCloseIcon onClick={onClose}/>
                </IconButton>
            </DialogTitle>

            {emailVerificationSent && emailSentContent()}

            {!emailVerificationSent &&

                <DialogContent sx={{width: { xs: 'unset', sm: '300px'}, p: 4}}>

                    <Typography variant={'h5'} textAlign='center' fontWeight={800} sx={{mt: 2}}>
                        {t('sign up all coins')}
                    </Typography>

                    <TextField
                        value={name}
                        placeholder={t('nick name')}
                        type="text"
                        size="medium"
                        variant="standard"
                        fullWidth
                        onChange={(e) => setName(e.target.value)}
                        InputProps={{
                            style: {
                                borderRadius: 30,
                                background: '#f0f0f0',
                                padding: '8px 12px'
                            },
                            disableUnderline: true,
                            startAdornment: (
                                <InputAdornment position="start">
                                    <PersonIcon fontSize="small"/>
                                </InputAdornment>)
                        }}
                        sx={{mt: 3}}
                    />

                    <TextField
                        size="medium"
                        variant="standard"
                        value={email}
                        placeholder={t('email')}
                        type="email"
                        fullWidth
                        onChange={(e) => setEmail(e.target.value)}
                        InputProps={{
                            style: {
                                borderRadius: 30,
                                background: '#f0f0f0',
                                padding: '8px 12px'
                            },
                            disableUnderline: true,
                            startAdornment: (
                                <InputAdornment position="start">
                                    <MailOutlineIcon fontSize="small"/>
                                </InputAdornment>)
                        }}
                        sx={{mt: 2}}
                    />

                    <TextField
                        size="medium"
                        variant="standard"
                        value={password}
                        placeholder={t('password')}
                        fullWidth
                        type="password"
                        onSubmit={() => onSignin()}
                        onChange={(e) => setPassword(e.target.value)}
                        onKeyPress={event => {
                            if (event.key === 'Enter') {
                                onSignin();
                            }
                        }}
                        InputProps={{
                            style: {
                                borderRadius: 30,
                                background: '#f0f0f0',
                                padding: '8px 12px'
                            },
                            disableUnderline: true,
                            startAdornment: (
                                <InputAdornment position="start">
                                    <LockIcon fontSize="small"/>
                                </InputAdornment>
                            )
                        }}
                        sx={{mt: 2}}
                    />

                    <Stack direction={"row"} spacing={1} mt={3} mb={2}>
                        <CustomButton
                            fullWidth
                            variant="outlined"
                            onClick={onLogin}>
                            {t('login')}
                        </CustomButton>

                        <CustomButton
                            fullWidth
                            variant="contained"
                            onClick={onSignin}>
                            {t('sign up')}
                        </CustomButton>
                    </Stack>

                    <Collapse in={!!errorMessage}>
                        <Alert
                            onClose={() => setErrorMessage(null)}
                            severity="error">{errorMessage}</Alert>
                    </Collapse>

                    <Divider sx={{my: 3}}/>

                    <Button
                        sx={{
                            mb: 2,
                            backgroundColor: '#f8f8f8',
                            borderRadius: 20,
                            color: 'black',
                            fontWeight: 'bold',
                            border: 'none',
                            padding: '8px 12px',
                            '&:hover': {
                                border: 'none'
                            }
                        }}
                        variant="outlined"
                        disableElevation
                        fullWidth
                        onClick={loginWithGoogle}>
                        <img src={GoogleIcon} width={18}/>&nbsp; &nbsp;{t('login with google')}
                    </Button>
                </DialogContent>}
        </Dialog>);
}