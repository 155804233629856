import {
    Alert,
    Backdrop,
    Box,
    CircularProgress,
    Collapse,
    FormControlLabel,
    IconButton,
    Radio,
    Stack,
    TextField,
    Typography
} from '@mui/material';
import React, { useState } from 'react';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import { useSnackbar } from 'notistack';
import { firestore } from '../firebase';
import { addDoc, collection, serverTimestamp } from "firebase/firestore";
import { useTranslation } from "react-i18next";
import { Close } from "@mui/icons-material";
import CustomButton from "../commons/CustomButton";
import DialogCloseIcon from "../commons/DialogCloseIcon";

export function ReportDialog({isOpen = false, onClose, reportData}) {
    const [loading, setLoading] = useState(false);
    const [errorMessage, setErrorMessage] = useState(null);
    const [reportId, setReportId] = useState(0);
    const [reasonText, setReasonText] = useState('');

    const reportRef = collection(firestore, `reported_messages`);

    const {enqueueSnackbar} = useSnackbar();
    const {t} = useTranslation();

    const reportReasons = [
        {id: 1, reason: t('sexual content')},
        {id: 2, reason: t('violent')},
        {id: 3, reason: t('harassment')},
        {id: 4, reason: t('misinformation')},
        {id: 5, reason: t('spam')},
        {id: 6, reason: t('something else')},
    ]

    const onSave = async () => {
        setLoading(true);

        const reason = reportId === 6 ? reasonText : reportReasons[reportId - 1].reason;
        const data = {...reportData, reason, reportedAt: serverTimestamp()};

        await addDoc(reportRef, data);

        enqueueSnackbar(t('user reported'), {
            variant: 'success'
        });

        setLoading(false);
        onClose();
    }

    const enableReportButton = reportId !== 0 || (reportId === 6 && reasonText !== '');

    return (
        <Dialog
            open={isOpen}
            keepMounted
            aria-describedby="alert-dialog-slide-description"
            sx={{
                backdropFilter: "blur(1px) sepia(1%)",
            }}
            PaperProps={{sx: {borderRadius: "26px"}}}
        >
            <Backdrop
                sx={{color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1}}
                open={loading}
            >
                <CircularProgress color="inherit"/>
            </Backdrop>

            <DialogTitle sx={{textAlign: 'center'}}>
                <IconButton
                    onClick={onClose}
                    sx={{position: 'absolute', right: 12, top: 12}}>
                    <DialogCloseIcon onClick={onClose}/>
                </IconButton>
            </DialogTitle>

            <DialogContent sx={{width: { xs: 'unset', sm: '300px'}, p: 4}}>

                <Typography variant={'h5'} textAlign='center' fontWeight={800} sx={{mt: 2}}>
                    {t('report message')}
                </Typography>

                <Stack spacing={3} mt={3}>

                    <Collapse in={!!errorMessage}>
                        <Alert
                            onClose={() => setErrorMessage(null)}
                            severity="error">{errorMessage}</Alert>
                    </Collapse>

                    <Box display={'flex'} flexDirection={'column'}>
                        {reportReasons.map((reason) => (
                            <FormControlLabel
                                control={
                                    <Radio
                                        onChange={(e) => {
                                            setReportId(reason.id);
                                        }}
                                        size={'small'}
                                        checked={reportId === reason.id}/>}
                                label={reason.reason}/>
                        ))}
                    </Box>

                    {reportId === 6 &&
                        <TextField
                            size="medium"
                            variant="standard"
                            placeholder={t('describe report reason')}
                            value={reasonText}
                            fullWidth
                            multiline
                            minRows={5}
                            type="text"
                            onChange={(e) => setReasonText(e.target.value)}
                            InputProps={{
                                style: {
                                    borderRadius: 30,
                                    background: '#f0f0f0',
                                    padding: '8px 12px'
                                },
                                disableUnderline: true,
                            }}
                        />}
                </Stack>

                <CustomButton
                    disabled={!enableReportButton}
                    variant="contained"
                    onClick={onSave}
                    sx={{mt: 3}}>
                    {t('report')}
                </CustomButton>

            </DialogContent>
        </Dialog>
    );
}