export const themeOptions = {
    palette: {
        mode: 'light',
        primary: {
            main: '#050506',
        },
        secondary: {
            main: '#f50057',
        },
        orange: {
            main: 'orange',
            dark: '#a96d00'
        }
    },
    typography: {
        fontFamily: [
            'sans-serif',
            '-apple-system',
            'Roboto',
            '"Montserrat"',
        ],
        subtitle2: {
            fontWeight: 'bold'
        },
        body2: {
            fontSize: '0.8rem',
            fontWeight: 600,
        },
        body1: {
            fontSize: '0.8rem',
        },
        caption: {
            fontSize: '0.8rem',
            color: '#a1a1a1'
        },
        button: {
            textTransform: "none"
        }
    },
    components: {
        MuiButton: {
            styleOverrides: {
                text: {

                },
                startIcon: {
                    marginRight: '2px',
                    '& > :nth-of-type(1)': {
                        fontSize: '16px',
                    },
                    paddingBottom: '2px'
                }
            }
        },
        MuiTableCell: {
            styleOverrides: {
                '.MuiTableCell-root': {
                    padding: 0
                }
            }
        }
    }
};