import { getLogoFromCoin } from "../utils/coin_utils";

import axios from "axios";

const COIN_SYMBOLS = {
    'USDT': 'BUSDUSDT',
    'BUSD': 'BUSDUSDT',
    'XEM': 'XEMUSDT',
}


export async function getMarkets() {
    const response = await axios.get('https://www.binance.com/bapi/composite/v1/public/marketing/symbol/list')
    const loadedMarkets = {};
    const responseData = response.data.data;
    responseData.sort((a, b) => a.rank - b.rank);
    response.data.data.forEach(coin => {
        if (coin.rank && coin.volume) {
            const name = coin.name;
            let symbol = coin.symbol;
            if (name === 'USDT') {
                symbol = 'BUSDUSDT'
            }
            const logo = getLogoFromCoin({coinName: coin.fullName, coinSymbol: name});
            loadedMarkets[coin.symbol] = {
                id: coin.id,
                rank: coin.rank,
                fullName: coin.fullName,
                name: coin.name,
                symbol: symbol,
                logo: logo,
                market: coin.symbol,
                tradePrice: coin.price,
                changeRate: coin.dayChange ?? 0,
                accTradeVolume: coin.volume ?? 0,
                marketCap: coin.marketCap ?? 0,
                userCount: 0,
                isPriceIncreased: null
            }
        }
    });

    return loadedMarkets;
}