import {
    Alert,
    Backdrop,
    Button,
    CircularProgress,
    Collapse,
    Divider,
    IconButton,
    InputAdornment,
    Stack,
    TextField,
    Typography
} from '@mui/material';
import { useState } from 'react';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import MailOutlineIcon from '@mui/icons-material/MailOutline';
import LockIcon from '@mui/icons-material/Lock';
import {
    browserLocalPersistence,
    getAdditionalUserInfo,
    getAuth,
    GoogleAuthProvider,
    setPersistence,
    signInWithEmailAndPassword,
    signInWithPopup,
} from "firebase/auth";
import { useSnackbar } from 'notistack';
import { auth, firestore, saveUserOnFirebase } from '../firebase';
import { collection, doc, getDoc, increment, serverTimestamp, updateDoc } from "firebase/firestore";
import { signOut } from "@firebase/auth";
import { useTranslation } from "react-i18next";
import { Close } from "@mui/icons-material";
import CustomButton from "../commons/CustomButton";
import GoogleIcon from "../assets/google.png";
import DialogCloseIcon from "../commons/DialogCloseIcon";
import errorCodes from './errorCodes';

export default function LoginDialog({isOpen = false, onCloseDialog, onSignup, onForgotPassword}) {
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [loading, setLoading] = useState(false);
    const [errorMessage, setErrorMessage] = useState(null);

    const {enqueueSnackbar} = useSnackbar();
    const {t} = useTranslation();

    const provider = new GoogleAuthProvider();

    const usersRef = collection(firestore, `users`);

    const loadingStart = () => {
        setLoading(true);
        setErrorMessage(null);
    }

    const loginWithGoogle = () => {
        loadingStart()
        setPersistence(auth, browserLocalPersistence).then(() => {
            return signInWithPopup(auth, provider)
                .then(async (result) => {
                    const user = result.user;

                    const userDetail = getAdditionalUserInfo(result);
                    if (userDetail.isNewUser) {
                        await saveUserOnFirebase(user);
                    }

                    await checkIfDeletedByAdmin(user);
                    await updateLoginInfo(user);
                    enqueueSnackbar(t('logged in success'), {variant: 'success'});
                    setLoading(false);
                    onClose();
                    // ...
                }).catch((error) => {
                    setLoading(false);
                    // Handle Errors here.
                    const errorCode = error.code;
                    const errorMessage = error.message;
                    // The email of the user's account used.
                    const email = error.email;
                    // The AuthCredential type that was used.
                    const credential = GoogleAuthProvider.credentialFromError(error);
                    console.error(error);
                    enqueueSnackbar(t(errorCodes[errorCode] ?? 'something went wrong'), {
                        variant: 'error'
                    })
                });
        });
    }

    const onLogin = () => {
        if (email === '' || !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(email)) {
            setErrorMessage(t('enter valid email'))
            return;
        }

        if (password.length < 6) {
            setErrorMessage(t('enter valid password'))
            return;
        }

        loadingStart();
        setPersistence(auth, browserLocalPersistence).then(() => {
            return signInWithEmailAndPassword(auth, email, password)
                .then(async (userCredential) => {
                        await checkIfDeletedByAdmin(userCredential.user);
                        await updateLoginInfo(userCredential.user);
                        // Signed in
                        enqueueSnackbar(t('logged in success'), {variant: 'success'});

                        setLoading(false);
                        onClose();

                    }
                )
                .catch((error) => {
                    const errorCode = error.code;
                    const errorMessage = error.message;
                    console.error(error, errorCode, errorMessage);

                    setErrorMessage(t(errorCodes[errorCode] ?? 'something went wrong'))

                    setLoading(false);
                });
        });
    }

    const updateLoginInfo = async (user) => {
        try {
            await updateDoc(doc(usersRef, user.uid), {
                lastLoginAt: serverTimestamp(),
                loginCount: increment(1),
                online: true,
            });
        } catch(e) {
            console.error('Unable to update login info', e);
        }
    }

    const checkIfDeletedByAdmin = async (user) => {
        try {
            const userSnap = await getDoc(doc(usersRef, user.uid));
            console.log(userSnap, userSnap.data())
            if (!userSnap.data()) {
                signOut(getAuth()).then(() => {
                    setErrorMessage(t('unable to login'));
                    setLoading(false)
                }).catch((error) => {
                    // An error happened.
                    setLoading(false)
                });
            }
        } catch(e) {
            console.error('Unable to check deleted by admin', e)
        }
    }

    const onClose = () => {
        setEmail('');
        setPassword('');
        onCloseDialog();
    }

    return (
        <Dialog
            open={isOpen}
            keepMounted
            aria-describedby="alert-dialog-slide-description"
            sx={{
                backdropFilter: "blur(1px) sepia(1%)",
            }}
            PaperProps={{sx: {borderRadius: "26px"}}}
        >
            <Backdrop
                sx={{color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1}}
                open={loading}
            >
                <CircularProgress color="inherit"/>
            </Backdrop>

            <DialogTitle sx={{textAlign: 'center'}}>
                <IconButton
                    onClick={onClose}
                    sx={{position: 'absolute', right: 12, top: 12}}>
                    <DialogCloseIcon onClick={onClose}/>
                </IconButton>
            </DialogTitle>

            <DialogContent sx={{ width: { xs: 'unset', sm: '300px'}, p: 4}}>
                <Typography variant={'h5'} textAlign='center' fontWeight={800} sx={{mt: 2}}>
                    {t('login')}
                </Typography>

                <TextField
                    value={email}
                    placeholder={t('email')}
                    fullWidth={true}
                    type="email"
                    size="medium"
                    variant="standard"
                    onChange={(e) => setEmail(e.target.value)}
                    InputProps={{
                        style: {
                            borderRadius: 30,
                            background: '#f0f0f0',
                            padding: '8px 12px'
                        },
                        disableUnderline: true,
                        startAdornment: (
                            <InputAdornment position="start">
                                <MailOutlineIcon fontSize="small"/>
                            </InputAdornment>)
                    }}
                    sx={{mt: 3}}
                />

                <TextField
                    size="medium"
                    variant="standard"
                    value={password}
                    placeholder={t('password')}
                    fullWidth
                    type="password"
                    onSubmit={() => onLogin()}
                    onChange={(e) => setPassword(e.target.value)}
                    onKeyPress={event => {
                        if (event.key === 'Enter') {
                            onLogin();
                        }
                    }}
                    InputProps={{
                        style: {
                            borderRadius: 30,
                            background: '#f0f0f0',
                            padding: '8px 12px'
                        },
                        disableUnderline: true,
                        startAdornment: (
                            <InputAdornment position="start">
                                <LockIcon fontSize="small"/>
                            </InputAdornment>
                        )
                    }}
                    sx={{mt: 2}}
                />

                <Button
                    sx={{
                        mt: 1,
                        mx: 'auto',
                        display: 'block'
                    }}
                    variant="text"
                    onClick={() => onForgotPassword()}
                    size="small">
                    {t('forgot password')}
                </Button>

                <Stack direction={"row"} spacing={1} mt={1}>
                    <CustomButton
                        fullWidth
                        variant="outlined"
                        onClick={onSignup}>
                        {t('sign up')}
                    </CustomButton>

                    <CustomButton
                        fullWidth
                        variant="contained"
                        onClick={onLogin}>
                        {t('login')}
                    </CustomButton>
                </Stack>

                <Divider sx={{my: 3}}/>

                <Button
                    sx={{
                        mb: 2,
                        backgroundColor: '#f8f8f8',
                        borderRadius: 20,
                        color: 'black',
                        fontWeight: 'bold',
                        border: 'none',
                        padding: '8px 12px',
                        '&:hover': {
                            border: 'none'
                        }
                    }}
                    variant="outlined"
                    disableElevation
                    fullWidth
                    onClick={loginWithGoogle}>
                    <img src={GoogleIcon} width={18}/>&nbsp; &nbsp;{t('login with google')}
                </Button>

                <Collapse in={!!errorMessage}>
                    <Alert
                        onClose={() => setErrorMessage(null)}
                        severity="error">{errorMessage}</Alert>
                </Collapse>

            </DialogContent>
        </Dialog>);
}