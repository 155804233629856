import { Box, ButtonBase } from "@mui/material";
import React, { useEffect, useState } from 'react';
import i18n from '../i18nextConf';

export default function LanguageToggleButton() {

    const [language, setLanguage] = useState(i18n.language ?? 'en');

    useEffect(() => {
        i18n.changeLanguage(language);
    }, [language]);

    const selectedStyle = {textDecoration: 'underline', textDecorationThickness: 2};

    return (
        <Box display={'flex'} alignItems={'center'} mr={6}>
            <ButtonBase
                disableRipple
                onClick={() => setLanguage('ko')}>
                <Box
                    p={2}
                    fontSize={14}
                    fontWeight={'bold'}
                    color={language === 'ko' ? 'red' : '#ccc7c7'}
                    sx={language === 'ko' ? selectedStyle : {}}>
                    KR
                </Box>
            </ButtonBase>
            <Box sx={{height: 12, width: 1.5, background: '#ccc7c7'}} my={2}/>
            <ButtonBase
                disableRipple
                onClick={() => setLanguage('en')}>
                <Box
                    p={2}
                    fontSize={14}
                    fontWeight={'bold'}
                    color={language === 'en' ? 'red' : '#ccc7c7'}
                    sx={language === 'en' ? selectedStyle : {}}>
                    EN
                </Box>
            </ButtonBase>
        </Box>
    );
}