import { initializeApp } from 'firebase/app';
import { getAuth } from "firebase/auth";
import { collection, doc, getFirestore, serverTimestamp, setDoc } from 'firebase/firestore';
import { getStorage } from 'firebase/storage';
import { getDatabase } from 'firebase/database';
import axios from 'axios';


const app = initializeApp({
    apiKey: "AIzaSyCuc-XYsW0ndBg7KxAVnEKiP6rXDcPl2H4",
    authDomain: "all-coins-4799e.firebaseapp.com",
    projectId: "all-coins-4799e",
    storageBucket: "all-coins-4799e.appspot.com",
    messagingSenderId: "154203430399",
    appId: "1:154203430399:web:95215031cbc333ead85bca",
    measurementId: "G-NRBM56P9B5",
    databaseURL: "https://all-coins-4799e-default-rtdb.asia-southeast1.firebasedatabase.app"
})

const firestore = getFirestore(app);
const auth = getAuth(app);
const storage = getStorage(app);
const realtimeDatabase = getDatabase(app);

const getUsersCountOnChatRoom = async (roomId) => {
    try {
        const response = await axios.get('https://asia-northeast3-all-coins-4799e.cloudfunctions.net/getOnlineUsersCountForMarket');
        return response.data.markets;
    } catch (e) {
        console.error(e);
    }
    return 0;
}

const getUsersOnCurrentRoom = async (roomId) => {
    try {
        const response = await axios.get('https://asia-northeast3-all-coins-4799e.cloudfunctions.net/getUsersOnCurrentRoom?market=' + roomId);
        return response.data;
    } catch (e) {
        console.error(e);
    }
    return [];
}

async function saveUserOnFirebase(user) {
    const usersRef = collection(firestore, `users`);

    await setDoc(doc(usersRef, user.uid), {
        createdAt: serverTimestamp(),
        email: user.email,
        enableChat: true,
        lastLoginAt: serverTimestamp(),
        loginCount: 1,
        name: user.displayName,
        note: '',
        online: true,
        uid: user.uid,
        avatar: user.photoURL
    });
}

export {
    firestore,
    auth,
    storage,
    realtimeDatabase,
    getUsersCountOnChatRoom,
    saveUserOnFirebase,
    getUsersOnCurrentRoom
}
